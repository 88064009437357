import { valorFinanceiroParaTexto } from 'components/AudioAcessibilidade/formatters';
import { executarAudio } from 'components/AudioAcessibilidade/utils'
import { formatarStringDataTemplate } from 'helpers/Formatters'
import { dateAddMoths } from 'helpers/dateUtils'

export default function AudioTermoAdesaoResumo(dados, tokenSignatario) {
    let texto = `
    Resumo da sua proposta de consignado:
    Valor Financiado: @VALOR_FINANCIADO.
    Valor Líquido: @VALOR_LIQUIDO.
    Operação: Proposta @TIPO_OPERACAO.
    Vencimento: Folha de @FOLHA_VENCIMENTO.
    Parcela: @VALOR_PARCELA.
    Prazo: @PRAZO vezes.`;

    const vencimentoFolha = dateAddMoths(dados.caracteristicaCreditoCet.primeiroVencimento, -1);
    const vencimentoFolhaAudio = formatarStringDataTemplate(vencimentoFolha, 'MMMM/YYYY')
        .replace('/', ' de ');

    texto = texto
        .replace('@VALOR_FINANCIADO', valorFinanceiroParaTexto(dados.caracteristicaCreditoCet.valorFinanciado))
        .replace('@VALOR_LIQUIDO', valorFinanceiroParaTexto(dados.valorLiberado))
        .replace('@VALOR_PARCELA', valorFinanceiroParaTexto(dados.caracteristicaCreditoCet.valorPrestacao))
        .replace('@TIPO_OPERACAO', dados.descricaoTipoOperacao)
        .replace('@PRAZO', dados.caracteristicaCreditoCet.quantidadePrestacao)
        .replace('@FOLHA_VENCIMENTO', vencimentoFolhaAudio);

    executarAudio(texto, tokenSignatario);
}