const PAGE_BIOMETRIA = '/biometria';
const PAGE_DOCUMENTO = '/documento';
const PAGE_ASSINATURA = '/assinatura';
const PAGE_TOKEN = '/token';
const PAGE_PASSOS = '/passos';
const PAGE_CONFIRMACAO_DADOS = '/confirmacao-dados';
const PAGE_CONCLUSAO = '/conclusao';
const PAGE_POLITICA = '/politica';
const PAGE_VERIFICACAO = '/verificacao';
const PAGE_LOCALIZACAO = '/localizacao';
const PAGE_ANEXOS = '/anexos';
const PAGE_CAMERA = '/camera';
const PAGE_SOLICITACAO_ACESSO = '/solicitacao-acesso';
const PAGE_INFORMACOES = '/informacoes';
const PAGE_IDENTIFICACAO = '/identificacao';

export {
  PAGE_BIOMETRIA,
  PAGE_DOCUMENTO,
  PAGE_ASSINATURA,
  PAGE_TOKEN,
  PAGE_PASSOS,
  PAGE_CONFIRMACAO_DADOS,
  PAGE_CONCLUSAO,
  PAGE_POLITICA,
  PAGE_VERIFICACAO,
  PAGE_LOCALIZACAO,
  PAGE_ANEXOS,
  PAGE_CAMERA,
  PAGE_SOLICITACAO_ACESSO,
  PAGE_INFORMACOES,
  PAGE_IDENTIFICACAO,
};
