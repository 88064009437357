import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import PageLoader from 'components/PageLoader';

import getOnlyNumbers from 'helpers/getOnlyNumbers';

import api, { apiAuth } from 'services/api';

import Cell from 'assets/cell.png';

import { useApp } from 'context/App';

import './styles.css';

export default function TokenPortal() {
  const [codigo, setCodigo] = useState('');
  const [idToken, setIdToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const {
    cpf,
    finalCelular,
    setTokenSMS,
    setUsuarioValido,
    setIdTokenPortal,
    parteEmail,
    semNumeroCelular,
  } = useApp();

  useEffect(() => {
    const fecthData = async () => {
      try {
        const response = await apiAuth.post(
          `Autenticacao/EnviarTokenPortal/${getOnlyNumbers(cpf)}`,
          null,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );

        setIdToken(response.data.retorno);
        setIdTokenPortal(response.data.retorno);
      } catch (error) {
        toast.error('Ocorreu um erro ao enviar o código');
      }
    };
    fecthData();
  }, []);

  async function reenviarToken() {
    try {
      setIsLoading(true);

      const response = await apiAuth.post(
        `Autenticacao/ReenviarToken/${idToken}`,
        null,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      setIsLoading(false);

      if (response.data.retorno) {
        toast.info('O código foi reenviado');
      } else {
        toast.error('Ocorreu um erro ao reenviar o código');
      }
    } catch (error) {
      setIsLoading(false);
      toast.error('Ocorreu um erro ao reenviar o código');
    }
  }

  async function validarToken() {
    try {
      setIsLoading(true);

      const response = await apiAuth.post(
        `Autenticacao/ValidarToken/${idToken}/${codigo}`,
        null,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      setIsLoading(false);

      return response.data.retorno;
    } catch (error) {
      setIsLoading(false);
      return false;
    }
  }

  async function proximo(e) {
    e.preventDefault();

    const tokenValido = await validarToken();

    if (tokenValido) {
      setTokenSMS(codigo);
      setUsuarioValido(true);
      navegarProximaPagina();
    } else {
      toast.error('O código informado não é valido');
    }
  }

  function navegarProximaPagina() {
    history.push('/redefine-senha');
  }

  return (
    <div className="container token-portal-container">
      <section
        className={`loader-animation ${isLoading ? 'enabled' : 'hidden'}`}
      >
        <PageLoader isOverlay="true" width={125} height={250} />
      </section>
      <section className="form">
        <h1>Verificação de {semNumeroCelular ? 'email' : 'telefone'}</h1>
        <h2>
          Uma mensagem com o código foi enviada para o seu{' '}
          {semNumeroCelular
            ? `email ${parteEmail}`
            : `telefone com final (**)*****${finalCelular}`}
          .
        </h2>

        <img src={Cell} alt="celular" />

        <form>
          <input
            placeholder="Digite o código de 4 dígitos"
            value={codigo}
            onChange={(e) => setCodigo(e.target.value)}
          />

          <p className="codigo-reenvio">
            Não recebeu o código? <a onClick={reenviarToken}>Clique Aqui!</a>
          </p>

          <button className="button-bem" onClick={proximo}>
            Próxima
          </button>
        </form>
      </section>
      <ToastContainer />
    </div>
  );
}
