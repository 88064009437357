const copyToClipboard = string => {
  const el = document.createElement('input');
  el.value = string;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export default copyToClipboard;
