import { obterAmbiente } from 'services/autenticacao'

export default async function validaAmbiente(pathName) {
    try {
        var response = await obterAmbiente();
        var retorno = response.data.retorno;

        if (window.location.origin === retorno.url)
            return { ambienteValido: true };
        
        window.location.href = retorno.url + pathName;
        return { ambienteValido: false };
    } catch {
        return { ambienteValido: false, mensagemAviso: { titulo: 'Sistema em manutenção!', subTitulo: 'Tente novamente mais tarde.'}};
    }
}