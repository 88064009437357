import { getExpirationDate } from 'auth';

export const isValidTokenScore = () => {
    const jwtToken = localStorage.getItem('autorizacao-score');

    if (jwtToken) {
        return Date.now() < getExpirationDate(jwtToken);
    }

    return false;
}