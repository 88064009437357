import React, { useEffect } from 'react';

import { ReactComponent as CameraIcon } from 'assets/icon-camera.svg';

import { useApp } from 'context/App';

import { CODIGO_ERRO_CAMERA } from './consts';

import api from 'services/api';

import './styles.css';
import { useHistory } from 'react-router-dom';

export default function Camera() {
  const { tokenSignatario, proximaPagina, configGlobal } = useApp();
  const history = useHistory();

  useEffect(() => {
    const executaCamera = async () => {
      await enviaDadosDispositivo();

      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(sucessoCamera)
        .catch(erroCamera);
    };

    executaCamera();
  }, []);

  function navegarProximaPagina() {
    history.push(proximaPagina());
  }

  function sucessoCamera() {
    navegarProximaPagina();
  }

  async function erroCamera() {
    try {
      const data = {
        token: tokenSignatario,
        mensagem: 'O acesso da camera não foi permitido.',
        tipo: CODIGO_ERRO_CAMERA,
      };

      await api.post(`BemSign/GravarLog`, data, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('autorizacao')}`,
          'Content-Type': 'application/json',
        },
      });
    } catch (err) {
    } finally {
      history.push('/instrucao-camera');
    }
  }

  const enviaDadosDispositivo = async () => {
    const listaDispositivos = [];

    try {
      navigator.mediaDevices.enumerateDevices().then(async function (devices) {
        const lista = devices.map((d) => {
          return {
            idDispositivo: d.deviceId,
            tipo: d.kind,
            descricao: d.label,
            idGrupo: d.groupId,
          };
        });

        listaDispositivos.push(...lista);

        await api.post(`BemSign/GravarDispositivosMidia`, listaDispositivos, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              'autorizacao',
            )}`,
            'Content-Type': 'application/json',
          },
        });
      });
    } catch (error) { }
  };

  return (
    <div className="container camera-container" style={{ background: configGlobal.camera_cor_fundo }}>
      <CameraIcon fill={configGlobal.camera_cor_icone} />
      <h2>
        Para sua segurança é importante que você permita o acesso a sua câmera.
      </h2>
    </div>
  );
}
