import React from 'react';

import { useHistory } from 'react-router-dom';

import dateTimeMiliseconds from 'helpers/dateTimeMiliseconds';
import firstName from 'helpers/firstName';

import { useApp } from 'context/App';

import './styles.css';

export default function Politica() {
  const { setConfirmacaoLgpd, cliente } = useApp();

  const history = useHistory();

  function confirmarPolitica(e) {
    e.preventDefault();

    setConfirmacaoLgpd(dateTimeMiliseconds());

    history.push('/biometria');
  }

  return (
    <div className="container politica-container">
      <section className="info-lgpd">
        <div className="info-cabecalho">
          <h1>{firstName(cliente)},</h1>
          <p>
            Para sua segurança, leia atentamente as instruções e informações de
            políticas compliance com a LGPD:
          </p>
        </div>

        <div className="info-texto">
          <h1>CAPÍTULO I DISPOSIÇÕES PRELIMINARES</h1>
          <p>
            Art. 1º Esta Lei dispõe sobre o tratamento de dados pessoais,
            inclusive nos meios digitais, por pessoa natural ou por pessoa
            jurídica de direito público ou privado, com o objetivo de proteger
            os direitos fundamentais de liberdade e de privacidade e o livre
            desenvolvimento da personalidade da pessoa natural.
            <br />
            Parágrafo único. As normas gerais contidas nesta Lei são de
            interesse nacional e devem ser observadas pela União, Estados,
            Distrito Federal e Municípios. (Incluído pela Lei nº 13.853, de
            2019) Vigência
            <br />
            Art. 2º A disciplina da proteção de dados pessoais tem como
            fundamentos:
            <br />
            I - o respeito à privacidade;
            <br />
            II - a autodeterminação informativa;
            <br />
            III - a liberdade de expressão, de informação, de comunicação e de
            opinião; e
            <br />
            IV - a inviolabilidade da intimidade, da honra e da imagem;
          </p>
        </div>
        <form onSubmit={confirmarPolitica}>
          <div className="checkbox-group">
            <input
              type="checkbox"
              id="concordo"
              className="checkbox-concordo"
            />
            <label htmlFor="concordo">Li e aceito os Termos de Uso</label>
          </div>
          <button type="submit" className="button-bem">
            Continuar
          </button>
        </form>
      </section>
      <section></section>
    </div>
  );
}
