const PASSO_INFO_DOCUMENTO = 1;
const PASSO_ESCOLHA_ACAO = 2;
const PASSO_UPLOAD = 3;
const PASSO_FOTO = 4;
const PASSO_IMAGEM = 5;
const ACAO_FOTO = 'Foto';
const ACAO_ANEXO = 'Anexo';

export {
  PASSO_INFO_DOCUMENTO,
  PASSO_ESCOLHA_ACAO,
  PASSO_UPLOAD,
  PASSO_FOTO,
  PASSO_IMAGEM,
  ACAO_FOTO,
  ACAO_ANEXO,
};
