import React from 'react';
import './styles.css';

export default function Timer(props) {
  return (
    <div
      className="timer-container"
      style={{ display: props.hide ? 'none' : '' }}
    >
      <span>{props.timer}</span>
    </div>
  );
}
