import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import MaskedInput from 'react-text-mask';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import api from 'services/api';

import { useApp } from 'context/App';

import { dataAno4DigitosMask } from 'helpers/masks';
import isValidDate from 'helpers/validate';
import dateFormat from 'helpers/dateFormat';

import PageLoader from 'components/PageLoader';

import audioDataNascimento from 'assets/audios/data-nascimento-4.mp3';

import { useAudio } from 'react-use';
import { VOLUME } from 'services/consts';
import BotaoFixado from 'components/BotaoFixado';
import './styles.css';

export default function Verificacao() {
  const [nascimento, setNascimento] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [dataNascimentoOk, setDataNascimentoOk] = useState(false);
  const { tokenSignatario, proximaPagina, ajudaPorVoz, alteraProgresso, configGlobal } =
    useApp();
  const history = useHistory();
  const [audio, state, controls, ref] = useAudio({
    src: audioDataNascimento,
    autoPlay: false,
  });

  useEffect(() => {
    if (ajudaPorVoz) {
      controls.volume(VOLUME);
      controls.play();
    }
  }, []);

  async function verificarNascimento(event) {
    event.preventDefault();

    if (!isValidDate(nascimento)) {
      toast.error('A data de nascimento informada é inválida');
      return;
    }

    const data = {
      token: tokenSignatario,
      nascimento: dateFormat(nascimento),
    };

    try {
      setIsVisible(true);
      const response = await api.post('Autenticacao/ValidarNascimento', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${window.localStorage.getItem('autorizacao')}`,
        },
      });

      setIsVisible(false);

      if (response.data.retorno) {
        alteraProgresso();
        navegarProximaPagina();
      }
    } catch (err) {
      setIsVisible(false);
      if (err.response && err.response.data && err.response.data.erros.length) {
        err.response.data.erros.map((erro) => toast.error(erro.mensagem));
      } else {
        toast.error('Erro ao validar data de nascimento');
      }
    }
  }

  function changeDataNascimento(valor) {
    setNascimento(valor);
    setDataNascimentoOk(valor.length === 10 && isValidDate(valor));
  }

  function navegarProximaPagina() {
    history.push(proximaPagina());
  }

  return (
    <div className="container verificacao-container" style={{ background: configGlobal.verificacao_cor_fundo }}>
      {audio}
      <section
        className={`loader-animation ${isVisible ? 'enabled' : 'hidden'}`}
      >
        <PageLoader isOverlay="true" width={125} height={250} />
      </section>
      <section className="form">
        <h1>Vamos começar?</h1>
        <h2>Primeiro confirme sua data de nascimento</h2>

        <fieldset className={!dataNascimentoOk && nascimento?.length === 10 ? `input-erro` : null}>
          <legend>Data de Nascimento</legend>
          <form onSubmit={(event) => verificarNascimento(event)}>
            <MaskedInput
              guide={false}
              mask={dataAno4DigitosMask}
              onChange={(e) => changeDataNascimento(e.target.value)}
              type="tel"
              style={{ background: configGlobal.verificacao_cor_fundo }}
            />
          </form>
        </fieldset>
      </section>
      <BotaoFixado
        onClick={(event) => verificarNascimento(event)}
        disabled={!dataNascimentoOk}
        borderTop
        corBotao={configGlobal.verificacao_cor_botao_primario}
        corFonte={configGlobal.verificacao_cor_fonte_botao_primario}
        cor="transparent"
      >
        Confirmar
      </BotaoFixado>
      <ToastContainer />
    </div>
  );
}
