export const ZoomStringsPtBr = {
  "FaceTec_accessibility_cancel_button": "Cancelar",

  "FaceTec_feedback_center_face": "Centralize seu rosto",
  "FaceTec_feedback_face_not_found": "Enquadre o seu rosto",
  "FaceTec_feedback_move_phone_away": "Afaste-se",
  "FaceTec_feedback_move_away_web": "Afaste-se",
  "FaceTec_feedback_move_phone_closer": "Aproxime-se",
  "FaceTec_feedback_move_phone_to_eye_level": "Telefone ao nível dos olhos",
  "FaceTec_feedback_move_to_eye_level_web": "Olhe para a câmera",
  "FaceTec_feedback_face_not_looking_straight_ahead": "Olhe para frente",
  "FaceTec_feedback_face_not_upright": "Mantenha a cabeça reta",
  "FaceTec_feedback_face_not_upright_mobile": "Alinhe a cabeça",
  "FaceTec_feedback_hold_steady": "Não se mova",
  "FaceTec_feedback_move_web_closer": "Aproxime-se",
  "FaceTec_feedback_move_web_even_closer": "Mais próximo",
  "FaceTec_feedback_use_even_lighting": "Ilumine seu rosto uniformemente",

  "FaceTec_instructions_header_ready": "Prepare-se para a selfie",
  "FaceTec_instructions_header_ready_1_mobile": "Prepare-se para a selfie",
  "FaceTec_instructions_header_ready_2_mobile": "",
  "FaceTec_instructions_header_ready_desktop": "Prepare-se para a selfie",
  "FaceTec_instructions_message_ready_desktop": `Enquadre seu rosto para prosseguir`,
  "FaceTec_instructions_message_ready_1": `Enquadre seu rosto para prosseguir`,
  "FaceTec_instructions_message_ready_2": `Enquadre seu rosto para prosseguir`,
  "FaceTec_instructions_message_ready_1_mobile": "Enquadre seu rosto para prosseguir",
  "FaceTec_instructions_message_ready_2_mobile": "",
  "FaceTec_action_im_ready": "Começar",
  "FaceTec_presession_frame_your_face": "Centralize seu rosto",
  "FaceTec_presession_look_straight_ahead": "Olhe para frente",
  "FaceTec_presession_hold_steady3": "Não se mova por: 3s",
  "FaceTec_presession_hold_steady2": "Não se mova por: 2s",
  "FaceTec_presession_hold_steady1": "Não se mova por: 1s",
  "FaceTec_presession_eyes_straight_ahead": "Olhe para frente",
  "FaceTec_presession_remove_dark_glasses": "Não utilize óculos",
  "FaceTec_presession_neutral_expression": "Expressão neutra, sem sorrir",
  "FaceTec_presession_conditions_too_bright": "Iluminação extrema",
  "FaceTec_presession_brighten_your_environment": "Ilumine seu ambiente",

  "FaceTec_result_facemap_upload_message": "Enviando<br/>os dados",
  "FaceTec_result_idscan_upload_message": "Enviando<br/>os dados",
  "FaceTec_result_facescan_upload_message": "Enviando<br/>os dados",


  "FaceTec_retry_header": "Vamos tentar novamente",
  "FaceTec_retry_subheader_message": "Precisamos de uma selfie mais clara",
  "FaceTec_retry_your_image_label": "Sua selfie",
  "FaceTec_retry_ideal_image_label": "Selfie ideal",
  "FaceTec_retry_instruction_message_1": "Sem iluminação ou iluminação extrema",
  "FaceTec_retry_instruction_message_2": "Expressão neutra, sem sorrir",
  "FaceTec_retry_instruction_message_3": "Muito embaçado, limpe a câmera",
  "FaceTec_action_ok": "OK",
  "FaceTec_action_try_again": "OK",

  "FaceTec_camera_permission_header": "Ativar câmera",
  "FaceTec_camera_permission_message": "O acesso à câmera está desativado. Toque abaixo para ativar em ajustes.",
  "FaceTec_browser_camera_help_action_link": "https://dev.zoomlogin.com/zoomsdk/#/browser-camera-help",
  "FaceTec_camera_permission_launch_settings": "Ajuda da câmera",

  "FaceTec_initializing_camera": "Iniciando a câmera...",
  "FaceTec_initializing_camera_still_loading": "",

  "FaceTec_idscan_type_selection_header": "Prepare-se para<br/>fotografar<br/>seu documento",
  "FaceTec_action_continue": "Continue",
  "FaceTec_action_select_id_card": "Documento com foto",
  "FaceTec_action_select_passport": "Passaporte",
  "FaceTec_idscan_capture_id_front_instruction_message": "Mostre a frente do documento",
  "FaceTec_idscan_capture_id_back_instruction_message": "Mostre o verso do documento",
  "FaceTec_idscan_capture_passport_instruction_message": "Mostre a página da foto no passaporte",
  "FaceTec_action_take_photo": "Tirar Foto",
  "FaceTec_idscan_review_id_front_instruction_message": "A foto está clara e legível?",
  "FaceTec_idscan_review_id_back_instruction_message": "Está claro e legível?",
  "FaceTec_idscan_review_passport_instruction_message": "A foto está clara e legível?",
  "FaceTec_action_accept_photo": "Sim",
  "FaceTec_action_retake_photo": "Não",
  "FaceTec_idscan_ocr_confirmation_main_header": "Revise e confirme",
  "FaceTec_action_confirm": "Confirme as informações",
  "FaceTec_result_idscan_unsuccess_message": "Foto do documento nāo corresponde com rosto do usuário",
  "FaceTec_idscan_additional_review_message": "Revisão adicional<br/>necessária",

  "FaceTec_result_idscan_success_front_side": "Verificação realizada",
  "FaceTec_result_idscan_success_front_side_with_back_side_next": "Verificação realizada",
  "FaceTec_result_idscan_success_back_side": "Documento capturado",
  "FaceTec_result_idscan_success_additional_review_message": "Captura completa",
  "FaceTec_result_idscan_retry_card_not_fully_visible": "Documento\r\nnão está visível",
  "FaceTec_result_idscan_retry_ocr_results_not_good_enough": "Documento\r\nnão está legível",
  "FaceTec_result_idscan_retry_id_type_not_suppported_message": "Documento<br />incompatível",

  "FaceTec_result_success_message": "Verificação de selfie\r\nrealizada"
};
