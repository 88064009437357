import { FacetecUtilities } from 'components/Liveness/facetec/utilities/FacetecUtilities2';
import { ZoomGlobalState } from '../../public/core-sdk/ZoomGlobalState';
import { ZoomStringsPtBr } from '../../public/core-sdk/ZoomStrings.pt-br';
import { FaceTecSDK } from '../../public/core-sdk/FaceTecSDK.js/FaceTecSDK.js';
import api from 'services/api';
import { Promise } from 'bluebird';

const inicializaFacetec = async (idEmpresa = null) => {
  const dadosRetornoFacetec = {
    status: 0,
    erro: '',
    inicializado: false,
    possuiErro: function () {
      return this.erro.length;
    },
  };

  try {
    const response = await api.get(`Facetec/ObterLicencaSftp`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('autorizacao')}`,
      },
    });

    const SFTPLicenseText = JSON.parse(response.data.retorno);

    FaceTecSDK.setResourceDirectory(
      '../../public/core-sdk/FaceTecSDK.js/resources',
    );
    FaceTecSDK.setImagesDirectory('../../public/core-sdk/FaceTec_images');

    FaceTecSDK.setCustomization(
      ZoomGlobalState.retrieveConfigurationWizardCustomization(FaceTecSDK),
    );

    const promise = new Promise((resolve) => {
      FaceTecSDK.initializeInProductionMode(
        SFTPLicenseText,
        ZoomGlobalState.DeviceLicenseKeyIdentifier,
        ZoomGlobalState.PublicFaceMapEncryptionKey,
        async function (initializedSuccessfully) {
          if (initializedSuccessfully) {
            FaceTecSDK.configureLocalization(ZoomStringsPtBr);
            FacetecUtilities.configureGuidanceScreenTextForDevice();
            FacetecUtilities.setVocalGuidanceSoundFiles();
            if (idEmpresa) {
              FacetecUtilities.changeImageLogo(idEmpresa);
            }
          }

          dadosRetornoFacetec.status = FaceTecSDK.getStatus();
          dadosRetornoFacetec.inicializado = initializedSuccessfully;

          resolve();
        },
      );
    });

    await promise;

    return dadosRetornoFacetec;
  } catch (error) {
    dadosRetornoFacetec.erro = 'Erro ao carregar dados';

    return dadosRetornoFacetec;
  }
};

export default inicializaFacetec;
