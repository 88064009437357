import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useApp } from '../../context/App';
import './styles.css';
import { BemFacetec } from './facetec/processors/helpers/BemFacetec';
import { COMPONENTE_DOCUMENTO } from 'helpers/enums';
import api from 'services/api';

export default function Liveness(props) {
  let latestZoomSessionResult = null;
  let latestZoomIDScanResult = null;

  const history = useHistory();
  const {
    setSelfie,
    setMatchLevel,
    setDocumentoIdentidadeFrente,
    setDocumentoIdentidadeVerso,
    tokenSignatario,
    facematchConfig,
    livenessConfig,
    proximaPagina,
    setIsLiveness,
    coletaDocumentoConfig,
    componenteDocumentoConfig,
    alteraProgresso,
  } = useApp();

  useEffect(() => {
    if (componenteDocumentoConfig === COMPONENTE_DOCUMENTO.FACETEC) {
      onPhotoIDMatchPressed();
    } else if (livenessConfig) {
      onLivenessCheckPressed();
    }
  }, []);

  function onLivenessCheckPressed() {
    BemFacetec.onLivenessCheckPressed(onProcessingComplete, tokenSignatario);
  }

  function onPhotoIDMatchPressed() {
    BemFacetec.onPhotoIDMatchPressed(
      onPhotoIDMatchProcessingComplete,
      onPhotoIDMatchProcessingFail,
      tokenSignatario,
      falhaNovaSessao
    );
  }

  async function onPhotoIDMatchProcessingComplete(
    isSuccess,
    zoomSessionResult,
    zoomIDScanResult,
  ) {
    latestZoomSessionResult = zoomSessionResult;
    latestZoomIDScanResult = zoomIDScanResult;

    if (latestZoomSessionResult.auditTrail.length && isSuccess) {
      window.localStorage.setItem(
        'selfie',
        latestZoomSessionResult.auditTrail[0],
      );
      setSelfie(latestZoomSessionResult.auditTrail[0]);
      setMatchLevel(Number(window.localStorage.getItem('matchLevel')) || 0);

      if (latestZoomIDScanResult.frontImages.length) {
        setDocumentoIdentidadeFrente(latestZoomIDScanResult.frontImages[0]);
      }

      if (
        !zoomIDScanResult.isCompletelyDone &&
        !latestZoomIDScanResult.backImages.length
      ) {
        await gravaLog(`1- Direcionando para coleta manual do verso do documento: ${zoomIDScanResult.isCompletelyDone} - ${latestZoomIDScanResult.backImages.length}`);
        return history.push('/identificacao');
      } else if (latestZoomIDScanResult.backImages.length) {
        setDocumentoIdentidadeVerso(latestZoomIDScanResult.backImages[0]);
      } else {
        await gravaLog(`2- Direcionando para coleta manual do verso do documento: ${zoomIDScanResult.isCompletelyDone} - ${latestZoomIDScanResult.backImages.length}`);
        return history.push('/identificacao');
      }

      alteraProgresso();

      history.push(proximaPagina());
    }
  }

  function onProcessingComplete(isSuccess, zoomSessionResult) {
    latestZoomSessionResult = zoomSessionResult;
    latestZoomIDScanResult = null;

    if (!isSuccess) {
      setSelfie(latestZoomSessionResult.auditTrail[0]);
      setIsLiveness(false);
      props.setFaceteInicializada(false);
    } else {
      if (latestZoomSessionResult.auditTrail.length) {
        window.localStorage.setItem(
          'selfie',
          latestZoomSessionResult.auditTrail[0],
        );
        setSelfie(latestZoomSessionResult.auditTrail[0]);

        alteraProgresso();

        if (coletaDocumentoConfig && componenteDocumentoConfig === COMPONENTE_DOCUMENTO.BEM) {
          history.push('/identificacao');
        } else {
          history.push(proximaPagina());
        }
      }
    }
  }

  function onPhotoIDMatchProcessingFail(sessionResult) {
    window.localStorage.setItem('selfie', sessionResult.auditTrail[0]);
    setSelfie(sessionResult.auditTrail[0]);
    setIsLiveness(false);

    props.setFaceteInicializada(false);
  }

  function falhaNovaSessao() {
    history.push('/tentativa');
  }

  async function gravaLog(mensagem) {
    const data = {
      token: tokenSignatario,
      mensagem: mensagem,
      tipo: 1,
    };

    await api.post(`BemSign/GravarLog`, data, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('autorizacao')}`,
        'Content-Type': 'application/json',
      },
    });
  }

  return (
    <div className="container liveness-container">
      <p id="status"></p>
    </div>
  );
}
