import React from 'react';

import Safari from 'assets/safari.png';
import Permitir from 'assets/permitir.png';
import Check from 'assets/check.png';
import Ajustes1 from 'assets/ajustes-1.png';
import Ajustes2 from 'assets/ajustes-2.png';
import Direcional from 'assets/direc.png';

export default function SafariInstrucao() {
  return (
    <div className="instrucao-camera">
      <h1>Safari no iPhone</h1>
      <ol dir="ltr">
        <li>
          No app Safari{' '}
          <img src={Safari} width="18" height="18" alt="navegador-safari" /> do
          iphone, escolha Safari{' '}
          <img src={Direcional} width="18" height="18" alt="e" title="e" />
          Ajustes para este Site{' '}
          <img src={Ajustes1} width="30" height="30" alt="ajustes" />.
        </li>
        <li>
          Após abrir a janela de Ajustes, toque em Ajustes do Site{' '}
          <img src={Ajustes2} width="120" alt="ajustes" />.
        </li>
        <li>
          Toque em Câmera ou Localização.
          <ul>
            <li data-outlined="false">
              Se a opção Negar estiver marcada com{' '}
              <img src={Check} width="25" height="25" alt="check" />, toque na
              opção Permitir.
              <br />
              <img src={Permitir} alt="permitir" />
            </li>
          </ul>
        </li>
        <li>Após permitir o acesso, clicar em OK no topo direito da tela.</li>
      </ol>
    </div>
  );
}
