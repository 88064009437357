import React, { useEffect, useState } from 'react';

import './styles.css';

import CedulaCreditoBancario from './Templates/CedulaCreditoBancario';
import ComprovanteResidencia from './Templates/ComprovanteResidencia';
import PropostaOperacaoCredito from './Templates/PropostaOperacaoCredito';
import TermoAutorizacao from './Templates/TermoAutorizacao';
import SolicitacaoPortabilidade from './Templates/SolicitacaoPortabilidade';
import AumentoPortabilidade from './Templates/AumentoPortabilidade';
import TermoAdesao from './Templates/TermoAdesao';
import AutorizacaoLiquidarDivida from './Templates/AutorizacaoLiquidarDivida';
import ContratoSeguro from './Templates/ContratoSeguro';
import TermoAdesaoFGTS from './Templates/TermoAdesaoFGTS';
import TermoValidacaoFigital from './Templates/TermoValidacaoFigital';
import Cartao from './Templates/Cartao';
import CadastroAgente from './Templates/CadastroAgente';

export default function DocumentoTemplate(props) {
  const [alturaBottom, setAlturaBottom] = useState('0');
  let component = <h1>Documento não encontrado</h1>;

  useEffect(() => {
    if (props.dimensaoBottom)
      setAlturaBottom(`${props.dimensaoBottom.height}px`);
  }, [props.dimensaoBottom]);

  switch (props.idTemplate) {
    case 11:
      component = (
        <ContratoSeguro
          setDadosValidos={props.setDadosValidos}
          setIsLoading={props.setIsLoading}
          idDocumento={props.idDocumento}
          idExterno={props.idExterno}
          setBloqueioScroll={props.setBloqueioScroll}
        />
      );
      break;
    case 13:
      component = (
        <SolicitacaoPortabilidade
          setDadosValidos={props.setDadosValidos}
          setIsLoading={props.setIsLoading}
          idDocumento={props.idDocumento}
          idExterno={props.idExterno}
          setBloqueioScroll={props.setBloqueioScroll}
        />
      );
      break;
    case 15:
      component = (
        <AumentoPortabilidade
          setDadosValidos={props.setDadosValidos}
          setIsLoading={props.setIsLoading}
          idDocumento={props.idDocumento}
          idExterno={props.idExterno}
          setBloqueioScroll={props.setBloqueioScroll}
        />
      );
      break;
    case 16:
      component = (
        <CedulaCreditoBancario
          setDadosValidos={props.setDadosValidos}
          setIsLoading={props.setIsLoading}
          idDocumento={props.idDocumento}
        />
      );
      break;
    case 28:
      component = (
        <AutorizacaoLiquidarDivida
          setDadosValidos={props.setDadosValidos}
          setIsLoading={props.setIsLoading}
          idDocumento={props.idDocumento}
          idExterno={props.idExterno}
          setBloqueioScroll={props.setBloqueioScroll}
        />
      );
      break;
    case 23:
      component = (
        <ComprovanteResidencia
          setDadosValidos={props.setDadosValidos}
          setIsLoading={props.setIsLoading}
          setBloqueioScroll={props.setBloqueioScroll}
        />
      );
      break;
    case 38:
      component = (
        <TermoAutorizacao
          setDadosValidos={props.setDadosValidos}
          idDocumento={props.idDocumento}
          setBloqueioScroll={props.setBloqueioScroll}
        />
      );
      break;
    case 40:
      component = (
        <PropostaOperacaoCredito
          setDadosValidos={props.setDadosValidos}
          setIsLoading={props.setIsLoading}
          idDocumento={props.idDocumento}
          idExterno={props.idExterno}
        />
      );
      break;
    case 45:
      component = (
        <TermoAdesao
          setDadosValidos={props.setDadosValidos}
          setIsLoading={props.setIsLoading}
          idDocumento={props.idDocumento}
          idExterno={props.idExterno}
          setBloqueioScroll={props.setBloqueioScroll}
        />
      );
      break;
    case 53:
      component = (
        <TermoAdesaoFGTS
          setDadosValidos={props.setDadosValidos}
          setIsLoading={props.setIsLoading}
          idDocumento={props.idDocumento}
          setBloqueioScroll={props.setBloqueioScroll}
        />
      );
      break;
    case 56:
      component = (
        <TermoValidacaoFigital
          setDadosValidos={props.setDadosValidos}
          setIsLoading={props.setIsLoading}
          setBloqueioScroll={props.setBloqueioScroll}
        />
      );
      break;
    case 57:
      component = (
        <Cartao
          setDadosValidos={props.setDadosValidos}
          setIsLoading={props.setIsLoading}
          setBloqueioScroll={props.setBloqueioScroll}
        />
      );
      break;
    case 99:
      component = (
        <CadastroAgente
          setDadosValidos={props.setDadosValidos}
          setIsLoading={props.setIsLoading}
          setBloqueioScroll={props.setBloqueioScroll}
        />
      );
      break;
    default:
      break;
  }

  return (
    <div className="template-container" style={{ marginBottom: alturaBottom }}>
      {component}
    </div>
  );
}
