// import Selfie from 'assets/selfie.png';
import { ReactComponent as Documento } from 'assets/carteira-de-identidade.svg';
import { ReactComponent as Cell } from 'assets/mensagem-sms.svg';
import { ReactComponent as ConfirmaDados } from 'assets/documento-verificado.svg';
import Assinatura from 'assets/assinatura.png';
import { ReactComponent as Selfie } from 'assets/selfie-v2.svg';

const listaCards = (config) => {
  let lista = [];

  lista.push({
    image: ConfirmaDados,
    altText: 'confirmar dados',
    text: 'Confirmar dados',
  });

  if (config.realizarLiveness) {
    lista.push({
      image: Selfie,
      altText: 'selfie',
      text: 'Uma foto sua',
    });
  }
  
  if (config.realizarFacematch || config.coletaDocumento) {
    lista.push({
      image: Documento,
      altText: 'documento',
      text: 'Uma foto do seu documento',
    });
  }

  if (config.validarTokenSms) {
    lista.push({
      image: Cell,
      altText: 'celular',
      text: 'Confirmação via SMS',
    });
  }

  if (config.assinaturaDedo) {
    lista.push({
      image: Assinatura,
      altText: 'assinatura',
      text: 'Registro da sua assinatura',
    });
  }

  return lista;
};

export default listaCards;
