const ERRO_NEGOCIO = 1;
const ERRO_FORMULARIO = 2;
const CODIGO_ERRO_FACETEC = 5;
const CODIGO_SUCESSO_FACETEC = 8;
const PATH_URL = '/b/';

export {
  ERRO_NEGOCIO,
  ERRO_FORMULARIO,
  CODIGO_ERRO_FACETEC,
  CODIGO_SUCESSO_FACETEC,
  PATH_URL,
};
