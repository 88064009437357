export const isAuthenticated = (isPortal) => {
  if (window.localStorage.getItem(isPortal ? 'autenticacao-portal' : 'autorizacao') && window.localStorage.getItem(isPortal ? 'autenticacao-portal' : 'autorizacao') != 'null') {
    return (
      Date.now() < getExpirationDate(window.localStorage.getItem(isPortal ? 'autenticacao-portal' : 'autorizacao'))
    );
  }

  return false;
};

export const getExpirationDate = (jwtToken) => {
  const jwt = JSON.parse(atob(jwtToken.split('.')[1]));

  return (jwt && jwt.exp && jwt.exp * 1000) || null;
};

export const getAutenticacaoId = () => {
  const jwtToken = window.localStorage.getItem('autorizacao')
  if (!jwtToken) return null;

  const jwtDescoded = new Buffer.from(jwtToken.split('.')[1], 'base64').toString('utf-8')
  const jwt = JSON.parse(jwtDescoded);

  return jwt.autenticacaoId
};

export const getAutenticacaoIdBase64 = () => {
  const autenticacaoId = getAutenticacaoId();
  if (!autenticacaoId) return null;
  
  return Buffer.from(autenticacaoId).toString("base64");
};
