const CONSIGNADO = 1;
const BANRISUL = 6;
const BANRISUL_2 = 7;
const BANRISUL_PORTAL_EMPRESARIAL = 12;
const FGTS = 4;
const FUNDACAO = 9;
const COR_PADRAO = "#FFF";

export {
  CONSIGNADO,
  BANRISUL,
  BANRISUL_2,
  FGTS,
  FUNDACAO,
  COR_PADRAO,
  BANRISUL_PORTAL_EMPRESARIAL,
};
