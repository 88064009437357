import React from 'react'
import Erro from 'assets/erro.png';
import './styles.css';
import { useApp } from 'context/App';

export default function Tentativa() {
    const { configGlobal } = useApp();

    const corPrimaria = configGlobal?.tentativa_cor_fundo;

    return <div className="container tentativa-container" style={{ background: corPrimaria }}>
        <h1>Ops!</h1>
        <img src={Erro} alt="Alerta" />
        <h2>O número de tentativas foi excedido.</h2>
        <h2>Reinicie o processo.</h2>
    </div>
}