import CryptoJS from 'crypto-js'
import { SALT_PBKDF2 } from 'services/consts';

const pbkdf2 = (password) => {
    const salt = SALT_PBKDF2;
    const hasher = CryptoJS.algo.SHA1;
    const iterations = 10000;
    const keySize = 8;

    return CryptoJS.PBKDF2(password, salt, { 
        hasher: hasher,
        iterations: iterations,
        keySize: keySize
    }).toString(CryptoJS.enc.Base64)
}

export default pbkdf2;