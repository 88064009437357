import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useApp } from 'context/App';

import virtualLocation from 'helpers/virtualLocation';

import { ReactComponent as LocalizacaoIcon } from 'assets/icon-localization.svg';
import api from 'services/api';
import './styles.css';

export default function Localizacao() {
  const { setLatitude, setLongitude, setGeolocalizacaoPorIp, proximaPagina, configGlobal } =
    useApp();
  const history = useHistory();

  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, errorPosition);
    }
  }

  function navegarProximaPagina() {
    history.push(proximaPagina());
  }

  async function showPosition(position) {
    if (!position.coords.latitude || !position.coords.longitude) {
      await obterGeolocalizacaoPorIp();
    } else {
      await gravarGeolocalizacaoAssinatura({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        precisao: position.coords.accuracy,
      });

      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    }

    navegarProximaPagina();
  }

  async function obterGeolocalizacaoPorIp() {
    try {
      const ipLocation = await virtualLocation();

      await gravarGeolocalizacaoAssinatura({
        latitude: ipLocation.latitude,
        longitude: ipLocation.longitude,
      });

      setLatitude(ipLocation.latitude);
      setLongitude(ipLocation.longitude);
      setGeolocalizacaoPorIp(true);
    } catch (error) { }
  }

  async function errorPosition(error) {
    if (error.code === error.PERMISSION_DENIED) {
      await obterGeolocalizacaoPorIp();
    }

    navegarProximaPagina();
  }

  useEffect(() => {
    getLocation();
  }, []);

  const gravarGeolocalizacaoAssinatura = async (dados) => {
    try {
      await api.post('BemSign/GravarGeolocalizacaoAssinatura', dados, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${window.localStorage.getItem('autorizacao')}`,
        },
      });
    } catch (error) { }
  };

  return (
    <div className="container localizacao-container" style={{ background: configGlobal.localizacao_cor_fundo }}>
      <LocalizacaoIcon fill={configGlobal.localizacao_cor_icone} />
      <h2>
        Para sua segurança é importante que você permita o acesso a sua
        localização.
      </h2>
    </div>
  );
}
