import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { cpfMask } from 'helpers/masks';
import MaskedInput from 'react-text-mask';
import PageLoader from 'components/PageLoader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getOnlyNumbers from 'helpers/getOnlyNumbers';
import api, { apiAuth } from 'services/api';
import { useApp } from 'context/App';

import './styles.css';

export default function AutenticacaoPortal() {
  const [botaoProximoDesabilitado, setDesabilitarBotaoProximo] = useState(true);
  const [senhaOk, setValidacaoSenha] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [senha, setSenha] = useState('');
  const {
    setTokenSignatario,
    setFinalCelular,
    setCliente,
    cliente,
    setCpf,
    tokenSMS,
    setUsuarioValido,
    usuarioValido,
    cpf,
    senhaRedefinida,
    setSenhaRedefinida,
    sessaoExpiradaPortal,
    setSessaoExpiradaPortal,
    setParteEmail,
    setSemNumeroCelular,
  } = useApp();
  const history = useHistory();

  useEffect(() => {
    if (sessaoExpiradaPortal) {
      toast.error('Sessão expirada');
      setSessaoExpiradaPortal(false);
    }

    if (!cpf.length && usuarioValido) {
      setUsuarioValido(false);
    } else if (senhaRedefinida) {
      toast.success('Senha redefinida com sucesso');
      setSenhaRedefinida(false);
    }
  }, []);

  function entrar(e) {
    e.preventDefault();
    login();
  }

  async function login() {
    try {
      setIsLoading(true);

      const data = {
        usuario: getOnlyNumbers(cpf),
        senha: senha,
      };

      const response = await apiAuth.post(`Autenticacao/Autenticar`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setIsLoading(false);

      if (response.data.retorno) {
        window.localStorage.setItem(
          'autenticacao-portal',
          response.data.retorno,
        );
        history.push('/detalhes');
      } else {
        throw new Error();
      }
    } catch (error) {
      setIsLoading(false);
      toast.error('senha inválida');
    }
  }

  function changeCpf(valor) {
    setCpf(valor);
    setDesabilitarBotaoProximo(valor.length !== 14);
  }

  function changeSenha(valor) {
    setSenha(valor);
    setValidacaoSenha(valor && valor.length > 0);
  }

  function redefinirSenha() {
    history.push('/token-portal');
  }

  async function obterUsuario(e) {
    e.preventDefault();

    try {
      setIsLoading(true);

      const response = await apiAuth.get(
        `Autenticacao/ObterDadosRedefinicaoSenhaUsuario/${getOnlyNumbers(cpf)}`,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              'autenticacao-portal',
            )}`,
          },
        },
      );

      setIsLoading(false);

      const retorno = response.data.retorno;

      preencheEstados(retorno);

      verificarRegras(retorno);
    } catch (error) {
      setIsLoading(false);
      toast.error('Ocorreu um erro ao verificar seu CPF');
    }
  }

  function preencheEstados(retorno) {
    setFinalCelular(retorno.finalCelular);
    setCliente(retorno.nome);
    setTokenSignatario(retorno.tokenAcesso);
    setParteEmail(retorno.email);
    setSemNumeroCelular(retorno.finalCelular === '');
  }

  function verificarRegras(usuario) {
    if (usuario.id == null || usuario.id == 0) {
      return toast.error('CPF inválido');
    }

    if (usuario.redefinirSenha) {
      return history.push('/token-portal');
    } else {
      setUsuarioValido(true);
    }
  }

  return (
    <div className="container autenticacao-portal-container">
      <section
        className={`loader-animation ${isLoading ? 'enabled' : 'hidden'}`}
      >
        <PageLoader isOverlay="true" width={125} height={250} />
      </section>
      <section
        className="form"
        style={{
          display: usuarioValido ? 'none' : '',
        }}
      >
        <h1>Olá!</h1>
        <h2>Para começar, preciso que você se identifique.</h2>

        <form>
          <fieldset>
            <legend>CPF</legend>
            <MaskedInput
              guide={false}
              mask={cpfMask}
              onChange={(e) => changeCpf(e.target.value)}
            />
          </fieldset>
          <button
            className="button-bem"
            onClick={obterUsuario}
            disabled={botaoProximoDesabilitado}
          >
            Confirmar
          </button>
        </form>
      </section>
      <section
        className="form"
        style={{
          display: !usuarioValido ? 'none' : '',
        }}
      >
        <h2>Olá, {cliente}</h2>

        <form>
          <fieldset className="fs-senha">
            <legend>Senha</legend>
            <input
              type="password"
              value={senha}
              onChange={(e) => changeSenha(e.target.value)}
            />
          </fieldset>

          <a onClick={redefinirSenha} className="redefine-senha">
            redefinir senha
          </a>

          <button className="button-bem" onClick={entrar} disabled={!senhaOk}>
            Entrar
          </button>
        </form>
      </section>
      <ToastContainer />
    </div>
  );
}
