import moment from 'moment';

const isValidDate = (data, formato = 'DD/MM/YYYY') => {
  if (data && data.length === formato.length) {
    const objetoData = moment(data, formato);
    const now = moment();
    return (
      objetoData.isValid() &&
      objetoData.isSameOrBefore(now, 'day') &&
      now.diff(objetoData, 'year') >= 1 &&
      now.diff(objetoData, 'year') <= 120
    );
  }

  return false;
};

export default isValidDate;
