import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import browserList from 'helpers/browserList';
import { getListBrowser } from 'helpers/isBrowserValid';
import copyToClipboard from 'helpers/copyToClipboard';
import { useApp } from 'context/App';
import api from 'services/api';
import { MENSAGEM_NAVEGADOR, CODIGO_NAVEGADOR } from './consts';

import './styles.css';

export default function Navegador() {
  const history = useHistory();
  const listaNavegadores = browserList();
  const [desabilitaContinuar, setDesabilitaContinuar] = useState(true);
  const { pathToken, exibirPassosConfig, tokenSignatario, configGlobal } = useApp();

  const corPrimaria = configGlobal?.navegador_cor_fundo;
  const bordaPrimaria = configGlobal?.navegador_cor_fundo ? `1px solid ${configGlobal?.navegador_cor_fundo}` : null;

  useEffect(() => {
    const logNavegador = async () => {
      const listaUserAgent = await getListBrowser();

      setDesabilitaContinuar(
        listaUserAgent.some((elem) => elem.name === 'Android') &&
        listaUserAgent.some(
          (elem) => elem.name === 'SamsungBrowser' && elem.version < 8,
        ),
      );

      const data = {
        token: tokenSignatario,
        mensagem: MENSAGEM_NAVEGADOR,
        tipo: CODIGO_NAVEGADOR,
      };

      await api.post(`BemSign/GravarLog`, data, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('autorizacao')}`,
          'Content-Type': 'application/json',
        },
      });
    };
    logNavegador();
  }, []);

  function copyLink() {
    copyToClipboard(`${window.location.origin}${pathToken}`);
    toast.success('Link copiado com sucesso');
  }

  function continuar() {
    if (exibirPassosConfig) {
      history.push('/passos');
    } else {
      history.push('/solicitacao-acesso');
    }
  }

  return (
    <div className="container navegador-container">
      <h1>Atenção</h1>
      <h2>
        Para continuar é recomendável a utilização de um dos seguintes
        navegadores:
      </h2>

      {listaNavegadores.map((nav, index) => (
        <table
          className="table-group"
          cellSpacing="0"
          cellPadding="0"
          key={index}
          style={{ borderLeft: bordaPrimaria, borderRight: bordaPrimaria, borderBottom: bordaPrimaria }}
        >
          <thead>
            <tr>
              <th colSpan="2" style={{ backgroundColor: corPrimaria }}>{nav.device}</th>
            </tr>
          </thead>
          <tbody>
            {nav.browsers.map((browser, index) => (
              <tr key={index}>
                <td style={{ borderTop: bordaPrimaria }}>
                  <img src={browser.image} alt={browser.name} />
                </td>
                <td style={{ borderTop: bordaPrimaria }}>{browser.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ))}
      <button className="button-bem copiar-link" onClick={copyLink}>
        Copiar Link
      </button>
      {!desabilitaContinuar && (
        <button className="button-bem" onClick={continuar} style={{ background: configGlobal.navegador_cor_botao_primario }}>
          Continuar
        </button>
      )}
      <ToastContainer />
    </div>
  );
}
