import { valorFinanceiroParaTexto } from 'components/AudioAcessibilidade/formatters';
import { executarAudio } from 'components/AudioAcessibilidade/utils'
import { formatarStringDataTemplate } from 'helpers/Formatters';

export default function AudioTermoAdesaoFGTSResumo(dados, tokenSignatario) {
    let texto = `
    Resumo da sua antecipação de FGTS:
    Valor a receber: @VALOR_A_RECEBER.
    Valor antecipado: @VALOR_ANTECIPADO.
    Cet mês: @CET_MES.
    Total IOF: @TOTAL_IOF.
    Primeira data antecipada: @PRIMEIRA_DATA.
    Última data antecipada: @ULTIMA_DATA.`;

    texto = texto
        .replace('@VALOR_A_RECEBER', valorFinanceiroParaTexto(dados.valorAReceber))
        .replace('@VALOR_ANTECIPADO', valorFinanceiroParaTexto(dados.totalAntecipacao))
        .replace('@CET_MES', `${dados.cetMes.toString().replace('.', ',')} %`)
        .replace('@TOTAL_IOF', valorFinanceiroParaTexto(dados.totalIOF))
        .replace('@PRIMEIRA_DATA', formatarStringDataTemplate(dados.dataAntecipacao.primeiraData))
        .replace('@ULTIMA_DATA', formatarStringDataTemplate(dados.dataAntecipacao.ultimaData));

    executarAudio(texto, tokenSignatario);
}
