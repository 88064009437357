import { ROLE_ADM } from 'pages/Login/consts';
import { useState, useEffect } from 'react';

const useRole = () => {
    const [isAdmRole, setIsAdmRole] = useState(false);
    const jwtToken = localStorage.getItem('autorizacao-score');


    const getIsAdmRole = () => {
        if (jwtToken) {
            const jwtDecoded = new Buffer.from(jwtToken.split('.')[1], 'base64').toString('ascii')

            const jwt = JSON.parse(jwtDecoded);

            return jwt.role === ROLE_ADM;
        }

        return false;
    }

    useEffect(() => {
        const isAdm = getIsAdmRole();

        setIsAdmRole(isAdm);

    }, [jwtToken])

    return { isAdmRole, getIsAdmRole };
}

export default useRole;