const BIOMETRIA_ETAPA = {
    INFORMACOES: 'INFO',
    BIOMETRIA: 'BIOMETRIA'
}

const QUANTIDADE_REQUISICOES = 10;

const STATUS_PROCESSO = {
    EXIBE: 30,
    ERRO: 99
}

export { BIOMETRIA_ETAPA, QUANTIDADE_REQUISICOES, STATUS_PROCESSO }