import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";

import { isAuthenticated } from "auth";

import { useApp } from "context/App";

import retornaBasename from "helpers/retornaBasename";

import Header from "theme/Header";
import Footer from "theme/Footer";

import Autenticacao from "pages/Autenticacao";
import Biometria from "pages/Biometria";
import Documento from "pages/Documento";
import Assinatura from "pages/Assinatura";
import Token from "pages/Token";
import Passos from "pages/Passos";
import ConfirmacaoDados from "pages/ConfirmacaoDados";
import Conclusao from "pages/Conclusao";
import Politica from "pages/Politica";
import Verificacao from "pages/Verificacao";
import Localizacao from "pages/Localizacao";
import LocalizacaoAviso from "pages/Avisos/Localizacao";
import SessaoAviso from "pages/Avisos/Sessao";
import Aviso from "pages/Avisos";
import Navegador from "pages/Navegador";
import Anexo from "pages/Anexo";
import AutenticacaoPortal from "pages/Portal/AutenticacaoPortal";
import Detalhes from "pages/Portal/Detalhes";
import TokenPortal from "pages/Portal/Token";
import RedefineSenha from "pages/Portal/RedefineSenha";
import Orientacao from "pages/Orientacao";
import Camera from "pages/Camera";
import SolicitacaoAcesso from "pages/SolicitacaoAcesso";
import Usuario from "pages/Usuario";
import CameraInstrucao from "pages/CameraInstrucao";
import Informacoes from "pages/Informacoes";
import Identificacao from "pages/Identificacao";
import VideoUsuario from "pages/VideoUsuario";
import Validador from "pages/Validador";
import Tentativa from "pages/Tentativa";
import Login from "pages/Login";
import Score from "pages/Score";
import BiometriaScore from "pages/BiometriaScore";
import Relatorio from "pages/Relatorio";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { token, tokenSignatario } = useApp();
  const history = useHistory();

  useEffect(() => {
    if (
      tokenSignatario !== "" &&
      tokenSignatario !==
        window.localStorage.getItem("url-token").replace("/b/", "")
    ) {
      return history.push("/sessao");
    }
  });

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated(false) && token ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: window.localStorage.getItem("url-token")
                ? window.localStorage.getItem("url-token")
                : "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default function Routes() {
  const [basename, setBasename] = useState("");

  useEffect(() => {
    setBasename(retornaBasename());
  }, []);

  return (
    basename !== "" && (
      <BrowserRouter basename={basename}>
        <Header />
        <Switch>
          <Route path="/" exact component={AutenticacaoPortal} />
          <Route path="/b/:token" component={Autenticacao} />
          <PrivateRoute path="/biometria" component={Biometria} />
          <PrivateRoute path="/documento" component={Documento} />
          <PrivateRoute path="/assinatura" component={Assinatura} />
          <PrivateRoute path="/token" component={Token} />
          <PrivateRoute path="/passos" component={Passos} />
          <PrivateRoute
            path="/confirmacao-dados"
            component={ConfirmacaoDados}
          />
          <PrivateRoute path="/conclusao" component={Conclusao} />
          <PrivateRoute path="/politica" component={Politica} />
          <PrivateRoute path="/verificacao" component={Verificacao} />
          <PrivateRoute path="/localizacao" component={Localizacao} />
          <PrivateRoute
            path="/localizacao-aviso"
            component={LocalizacaoAviso}
          />
          <PrivateRoute path="/camera" component={Camera} />
          <PrivateRoute path="/instrucao-navegador" component={Navegador} />
          <PrivateRoute path="/instrucao-camera" component={CameraInstrucao} />
          <PrivateRoute path="/anexos" component={Anexo} />
          <Route path="/sessao" component={SessaoAviso} />
          <Route path="/aviso" component={Aviso} />
          <Route path="/redefine-senha" component={RedefineSenha} />
          <Route path="/detalhes" component={Detalhes} />
          <Route path="/token-portal" component={TokenPortal} />
          <PrivateRoute path="/orientacao" component={Orientacao} />
          <PrivateRoute
            path="/solicitacao-acesso"
            component={SolicitacaoAcesso}
          />
          <PrivateRoute path="/usuario" component={Usuario} />
          <PrivateRoute path="/informacoes" component={Informacoes} />
          <PrivateRoute path="/identificacao" component={Identificacao} />
          <Route path="/video-usuario/:token" component={VideoUsuario} />
          <Route
            exact
            path={[
              "/verificador-assinatura",
              "/verificador-assinatura/:identificador",
            ]}
            component={Validador}
          />
          <PrivateRoute path="/tentativa" component={Tentativa} />
          <Route path="/login" component={Login} />
          <Route path="/score" component={Score} />
          <Route path="/biometria-score" component={BiometriaScore} />
          <Route path="/relatorio-biometria" component={Relatorio} />
        </Switch>
      </BrowserRouter>
    )
  );
}
