import React, { useEffect, useState } from 'react';
import {
  formatarMoeda,
  formatarStringDataTemplate,
  formatarPercentual,
} from 'helpers/Formatters';
import { VOLUME } from 'services/consts';
import audioResumoProposta from 'assets/audios/resumo-proposta.mp3';
import { useAudio } from 'react-use';
import { AudioAcessibilidade } from 'components/AudioAcessibilidade';
import { cancelarAudio } from 'components/AudioAcessibilidade/utils';
import AudioOn from 'assets/audio-on.png';
import { useApp } from 'context/App';

import './styles.css';

export default function Resumo(prop) {
  const { dadosFGTS } = prop;
  const [totalIOF, setTotalIOF] = useState(0);
  const [dataAntecipacao, setDataAntecipacao] = useState({
    primeiraData: null,
    ultimaData: null,
  });
  const [totalAntecipacao, setTotalAntecipacao] = useState(0);
  const [audio, state, controls, ref] = useAudio({
    src: audioResumoProposta,
    autoPlay: false,
  });
  const { ajudaPorVoz, tokenSignatario, habilitaAudio } = useApp();

  useEffect(() => {
    obtemTotalIOF();
    obtemDatasAntecipacao();
    obtemValorAntecipacao();

    if (ajudaPorVoz && !habilitaAudio) {
      controls.volume(VOLUME);
      controls.play();
    }

    if (habilitaAudio) {
      executaAudioResumo();
    }

    return () => {
      if (habilitaAudio) cancelarAudio(tokenSignatario);
    };
  }, []);

  const obtemTotalIOF = () => {
    const somaIOF = dadosFGTS.adiantamentos.reduce((a, b) => ({
      valorIOF: a.valorIOF + b.valorIOF,
    }));

    setTotalIOF(somaIOF.valorIOF);
  };

  const obtemDatasAntecipacao = () => {
    let datasAntecipacao = null;

    const ultimoIndex = dadosFGTS.adiantamentos.length - 1;
    const primeiraData = dadosFGTS.adiantamentos[0].dataAntecipacao;
    const ultimaData = dadosFGTS.adiantamentos[ultimoIndex].dataAntecipacao;

    datasAntecipacao = {
      primeiraData,
      ultimaData,
    };

    setDataAntecipacao(datasAntecipacao);
  };

  const obtemValorAntecipacao = () => {
    const somaValorAntecipacao = dadosFGTS.adiantamentos.reduce((a, b) => ({
      valorAntecipacao: a.valorAntecipacao + b.valorAntecipacao,
    }));

    setTotalAntecipacao(somaValorAntecipacao.valorAntecipacao);
  };

  const executaAudioResumo = () => {
    const dadosAudio = {
      totalIOF: totalIOF,
      totalAntecipacao: totalAntecipacao,
      dataAntecipacao: dataAntecipacao,
      cetMes: dadosFGTS.caracteristicaCreditoCet.cetMes,
      valorAReceber: dadosFGTS.valorLiquidoASerCreditado,
    };

    AudioAcessibilidade(2, dadosAudio, tokenSignatario);
  };

  return (
    <div className="resumo-fgts-container">
      {audio}

      <div className="resumo-valor-fgts">
        <label>Valor a receber</label>
        <div>
          <span>{formatarMoeda(dadosFGTS.valorLiquidoASerCreditado)}</span>
        </div>
      </div>

      <div className="resumo-informacoes-adicionais">
        <div>
          <label>Cet mês</label>
          <span>
            {formatarPercentual(dadosFGTS.caracteristicaCreditoCet.cetMes)}
          </span>
        </div>

        <div className="text-align-right">
          <label>Total IOF</label>
          <span>{formatarMoeda(totalIOF)}</span>
        </div>
      </div>

      <div className="tabela-antecipacoes">
        <label>Antecipações</label>
        <table className="table-group" cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th>Data</th>
              <th>Valor</th>
              <th>IOF</th>
            </tr>
          </thead>
          <tbody>
            {dadosFGTS.adiantamentos.map((itemAdiantamento, index) => {
              return (
                <tr key={index} className="antecipacao-small">
                  <td>
                    {formatarStringDataTemplate(
                      itemAdiantamento.dataAntecipacao,
                      'DD/MM/YY',
                    )}
                  </td>
                  <td>{formatarMoeda(itemAdiantamento.valorAntecipacao)}</td>
                  <td>{formatarMoeda(itemAdiantamento.valorIOF)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
