import React from 'react';
import { useApp } from 'context/App';
import './styles.css';

export default function PassosProgresso(props) {
  const { listaSpanProgresso } = useApp();
  const { hide } = props;

  return (
    listaSpanProgresso.length > 0 &&
    !hide && (
      <div className="passos-progresso">
        {listaSpanProgresso.map((span, index) => (
          <span
            key={index}
            className={span.checked ? 'highlight' : null}
          ></span>
        ))}
      </div>
    )
  );
}
