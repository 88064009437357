import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import { useApp } from 'context/App';

import api from 'services/api';

import PageLoader from 'components/PageLoader';

import Pbkdf2 from 'helpers/criptografia';

import './styles.css';

export default function Conclusao() {
  const {
    confirmacaoLgpd,
    confirmacaoDados,
    ip,
    latitude,
    longitude,
    documentosAssinados,
    setLote,
    matchLevel,
    documentoIdentidadeFrente,
    documentoIdentidadeVerso,
    tokenSignatario,
    geolocalizacaoPorIp,
    signatarioAnexos,
    tokenSMS,
    proximaPagina,
    setAssinaturaRealizada,
    assinaturaRealizada,
    setAssinaturaComErro,
    assinaturaComErro,
    isLiveness,
    setUltimaAssinatura,
  } = useApp();
  const history = useHistory();

  const data = {
    anexos: signatarioAnexos,
    documentos: documentosAssinados,
    evidencias: {
      enderecoIp: ip,
      latitude: latitude,
      longitude: longitude,
      precisao: null,
      scoreFacematch: Number(matchLevel) === -1 ? null : Number(matchLevel),
      geolocalizacaoPorIp: geolocalizacaoPorIp,
      documentoIdentificacaoFrente: documentoIdentidadeFrente,
      documentoIdentificacaoVerso: documentoIdentidadeVerso,
      liveness: isLiveness,
      tokenSMS: tokenSMS,
      timestampConfirmacaoDados: confirmacaoDados,
      timestampAceiteTermoUso: confirmacaoLgpd,
    },
  };

  function erroAssinatura() {
    history.push('/aviso', {
      textoH1: 'Erro ao assinar documentos. Tente novamente mais tarde.',
      showImage: true,
    });
  }

  useEffect(() => {
    if (assinaturaRealizada) {
      return history.push(proximaPagina());
    } else if (assinaturaComErro) {
      return erroAssinatura();
    }

    const fecthData = async () => {
      try {
        const dadosAssinatura = data;

        const apiKey = Pbkdf2(JSON.stringify(dadosAssinatura));

        const response = await api.post(
          `Assinatura/Assinar/${tokenSignatario}`,
          dadosAssinatura,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                'autorizacao',
              )}`,
              'bemsign-key': apiKey,
              'Content-Type': 'application/json',
            },
          },
        );

        setAssinaturaRealizada(true);

        setLote(response.data.retorno?.idLote ?? 0);
        setUltimaAssinatura(response.data.retorno?.ultimaAssinatura ?? false);

        window.localStorage.removeItem('matchLevel');
        window.localStorage.removeItem('selfie');
        window.localStorage.removeItem('zoom-q-id');

        history.push(proximaPagina());
      } catch (err) {
        setAssinaturaComErro(true);

        if (err.response && err.response.data && err.response.data.alertas.length) {
          return history.push('/aviso', {
            textoH1: 'Ops',
            showImage: true,
            alertas: [
              {
                mensagem: err.response.data.alertas[0].mensagem
              },
            ],
          });
        }

        return erroAssinatura();
      }
    };
    fecthData();
  }, []);

  return (
    <div className="container assinatura-container">
      <section className="loader-animation">
        <PageLoader isOverlay="true" width={125} height={250} />
      </section>
    </div>
  );
}
