const SELFIE_FACETEC = 1;
const SELFIE_BEMSIGN_NEUTRO = 2;
const SELFIE_BEMSIGN_SORRINDO = 3;
const SELFIE_AUTENTICACAO_NEUTRO = 9;
const SELFIE_CONCLUSAO = 10;
const QUANTIDADE_FOTOS = 15;
const TEMPO_INICIAL_FOTOS = 0;
const TEMPO_TOTAL_FOTOS = 10;
const MENSAGEM_CENTRALIZE_ROSTO = 'CENTRALIZE SEU ROSTO E CLIQUE EM INICIAR';
const DIREITA = 1;
const ESQUERDA = 2;
const CIMA = 3;
const BAIXO = 4;

export {
  SELFIE_FACETEC,
  SELFIE_BEMSIGN_NEUTRO,
  SELFIE_BEMSIGN_SORRINDO,
  SELFIE_AUTENTICACAO_NEUTRO,
  SELFIE_CONCLUSAO,
  QUANTIDADE_FOTOS,
  TEMPO_INICIAL_FOTOS,
  TEMPO_TOTAL_FOTOS,
  MENSAGEM_CENTRALIZE_ROSTO,
  DIREITA,
  ESQUERDA,
  CIMA,
  BAIXO,
};
