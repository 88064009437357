import { valorFinanceiroParaTexto } from 'components/AudioAcessibilidade/formatters';
import { executarAudio } from 'components/AudioAcessibilidade/utils'
import { formatarStringDataTemplate } from 'helpers/Formatters';

export default function AudioTermoFigitalResumo(dados, tokenSignatario) {
    let texto = `
    Resumo da proposta:
    Operação: @OPERACAO.
    Valor financiado: @VALOR_FINANCIADO.
    Valor líquido: @VALOR_LIQUIDO.
    Parcela: @PARCELA.
    Prazo: @PRAZO vezes.
    Cet mês: @CET_MES.
    Primeiro vencimento: @PRIMEIRO_VENCIMENTO.`;

    texto = texto
        .replace('@OPERACAO', dados.operacao)
        .replace('@VALOR_FINANCIADO', valorFinanceiroParaTexto(dados.valorFinanciado))
        .replace('@VALOR_LIQUIDO', valorFinanceiroParaTexto(dados.valorLiquido))
        .replace('@PARCELA', valorFinanceiroParaTexto(dados.parcela))
        .replace('@PRAZO', dados.prazo)
        .replace('@CET_MES', `${dados.cetMes.toString().replace('.', ',')} %`)
        .replace('@PRIMEIRO_VENCIMENTO', formatarStringDataTemplate(dados.primeiroVencimento));

    executarAudio(texto, tokenSignatario);
}
