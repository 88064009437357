import React, { useState, useCallback } from 'react';
import api from 'services/api';
import './styles.css';
import { useApp } from 'context/App';
import { toast } from 'react-toastify';

export default function Feedback({ resetTimer }) {
   const [avaliacao, setAvaliacao] = useState(null);
   const [descricao, setDescricao] = useState('');
   const [idAvaliacao, setIdAvaliacao] = useState(null);
   const [executando, setExecutando] = useState(false);
   const [feedbackConcluido, setFeedbackConcluido] = useState(false);
   const { tokenSignatario } = useApp();

   const enviarFeedbackAvaliacao = useCallback(
      async (event) => {
         const valor = event.target.value;

         setAvaliacao(valor);
         if(resetTimer) resetTimer();

         const dados = { nota: valor };

         if (idAvaliacao) dados.id = idAvaliacao;

         const estrelas = document.querySelectorAll('.estrela');
         
         estrelas.forEach((estrela, index) => {
            if (index < parseInt(valor)) {
               estrela.classList.add('ativa');
            } else {
               estrela.classList.remove('ativa');
            }
         })

         try {
            const response = await api.post(
               `BemSign/AtualizarAvaliacao/${tokenSignatario}`,
               dados,
               {
                  headers: {
                     'Content-Type': 'application/json',
                     Authorization: `Bearer ${window.localStorage.getItem(
                        'autorizacao',
                     )}`,
                  },
               },
            );

            setIdAvaliacao(response.data.retorno);
         } catch (error) {
            toast.error('Não foi possível salvar o feedback');
         }
      },
      [idAvaliacao, resetTimer, tokenSignatario],
   );

   const atualizaDescricao = (value) => {
      if(resetTimer) resetTimer();
      setDescricao(value);
   }


   const enviarFeedbackDescricao = useCallback(
      async (event, descricao) => {
         event.preventDefault();

         if (descricao != '') {
            const dados = { id: idAvaliacao, descricao: descricao };

            setExecutando(true);
            if(resetTimer) resetTimer();

            try {
               await api.post('BemSign/AtualizarAvaliacaoDescricao', dados, {
                  headers: {
                     'Content-Type': 'application/json',
                     Authorization: `Bearer ${window.localStorage.getItem(
                        'autorizacao',
                     )}`,
                  },
               });
               finalizaFeedbackAvaliacao()
            } catch (error) {
               setExecutando(false);
               toast.error('Não foi possível salvar a descrição do feedback');
            }
         } else {
            finalizaFeedbackAvaliacao()
         }
      },
      [idAvaliacao, resetTimer],
   );

   function finalizaFeedbackAvaliacao() {
      toast.success('Obrigado pelo seu feedback!');
      setExecutando(false);
      setFeedbackConcluido(true);
   }

   return (
      !feedbackConcluido && (
         <div className="feedback-container">
            <h2>Como foi a sua experiência com a assinatura?</h2>
            <div
               className="feedback-lista-opcoes"
               onChange={enviarFeedbackAvaliacao}
            >
               <input type="radio" id="1" value="1" name="opcao" />
               <label htmlFor="1" className="estrela"></label>

               <input type="radio" id="2" value="2" name="opcao" />
               <label htmlFor="2" className="estrela"></label>

               <input type="radio" id="3" value="3" name="opcao" />
               <label htmlFor="3" className="estrela"></label>

               <input type="radio" id="4" value="4" name="opcao" />
               <label htmlFor="4" className="estrela"></label>

               <input type="radio" id="5" value="5" name="opcao" />
               <label htmlFor="5" className="estrela"></label>
            </div>
            {idAvaliacao && (
               <div className="feedback-descricao">
                  <form onSubmit={(event) => enviarFeedbackDescricao(event, descricao)}>
                     <textarea
                        placeholder={
                           avaliacao === '5'
                              ? 'O que você mais gostou? (Opcional)'
                              : 'O que podemos fazer para melhorar? (Opcional)'
                        }
                        maxLength={500}
                        onChange={(event) => {atualizaDescricao(event.target.value)}}
                     ></textarea>
                     <button
                        className="button-bem"
                        type="submit"
                        disabled={executando}
                     >
                        Enviar
                     </button>
                  </form>
               </div>
            )}
         </div>
      )
   );
}
