import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getOnlyNumbers from 'helpers/getOnlyNumbers';
import PageLoader from 'components/PageLoader';
import { useApp } from 'context/App';
import api, { apiAuth } from 'services/api';

import './styles.css';

export default function RedefineSenha() {
  const [senha, setSenha] = useState('');
  const [senhaRepetida, setSenhaRepetida] = useState('');
  const [senhaOk, setValidacaoSenha] = useState(false);
  const [senhaRepetidaOk, setValidacaoSenhaRepetida] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { cpf, setUsuarioValido, setSenhaRedefinida, idTokenPortal } = useApp();
  const history = useHistory();

  useEffect(() => {
    if (!cpf.length) {
      history.push('/');
    }
  }, []);

  function changeSenha(valor) {
    setSenha(valor);
    setValidacaoSenha(valor && valor.length > 0);
  }

  function changeSenhaRepetida(valor) {
    setSenhaRepetida(valor);
    setValidacaoSenhaRepetida(valor && valor.length > 0);
  }

  async function redefinirSenha(e) {
    e.preventDefault();

    if (senha === senhaRepetida) {
      try {
        setIsLoading(true);

        const data = {
          usuario: getOnlyNumbers(cpf),
          senha: senha,
          idToken: idTokenPortal,
        };

        const response = await apiAuth.put(`Autenticacao/AtualizarSenhaUsuario`, data, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem('autenticacao-portal')}`,
            'Content-Type': 'application/json',
          },
        });

        setIsLoading(false);

        if (response.data.retorno) {
          setSenhaRedefinida(true);
          setUsuarioValido(true);
          history.push('/');
        } else {
          throw new Error();
        }
      } catch (error) {
        setIsLoading(false);
        toast.error('Ocorreu um erro ao tentar atualizar sua senha');
      }
    } else {
      toast.error('A senha digitada deve ser igual');
    }
  }

  return (
    <div className="container redefinir-senha-container">
      <section
        className={`loader-animation ${isLoading ? 'enabled' : 'hidden'}`}
      >
        <PageLoader isOverlay="true" width={125} height={250} />
      </section>
      <h1>Redefinição de senha</h1>
      <form>
        <fieldset>
          <legend>Definir senha</legend>
          <input
            type="password"
            value={senha}
            onChange={(e) => changeSenha(e.target.value)}
          />
        </fieldset>

        <fieldset>
          <legend>Repetir senha</legend>
          <input
            type="password"
            value={senhaRepetida}
            onChange={(e) => changeSenhaRepetida(e.target.value)}
          />
        </fieldset>

        <button
          className="button-bem"
          onClick={redefinirSenha}
          disabled={!senhaOk || !senhaRepetidaOk}
        >
          Confirmar
        </button>
      </form>
      <ToastContainer />
    </div>
  );
}
