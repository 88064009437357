import React from 'react';

export default function InfoTela(props) {
  return (
    <div className="container informacoes-container">
      <h1>
        {props.carregando
          ? 'Carregando...'
          : 'Não foi possível carregar esta tela'}
      </h1>

      {!props.carregando && (
        <button className="button-bem" onClick={props.funcaoBotao}>
          Continuar
        </button>
      )}
    </div>
  );
}
