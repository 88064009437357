import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import api from 'services/api';
import PageLoader from 'components/PageLoader';

import DocumentoAssinatura from 'components/DocumentoAssinatura';

import {
  TERMO_ADESAO,
  TERMO_ADESAO_FGTS,
  TERMO_AUTORIZACAO,
  TERMO_FIGITAL,
} from './consts';

import { useApp } from 'context/App';

import audioDocumento from 'assets/audios/ler-documento-2.mp3';
import audioProximo from 'assets/audios/vamos-proximo.mp3';
import audioTodoDocumento from 'assets/audios/ler-todo-documento.mp3';

import { useAudio } from 'react-use';
import { VOLUME } from 'services/consts';
import BotaoFixado from 'components/BotaoFixado';
import './styles.css';

export default function Documento() {
  const {
    listaDocumentos,
    setListaDocumentos,
    setDocumentosAssinados,
    tokenSignatario,
    proximaPagina,
    autorizoDebitoValorParcelasTotalOuParcialmente,
    autorizoDebitosLimiteCreditoConta,
    autorizoDebitosObrigacaoVencidaInclusive,
    ajudaPorVoz,
    alteraProgresso,
    configuracaoAssinatura,
    habilitaAudio,
    configGlobal
  } = useApp();

  const [isLoading, setIsLoading] = useState(false);
  const [documentoSelecionado, setDocumentoSelecionado] = useState(null);
  const [documentoAceito, setDocumentoAceito] = useState(false);
  const [bloqueioAvancar, setBloqueioAvancar] = useState(false);
  const [audioDoc, setAudioDoc] = useState(null);
  const [bloqueioScroll, setBloqueioScroll] = useState(
    configuracaoAssinatura.validaVisualizacaoDocumento,
  );
  const [pdfRenderizado, setPdfRenderizado] = useState(false);
  const [dimensaoBottom, setDimensaoBottom] = useState(null);
  const history = useHistory();
  const [audio, state, controls, ref] = useAudio({
    src: audioDoc,
    autoPlay: false,
  });

  async function gravarAceiteDocumento(idDocumento) {
    await api.get(`Documento/GravarAceite/${tokenSignatario}/${idDocumento}`, {
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('autorizacao')}`,
      },
    });
  }

  async function gravarAuditoriasTermoAutorizacao(idDocumento) {
    const data = {
      autorizoDebitoValorParcelasTotalOuParcialmente,
      autorizoDebitosLimiteCreditoConta,
      autorizoDebitosObrigacaoVencidaInclusive,
    };

    await api.post(
      `Template/gravarAuditoriasTermoAutorizacao/${tokenSignatario}/${idDocumento}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('autorizacao')}`,
        },
      },
    );
  }

  async function proximoDocumento() {
    setBloqueioAvancar(true);
    setPdfRenderizado(false);

    setDocumentosAssinados((listaDocumentosAssinados) => [
      ...listaDocumentosAssinados,
      documentoSelecionado.id,
    ]);

    let doc;
    let index = listaDocumentos.indexOf(documentoSelecionado);
    let nextIndex = index + 1;

    await gravarAceiteDocumento(documentoSelecionado.id);

    if (
      (documentoSelecionado.idTemplate === TERMO_ADESAO ||
        documentoSelecionado.idTemplate === TERMO_ADESAO_FGTS) &&
      (autorizoDebitoValorParcelasTotalOuParcialmente ||
        autorizoDebitosLimiteCreditoConta ||
        autorizoDebitosObrigacaoVencidaInclusive)
    ) {
      await gravarAuditoriasTermoAutorizacao(documentoSelecionado.id);
    }

    setBloqueioAvancar(false);

    alteraProgresso();

    if (listaDocumentos[nextIndex]) {
      doc = listaDocumentos[nextIndex];

      let autorizacaoDescricao = habilitaAudio ? doc.idTemplate !== TERMO_AUTORIZACAO : true;

      if (
        doc.idTemplate !== TERMO_ADESAO &&
        doc.idTemplate !== TERMO_ADESAO_FGTS &&
        doc.idTemplate !== TERMO_FIGITAL &&
        autorizacaoDescricao
      ) {
        setAudioDoc(audioProximo);
      }
    } else {
      return history.push(proximaPagina());
    }

    setDocumentoSelecionado(doc);
  }

  const avisaScrollFinal = (event) => {
    let element = event.target.scrollingElement;
    let calculoScroll = element.scrollHeight - element.scrollTop;
    let alturaTotal = element.clientHeight * 0.1 + element.clientHeight;

    if (calculoScroll <= alturaTotal) {
      setBloqueioScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', avisaScrollFinal);

    const fecthData = async () => {
      try {
        setIsLoading(true);
        const response = await api.get(
          `Documento/ListarDocumentos/${tokenSignatario}`,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                'autorizacao',
              )}`,
            },
          },
        );

        const listaRetorno = Array.from(new Set(response.data.retorno));

        verificaAudio(listaRetorno);

        setListaDocumentos(listaRetorno);
        setDocumentoSelecionado(listaRetorno[0]);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
      }
    };

    fecthData();

    return () => {
      window.removeEventListener('scroll', avisaScrollFinal);
    };
  }, []);

  function onChangeCheckbox(e) {
    const target = e.target;
    const value = target.checked;

    if (bloqueioScroll && value)
      setAudioDoc(audioTodoDocumento);

    setDocumentoAceito(value);
  }

  function avancar(e) {
    e.preventDefault();

    proximoDocumento();

    setDocumentoAceito(false);
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    if (ajudaPorVoz) {
      controls.volume(VOLUME);
      controls.play();
    }
  }, [audioDoc]);

  const verificaAudio = async (listaRetorno) => {
    let lista = listaRetorno.filter(
      (documento) => documento.idTemplate === TERMO_FIGITAL,
    );

    if (lista.length === 0) {
      setAudioDoc(audioDocumento);
    }
  };

  return (
    <div className="container documento-container">
      {audio}
      <section
        className={`loader-animation ${isLoading ? 'enabled' : 'hidden'}`}
      >
        <PageLoader isOverlay="true" width={125} height={250} />
      </section>

      {documentoSelecionado && (
        <div className="content">
          <DocumentoAssinatura
            idTemplate={documentoSelecionado.idTemplate}
            idDocumento={documentoSelecionado.id}
            setIsLoading={setIsLoading}
            idExterno={documentoSelecionado.idExterno}
            setBloqueioScroll={setBloqueioScroll}
            setPdfRenderizado={setPdfRenderizado}
            dimensaoBottom={dimensaoBottom}
          />

          {!isLoading && (
            <section className="form">
              <BotaoFixado
                className="button-bem button-doc"
                onClick={avancar}
                disabled={!documentoAceito || bloqueioAvancar || bloqueioScroll}
                CheckboxDoc={
                  <div className="checkbox-group">
                    <input
                      type="checkbox"
                      id="concordo"
                      className="checkbox-concordo"
                      checked={documentoAceito}
                      onChange={onChangeCheckbox}
                    />
                    <label htmlFor="concordo" className="checkbox-label">
                      Concordo com a veracidade das informações do documento
                    </label>
                  </div>
                }
                borderTop
                corBotao={configGlobal.confirmacao_cor_botao_primario}
                corFonte={configGlobal.confirmacao_cor_fonte_botao_primario}
                removeFixed={pdfRenderizado}
                dimensao={(dimensao) => {
                  setDimensaoBottom(dimensao);
                }}
              >
                {bloqueioScroll ? 'Leia todo documento' : 'Avançar'}
              </BotaoFixado>
            </section>
          )}
        </div>
      )}
    </div>
  );
}
