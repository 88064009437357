import React from 'react';
import './styles.css';
import { QRCodeSVG } from 'qrcode.react';

export default function QrCode(props) {
   const { link } = props;
   return (
      <div className="qrcode-container">
         <h2>{'Aponte a câmera do seu celular para o QRCode'}</h2>
         <QRCodeSVG className="qrCode" value={link} bgColor='transparent' />
      </div>
   );
}
