import React, { useEffect, useState } from 'react';
import { useApp } from 'context/App';
import './styles.css';

export default function TabelaRetratil(props) {
  const { configuracaoAssinatura } = useApp();
  const [aberto, setAberto] = useState(false);
  const { thTitulo, children } = props;

  useEffect(() => {
    setAberto(!configuracaoAssinatura.informacoesRetratil);
  }, []);

  const toggleClass = () => {
    setAberto(!aberto);
  };

  const SetaTabela = () => (
    <span
      className={`arrow ${aberto ? 'arrow-top' : 'arrow-bottom'}`}
      onClick={toggleClass}
    ></span>
  );

  return (
    children && (
      <table
        className={`table-group ${!aberto ? 'retratil' : null}`}
        cellSpacing="0"
        cellPadding="0"
      >
        <thead>
          <tr onClick={toggleClass}>
            <th colSpan="4">
              <div className="dados-th">
                <span className="titulo">{thTitulo}</span>
                <SetaTabela />
              </div>
            </th>
          </tr>
        </thead>
        {aberto && children}
      </table>
    )
  );
}
