const addZero = value => value.toString().padStart(2, '0');

const dateTimeMiliseconds = () => {
    // window.performance && window.performance.now && window.performance.timing && window.performance.timing.navigationStart ? window.performance.now() + window.performance.timing.navigationStart : Date.now();
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = addZero(currentDate.getMonth()+1);
    const day = addZero(currentDate.getDate());
    const hours = addZero(currentDate.getHours());
    const minutes = addZero(currentDate.getMinutes());
    const seconds = addZero(currentDate.getSeconds());
    const miliseconds = currentDate.getMilliseconds();
    const dateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${miliseconds}`;
    
    return dateTime;
}

export default dateTimeMiliseconds;