import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ChromeInstrucao from './components/ChromeInstrucao';
import SafariInstrucao from './components/SafariInstrucao';
import getBrowser from 'helpers/browser';

import './styles.css';

export default function CameraInstrucao() {
  const [navegador, setNavegador] = useState(null);
  const history = useHistory();

  useEffect(() => {
    setNavegador(
      getBrowser() === 'Chrome' ? <ChromeInstrucao /> : <SafariInstrucao />,
    );
  }, []);

  function recomecar() {
    history.push(window.localStorage.getItem('url-token'));
  }

  return (
    <div className="container camera-instrucao-container">
      {navegador ? navegador : <h1>Aguarde...</h1>}
      <button className="button-bem" onClick={recomecar}>
        Recomeçar
      </button>
    </div>
  );
}
