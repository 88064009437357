import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useApp } from 'context/App';
import { ReactComponent as PermissaoCamera } from 'assets/permissao-camera.svg';
import { ReactComponent as PermissaoLocalizacao } from 'assets/permissao-localizacao.svg';
import FaceId from 'assets/face-id.gif';

import audioLocalizacao from 'assets/audios/localizacao-3.mp3';
import audioCameraLocalizacao from 'assets/audios/camera-localizacao-5.mp3';

import { useAudio } from 'react-use';
import { VOLUME } from 'services/consts';
import BotaoFixado from 'components/BotaoFixado';
import './styles.css';

export default function SolicitacaoAcesso() {
  const { proximaPagina, livenessConfig, coletaDocumentoConfig, ajudaPorVoz, configGlobal } =
    useApp();
  const history = useHistory();
  const [audio, state, controls, ref] = useAudio({
    src:
      livenessConfig || coletaDocumentoConfig
        ? audioCameraLocalizacao
        : audioLocalizacao,
    autoPlay: false,
  });

  useEffect(() => {
    if (ajudaPorVoz) {
      controls.volume(VOLUME);
      controls.play();
    }
  }, []);

  function navegarProximaPagina() {
    history.push(proximaPagina());
  }

  const TextoDestaque = ({ children }) => {
    return <b style={{ color: configGlobal.acesso_cor_fonte }}>{children}</b>
  }

  function Instrucao() {
    return livenessConfig || coletaDocumentoConfig ? (
      <p>
        Para continuar, é necessário que você permita o acesso a sua{' '}
        <TextoDestaque>CÂMERA</TextoDestaque> e a sua <TextoDestaque>LOCALIZAÇÃO</TextoDestaque>.
      </p>
    ) : (
      <p>
        Para continuar, é necessário que você permita o acesso a sua{' '}
        <TextoDestaque>LOCALIZAÇÃO</TextoDestaque>.
      </p>
    );
  }

  return (
    <div className="container solicitacao-acesso-container" style={{ background: configGlobal.acesso_cor_fundo }}>
      {audio}
      <div className="container-gifs">
        {(livenessConfig || coletaDocumentoConfig) && (
          <PermissaoCamera fill={configGlobal.acesso_cor_icone} width={80} />
        )}
        <PermissaoLocalizacao fill={configGlobal.acesso_cor_icone} width={80} />
      </div>

      <Instrucao />

      <BotaoFixado onClick={navegarProximaPagina} borderTop cor="transparent" corBotao={configGlobal.acesso_cor_botao_primario} corFonte={configGlobal.acesso_cor_fonte_botao_primario}>
        Entendi
      </BotaoFixado>
    </div>
  );
}
