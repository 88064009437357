import { FacetecUtilities } from '../../utilities/FacetecUtilities2';
import { ZoomGlobalState } from '../../../../../../public/core-sdk/ZoomGlobalState';
import { FaceTecSDK } from '../../../../../../public/core-sdk/FaceTecSDK.js/FaceTecSDK';
import LivenessCheckProcessor from 'components/Liveness/facetec/processors/LivenessCheckProcessor2'
import { ThemeHelpers } from './ThemeHelper2';
import PhotoIDMatchProcessor from '../PhotoIDMatchProcessor2';

const PATH_INSCRICAO = '/enrollment-3d';

export const BemFacetec = (function () {
    var latestEnrollmentIdentifier = "";
    var latestSuccessfulServerResult = null;
    var latestSessionResult = null;
    var latestIDScanResult = null;
    var latestProcessor;
    var onProcessCompleteLiveness;
    var onProcessCompleteFacematch;
    var onPhotoIDMatchProcessingFail;
    var token;
    var quantidadeTentativasInscricao;
    var falhaSessao;

    function onLivenessCheckPressed(onProcessComplete, tokenSignatario) {
        onProcessCompleteLiveness = onProcessComplete;
        token = tokenSignatario;
        FacetecUtilities.fadeOutMainUIAndPrepareForSession();

        getSessionToken(function (sessionToken) {
            latestProcessor = new LivenessCheckProcessor(sessionToken, BemFacetec, token);
        });
    }

    function onPhotoIDMatchPressed(onProcessComplete, onProcessFail, tokenSignatario, callbackFalhaSessao) {
        onProcessCompleteFacematch = onProcessComplete;
        onPhotoIDMatchProcessingFail = onProcessFail;
        token = tokenSignatario;
        quantidadeTentativasInscricao = 0;
        falhaSessao = callbackFalhaSessao;
        FacetecUtilities.fadeOutMainUIAndPrepareForSession();

        getSessionToken(function (sessionToken) {
            latestEnrollmentIdentifier = "bem_facetec_app" + FacetecUtilities.generateUUId();
            latestProcessor = new PhotoIDMatchProcessor(sessionToken, BemFacetec, token);
        });
    }

    function failProcessor() {
        if (latestProcessor instanceof LivenessCheckProcessor) {
            onLivenessCheckPressed(onProcessCompleteLiveness, token);
        } else if (latestProcessor instanceof PhotoIDMatchProcessor) {
            onPhotoIDMatchPressed(onProcessCompleteFacematch, onPhotoIDMatchProcessingFail, token, falhaSessao);
        }
    }

    function successProcessor(sessionResult, scanResult, success) {
        if (latestProcessor instanceof LivenessCheckProcessor) {
            onProcessCompleteLiveness(success, latestSessionResult);
        } else if (latestProcessor instanceof PhotoIDMatchProcessor) {
            onProcessCompleteFacematch(latestProcessor.isSuccess, latestSessionResult, latestIDScanResult);
        }
    }

    function onComplete(latestSessionResultComplete, latestIDScanResultComplete, status, success) {
        latestSessionResult = latestSessionResultComplete;
        latestIDScanResult = latestIDScanResultComplete;

        if (latestSessionResult && latestSessionResult.status !== FaceTecSDK.FaceTecSessionStatus.SessionCompletedSuccessfully && latestSessionResult.status !== FaceTecSDK.FaceTecSessionStatus.ContextSwitch) {
            return;
        }

        if (!latestProcessor.isSuccess()) {
            failProcessor();
        } else {
            successProcessor(latestSessionResult, latestIDScanResult, success);
        }
    }

    function onViewSessionResultPressed() {
        // SessionReviewScreen.show();
    }

    function onDesignShowcasePressed() {
        ThemeHelpers.showNewTheme();
    }

    function getSessionToken(sessionTokenCallback) {
        var XHR = new XMLHttpRequest();
        XHR.open("GET", ZoomGlobalState.ZoomServerBaseURL + "/ObterTokenSessao");
        XHR.setRequestHeader('Authorization', `Bearer ${window.localStorage.getItem('autorizacao')}`);
        XHR.setRequestHeader("X-Device-Key", ZoomGlobalState.DeviceLicenseKeyIdentifier);
        XHR.setRequestHeader("X-User-Agent", FaceTecSDK.createFaceTecAPIUserAgentString(""));
        XHR.onreadystatechange = function () {
            if (this.readyState === XMLHttpRequest.DONE) {
                var sessionToken = "";
                try {
                    sessionToken = JSON.parse(this.responseText).retorno.sessionToken;
                    if (typeof sessionToken !== "string") {
                        onServerSessionTokenError();
                        return;
                    }
                }
                catch (_a) {
                    onServerSessionTokenError();
                    return;
                }
                FacetecUtilities.hideLoadingSessionToken();
                sessionTokenCallback(sessionToken);
            }
        };
        setTimeout(function () {
            if (XHR.readyState !== XMLHttpRequest.DONE) {
                FacetecUtilities.showLoadingSessionToken();
            }
            ;
        }, 3000);
        XHR.onerror = function () {
            onServerSessionTokenError();
        };
        XHR.send();
    }
    function onServerSessionTokenError() {
        FacetecUtilities.handleErrorGettingServerSessionToken();
    }

    function setLatestSessionResult(sessionResult) {
        latestSessionResult = sessionResult;

        if (sessionResult && sessionResult.status !== FaceTecSDK.FaceTecSessionStatus.SessionCompletedSuccessfully && sessionResult.status !== FaceTecSDK.FaceTecSessionStatus.ContextSwitch) {
            falhaSessao();
        }
    }
    function setLatestIDScanResult(idScanResult) {
        latestIDScanResult = idScanResult;
    }
    function setLatestServerResult(responseJSON) {
        latestSuccessfulServerResult = responseJSON;

        if (latestSuccessfulServerResult.callData && latestSuccessfulServerResult.callData.path === PATH_INSCRICAO) {
            quantidadeTentativasInscricao = quantidadeTentativasInscricao + 1;

            if (!latestSuccessfulServerResult.success && quantidadeTentativasInscricao === parseInt(window.localStorage.getItem('tentativasLiveness'))) {
                onPhotoIDMatchProcessingFail(latestSessionResult);
            }
        }
    }
    function getLatestEnrollmentIdentifier() {
        return latestEnrollmentIdentifier;
    }
    function clearLatestEnrollmentIdentifier() {
        latestEnrollmentIdentifier = "";
    }
    return {
        onLivenessCheckPressed: onLivenessCheckPressed,
        onPhotoIDMatchPressed: onPhotoIDMatchPressed,
        getSessionToken: getSessionToken,
        onViewSessionResultPressed: onViewSessionResultPressed,
        onDesignShowcasePressed: onDesignShowcasePressed,
        onComplete: onComplete,
        setLatestSessionResult: setLatestSessionResult,
        setLatestIDScanResult: setLatestIDScanResult,
        setLatestServerResult: setLatestServerResult,
        getLatestEnrollmentIdentifier: getLatestEnrollmentIdentifier,
        clearLatestEnrollmentIdentifier: clearLatestEnrollmentIdentifier
    };
})();
