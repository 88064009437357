import { useHistory } from 'react-router-dom';

const useAuthScore = () => {
    const history = useHistory();

    const deslogar = () => {
        localStorage.removeItem('autorizacao-score');
        history.push('/login');
    }

    return { deslogar };
}

export default useAuthScore;