import chrome from 'assets/navegadores/chrome.png';
import firefox from 'assets/navegadores/firefox.png';
import opera from 'assets/navegadores/opera.png';
import safari from 'assets/navegadores/safari.png';

class Browser {
  device = '';
  browsers = [];

  constructor(device, browsers) {
    this.browsers = browsers;
    this.device = device;
  }
}

const browserList = () => {
  let list = [];

  const androidBrowser = new Browser('Android 4.4+', [
    { image: chrome, name: 'Chrome v56+' },
    { image: firefox, name: 'Firefox v50+' },
    { image: opera, name: 'Opera v47+' },
  ]);

  const iosBrowser = new Browser('IOS 8.3+', [
    { image: safari, name: 'Safari v11' },
  ]);

  const desktopBrowser = new Browser('Desktop', [
    { image: chrome, name: 'Chrome v56+' },
    { image: firefox, name: 'Firefox v50+' },
    { image: safari, name: 'Safari v11' },
    { image: opera, name: 'Opera v47+' },
  ]);

  list.push(androidBrowser, iosBrowser, desktopBrowser);

  return list;
};

export default browserList;
