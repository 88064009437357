import api from 'services/api';
import useDownload from './useDownload';
import { useState } from 'react';

const useDownloadRelatorio = () => {
    const { download } = useDownload();
    const [loading, setLoading] = useState(false);

    const baixarLista = async (filtro) => {
        setLoading(true);

        try {
            const response = await api
                .post('ScoreBiometrico/GerarRelatorioGeralCadastroBiometrico', filtro, {
                    headers: {
                        Authorization: `Bearer ${window.localStorage.getItem('autorizacao-score')}`,
                    },
                    responseType: 'blob'
                });

            download(response.data, `relatorio-biometria`);

            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    return { baixarLista, loading };
}

export default useDownloadRelatorio;