import React, { useState, useEffect } from 'react';

import api from 'services/api';

import { useApp } from 'context/App';

import datePtBr from 'helpers/datePtBr';

import {
  formatarCPF,
  formatarMoeda,
  formatarPercentual,
  formatarStringData,
} from 'helpers/Formatters';
import { telaVisualizada } from 'helpers/telaVisualizada';

import './styles.css';

export default function SolicitacaoPortabilidade(props) {
  const [dadosPortabilidade, setDadosPortabilidade] = useState(null);
  const { tokenSignatario, configuracaoAssinatura } = useApp();

  useEffect(() => {
    const fecthData = async () => {
      try {
        props.setIsLoading(true);
        const urlIdExterno = props.idExterno ? `/${props.idExterno}` : '';

        const url = `Template/ObterDadosSolicitacaoPortabilidade/${tokenSignatario}${urlIdExterno}`;

        const response = await api.get(url, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              'autorizacao'
            )}`,
          },
        });
        props.setIsLoading(false);
        props.setDadosValidos(true);
        setDadosPortabilidade(response.data.retorno);
        if (configuracaoAssinatura.validaVisualizacaoDocumento) {
          props.setBloqueioScroll(!telaVisualizada());
        }
      } catch (err) {
        props.setDadosValidos(false);
        props.setIsLoading(false);
      }
    };
    fecthData();
  }, [props.idDocumento, props.idExterno]);

  return (
    dadosPortabilidade && (
      <div className="container-solicitacao-portabilidade">
        <h2>Solicitação de Portabilidade</h2>
        <table className="table-group" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th colSpan="2">À instituição proponente</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Razão Social</td>
              <td>
                <b>Banrisul S.A.</b> CNPJ Base: 92702067
              </td>
            </tr>
            <tr>
              <td>Endereço</td>
              <td>Rua Caldas Júnior, 108 – Porto Alegre/RS CEP: 90.018-900</td>
            </tr>
          </tbody>
        </table>
        <p>
          Por este instrumento, o <b>CLIENTE (SOLICITANTE)</b>, devidamente
          qualificado abaixo, solicita formalmente e em conformidade com as
          regras da Resolução 5.057/2022 do Banco Central do Brasil (Bacen), a
          portabilidade da operação de crédito consignado, conforme segue:
        </p>
        <table className="table-group" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th colSpan="2">Identificação do cliente (solicitante)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Nome</td>
              <td>{dadosPortabilidade.nomeCliente}</td>
            </tr>
            <tr>
              <td>CPF</td>
              <td>{formatarCPF(dadosPortabilidade.cpf)}</td>
            </tr>
            <tr>
              <td>Telefone</td>
              <td>{dadosPortabilidade.telefone}</td>
            </tr>
            <tr>
              <td>Documento de identificação</td>
              <td>{dadosPortabilidade.documentoIdentificacao}</td>
            </tr>
            <tr>
              <td>Tipo</td>
              <td>{dadosPortabilidade.tipoDocumentoIdentificacao}</td>
            </tr>
            <tr>
              <td>Data de emissão</td>
              <td>
                {formatarStringData(
                  dadosPortabilidade.dataEmissaoDocumentoIdentificacao
                )}
              </td>
            </tr>
            <tr>
              <td>Órgão emissor</td>
              <td>{dadosPortabilidade.orgaoEmissorDocumentoIdentificacao}</td>
            </tr>
          </tbody>
        </table>
        <table className="table-group" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th colSpan="2">Dados da operação original</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Número do contrato</td>
              <td>{dadosPortabilidade.numeroContrato}</td>
            </tr>
            <tr>
              <td>Instituição credora original</td>
              <td>{dadosPortabilidade.instituicaoCredoraOriginal}</td>
            </tr>
          </tbody>
        </table>
        <table className="table-group" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th colSpan="2">Dados da proposta</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Data referência saldo devedor</td>
              <td>
                {formatarStringData(
                  dadosPortabilidade.dataReferenciaSaldoDevedor
                )}
              </td>
            </tr>
            <tr>
              <td>Valor saldo devedor</td>
              <td>
                {formatarMoeda(
                  parseFloat(dadosPortabilidade.valorSaldoDevedor)
                )}
              </td>
            </tr>
            <tr>
              <td>Taxa juros nominal % a.a.</td>
              <td>
                {formatarPercentual(dadosPortabilidade.taxaJurosNominalAoAno)}
              </td>
            </tr>
            <tr>
              <td>CET % a.a.</td>
              <td>{formatarPercentual(dadosPortabilidade.cetAoAno)}</td>
            </tr>
            <tr>
              <td>Taxa juros efetiva % a.a.</td>
              <td>{formatarPercentual(dadosPortabilidade.taxaJurosNominalAoAno)}</td>
            </tr>
            <tr>
              <td>Valor da prestação</td>
              <td>{formatarMoeda(dadosPortabilidade.valorPrestacao)}</td>
            </tr>
            <tr>
              <td>Prazo</td>
              <td>{dadosPortabilidade.prazo}</td>
            </tr>
          </tbody>
        </table>
        <table className="table-group" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th colSpan="2">Dados do corresponde</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Razão social</td>
              <td>{dadosPortabilidade.razaoSocialCorrespondente}</td>
            </tr>
            <tr>
              <td>CNPJ</td>
              <td>{dadosPortabilidade.cnpjCorrespondente}</td>
            </tr>
          </tbody>
        </table>
        <div className="div-condicoes">
          <p>
            Para sugestões, reclamações e solução de conflitos, o CLIENTE deve
            entrar em contato pelos seguintes canais: Central de Relacionamento
            ao Cliente - Capitais e regiões metropolitanas - 3003.0511 / Demais
            localidades - 0800.7200011; Serviço de Atendimento ao Cliente -
            0800.6461515; Ouvidoria - 0800.6442200, em dias úteis, das 9h às
            17h.
          </p>
        </div>
      </div>
    )
  );
}
