import React, { useState, useEffect } from 'react';

import api from 'services/api';

import { useApp } from 'context/App';

import {
  formatarCPF,
  formatarCep,
  formatarIniciarMaiusculo,
} from 'helpers/Formatters';
import { telaVisualizada } from 'helpers/telaVisualizada';
import './styles.css';

export default function ComprovanteResidencia(props) {
  const { tokenSignatario, configuracaoAssinatura } = useApp();
  const [dadosDeclaracao, setDadosDeclaracao] = useState(null);

  useEffect(() => {
    const fecthData = async () => {
      try {
        props.setIsLoading(true);
        const response = await api.get(
          `Template/ObterDadosDeclaracaoResidencia/${tokenSignatario}`,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                'autorizacao',
              )}`,
            },
          },
        );

        props.setIsLoading(false);
        props.setDadosValidos(true);

        setDadosDeclaracao(response.data.retorno);
        if (configuracaoAssinatura.validaVisualizacaoDocumento) {
          props.setBloqueioScroll(!telaVisualizada());
        }
      } catch (err) {
        props.setDadosValidos(false);
        props.setIsLoading(false);
      }
    };

    fecthData();
  }, []);

  return (
    dadosDeclaracao && (
      <div className="container-comprovante-residencia">
        <h2>Confirme seu endereço</h2>
        <table className="table-group" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th colSpan="2">Dados de endereço</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Endereço</td>
              <td>
                {formatarIniciarMaiusculo(dadosDeclaracao.endereco)}, nº{' '}
                {dadosDeclaracao.numero}
              </td>
            </tr>
            <tr>
              <td>Complemento</td>
              <td>{formatarIniciarMaiusculo(dadosDeclaracao.complemento)}</td>
            </tr>
            <tr>
              <td>CEP</td>
              <td>{formatarCep(dadosDeclaracao.cep)}</td>
            </tr>
            <tr>
              <td>Bairro</td>
              <td>{formatarIniciarMaiusculo(dadosDeclaracao.bairro)}</td>
            </tr>
            <tr>
              <td>Cidade</td>
              <td>{formatarIniciarMaiusculo(dadosDeclaracao.cidade)}</td>
            </tr>
            <tr>
              <td>UF</td>
              <td>{dadosDeclaracao.uf}</td>
            </tr>
          </tbody>
        </table>
        <p>
          Eu, <b>{formatarIniciarMaiusculo(dadosDeclaracao.nome)}</b>, portador
          do CPF <b>{formatarCPF(dadosDeclaracao.cpf)}</b>, declaro, sob as
          penas da lei Civil e Penal, que tenho residência e domicílio situados
          no endereço acima.
        </p>
      </div>
    )
  );
}
