import React from 'react';
import { Oval } from 'react-loader-spinner';

export default function LoaderPadrao() {
    return <Oval
        height={80}
        width={80}
        color="#4264CE"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
        ariaLabel='oval-loading'
        secondaryColor="#4264CE"
        strokeWidth={2}
        strokeWidthSecondary={2}
    />
}