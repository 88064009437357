import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { formatarStringDataHora } from 'helpers/Formatters';
import api from 'services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageLoader from 'components/PageLoader';
import { useApp } from 'context/App';
import getOnlyNumbers from 'helpers/getOnlyNumbers';
import { isAuthenticated } from 'auth';

import './styles.css';

export default function Detalhes() {
  const [listaDetalhes, setListaDetalhes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { cpf, setSessaoExpiradaPortal, setUsuarioValido } = useApp();
  const history = useHistory();

  async function expiraSessao() {
    return !isAuthenticated(true);
  }

  useEffect(() => {
    if (!cpf.length) {
      return history.push('/');
    }

    const fecthData = async () => {
      try {
        setIsLoading(true);

        const sessaoExpirada = await expiraSessao();

        if (sessaoExpirada) {
          setSessaoExpiradaPortal(true);
          setUsuarioValido(false);
          return history.push('/');
        }

        const response = await api.get(
          `Portal/ListarLotesUsuario/${getOnlyNumbers(cpf)}`,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                'autenticacao-portal',
              )}`,
            },
          },
        );

        setIsLoading(false);

        setListaDetalhes(response.data.retorno);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
        toast.error('Erro ao listar lotes');
      }
    };

    fecthData();
  }, []);

  async function downloadDocumento(tokenSignatario) {
    try {
      setIsLoading(true);

      const sessaoExpirada = await expiraSessao();

      if (sessaoExpirada) {
        setSessaoExpiradaPortal(true);
        setUsuarioValido(false);
        return history.push('/');
      }

      const response = await api.get(`Documento/Download/${tokenSignatario}`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            'autenticacao-portal',
          )}`,
          'Content-Type': 'application/octet-stream',
        },
        responseType: 'arraybuffer',
      });

      setIsLoading(false);

      const fileName = `documentos-assinados-${tokenSignatario}`;

      const blob = new Blob([response.data], { type: 'application/zip' });

      const downloadUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
    } catch (err) {
      setIsLoading(false);
      toast.error('Erro ao baixar arquivos');
    }
  }

  function componenteRegra(item) {
    let componente = null;

    const assinar = (
      <a
        href={item.linkPortalAssinatura}
        target="_blank"
        className="button-bem"
      >
        Assinar
      </a>
    );

    const download = (
      <button
        className="button-bem download"
        onClick={() => downloadDocumento(item.tokenAcessoSignatario)}
      >
        Download
      </button>
    );

    const vencida = <span className="prazo-expirado">Prazo expirado</span>;
    const inativada = <span className="prazo-expirado">Link inativo</span>;

    if (item.loteAssinado) {
      componente = download;
    } else if (!item.loteAssinado && moment(item.dataValidadeLote) < moment()) {
      componente = vencida;
    } else if (!item.loteAssinado && !item.loteAtivo) {
      componente = inativada;
    } else if (!item.loteAssinado) {
      componente = assinar;
    }

    return componente;
  }

  return (
    <div className="container detalhes-container">
      <section
        className={`loader-animation ${isLoading ? 'enabled' : 'hidden'}`}
      >
        <PageLoader isOverlay="true" width={125} height={250} />
      </section>
      {!isLoading && <h1>Verifique abaixo seus documentos</h1>}
      <div className="lista-detalhes">
        {listaDetalhes.map((item) => (
          <div className="card-detalhe" key={item.idLote}>
            <section className="card-info">
              <h2>{item.nomeLote ? item.nomeLote : 'Lote sem nome'}</h2>
              <span>{item.nomeEmpresa}</span>
              <span>{formatarStringDataHora(item.dataCadastro)}</span>
            </section>
            <section className="card-acao">{componenteRegra(item)}</section>
          </div>
        ))}
      </div>
      <ToastContainer />
    </div>
  );
}
