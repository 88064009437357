import { useApp } from 'context/App';
import React from 'react';
import LoaderPadrao from './LoaderPadrao';
import LoaderPersonalizado from './LoaderPersonalizado';
import './styles.css';
import { FUNDACAO } from 'helpers/consts';

const PageLoader = (props) => {
  const { idEmpresa } = useApp();

  const className = props.isOverlay ? 'overlay-container' : 'loading-container';

  return <div className={className}>
    {idEmpresa && idEmpresa !== FUNDACAO ? <LoaderPersonalizado idEmpresa={idEmpresa} /> : <LoaderPadrao />}
  </div>

};

export default PageLoader;
