import publicIp from 'public-ip';
import ipLocation from 'iplocation';

const virtualLocation = async () => {
  const ip = await publicIp.v4();
  const location = await ipLocation(ip);

  return {
    ip,
    latitude: location.latitude,
    longitude: location.longitude,
    city: location.city,
  };
};

export default virtualLocation;
