import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useApp } from 'context/App';
import InformacoesPropostaAlterada from './InformacoesPropostaAlterada';
import InformacoesSubrogacao from './InformacoesSubrogacao'
import { PROPOSTA_ALTERADA, SUBROGACAO } from './consts'
import InformacoesPadrao from './InformacoesPadrao';
import InfoTela from './components/InfoTela';
import './styles.css';

export default function Informacoes() {
  const { proximaPagina, informacoes, setInformacoes, listaPages } = useApp();
  const [informacao, setInformacao] = useState(null);
  const [carregando, setCarregando] = useState(true);
  const history = useHistory();

  function navegarProximaPagina() {
    history.push(proximaPagina());
  }

  useEffect(() => {
    setInformacao(informacoes.filter(x => !x.avisoValidado)[0]);
    setCarregando(false);
  }, [])

  const marcarInformacaoValidada = (id) => {
    var informacoesAlteradas = informacoes.map(x => x.id === id ? ({ ...x, avisoValidado: true }) : x);
    var informacaoAtual = informacoesAlteradas.filter(x => !x.avisoValidado)[0];
    if (!informacaoAtual) return navegarProximaPagina();
    setInformacoes(informacoesAlteradas)
    setInformacao(informacaoAtual)
  };

  return informacao ?
    (
      informacao.idAvisoTipo === SUBROGACAO ?
        <InformacoesSubrogacao informacoes={informacoes} marcarInformacaoValidada={navegarProximaPagina} /> :
        informacao.idAvisoTipo === PROPOSTA_ALTERADA ?
          <InformacoesPropostaAlterada informacoes={informacao} marcarInformacaoValidada={marcarInformacaoValidada} /> :
          <InformacoesPadrao informacoes={informacao} marcarInformacaoValidada={marcarInformacaoValidada} />
    ) :
    <InfoTela funcaoBotao={navegarProximaPagina} carregando={carregando} />
}
