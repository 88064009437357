import { TIPO_TERMO_ADESAO, TIPO_TERMO_ADESAO_FGTS, TIPO_TERMO_FIGITAL } from './consts';
import AudioTermoAdesaoResumo from 'components/AudioAcessibilidade/Audios/TermoAdesao/resumo';
import AudioTermoAdesaoFGTSResumo from 'components/AudioAcessibilidade/Audios/TermoAdesaoFGTS/resumo';
import AudioTermoFigitalResumo from 'components/AudioAcessibilidade/Audios/TermoFigital/resumo';

const AudioAcessibilidade = function (tipo, dados, tokenSignatario) {
  switch (tipo) {
    case TIPO_TERMO_ADESAO:
      return AudioTermoAdesaoResumo(dados, tokenSignatario);
    case TIPO_TERMO_ADESAO_FGTS:
      return AudioTermoAdesaoFGTSResumo(dados, tokenSignatario);
    case TIPO_TERMO_FIGITAL:
      return AudioTermoFigitalResumo(dados, tokenSignatario);
    default:
      return AudioTermoAdesaoResumo(dados, tokenSignatario);
  }
};

export { AudioAcessibilidade };
