import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import OrientacaoImg from 'assets/orientacao.png';

import { MENSAGEM_ORIENTACAO, CODIGO_ERRO_ORIENTACAO } from './consts';

import { useApp } from 'context/App';
import api from 'services/api';

import './styles.css';

export default function Orientacao() {
  const { tokenSignatario } = useApp();
  const history = useHistory();

  function continuar() {
    history.goBack();
  }

  useEffect(() => {
    const gravaLog = async () => {
      const data = {
        token: tokenSignatario,
        mensagem: `${MENSAGEM_ORIENTACAO} - Ângulo da orientação: ${window.orientation !== undefined ? window.orientation : 'indefinido'}`,
        tipo: CODIGO_ERRO_ORIENTACAO,
      };

      await api.post(`BemSign/GravarLog`, data, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('autorizacao')}`,
          'Content-Type': 'application/json',
        },
      });
    };

    gravaLog();
  }, []);

  return (
    <div className="container orientacao-container">
      <h1>Ajuste a orientação do seu celular</h1>
      <h2>
        Para melhorar sua experiência, indicamos que use o celular na orientação
        retrato, conforme mostrado abaixo:
      </h2>
      <img src={OrientacaoImg} alt="orientacao" />
      <button className="button-bem" onClick={continuar}>
        Continuar
      </button>
    </div>
  );
}
