import React, { createContext, useState, useContext, useEffect } from 'react';
import audioVozLigada from 'assets/audios/voz-ligada-1.mp3';
import audioVozDesligada from 'assets/audios/voz-desligada-1.mp3';
import audioDescricaoLigada from 'assets/audios/audio-desc-ligada.mp3';
import audioDescricaoDesligada from 'assets/audios/audio-desc-desligada.mp3';
import { ZoomGlobalState } from '../../public/core-sdk/ZoomGlobalState';
import { FaceTecSDK } from '../../public/core-sdk/FaceTecSDK.js/FaceTecSDK';
import { VOLUME } from 'services/consts';
import { useAudio } from 'react-use';

const AppContext = createContext();

export default function AppProvider({ children }) {
  const [proposta, setProposta] = useState('');
  const [cpf, setCpf] = useState('');
  const [senha, setSenha] = useState('');
  const [confirmacaoLgpd, setConfirmacaoLgpd] = useState(null);
  const [confirmacaoDados, setConfirmacaoDados] = useState(null);
  const [cliente, setCliente] = useState('');
  const [email, setEmail] = useState('');
  const [celular, setCelular] = useState('');
  const [ip, setIp] = useState('');
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [listaDocumentos, setListaDocumentos] = useState([]);
  const [documentosAssinados, setDocumentosAssinados] = useState([]);
  const [selfie, setSelfie] = useState(null);
  const [cidade, setCidade] = useState('');
  const [lote, setLote] = useState(0);
  const [token, setToken] = useState('');
  const [matchLevel, setMatchLevel] = useState(0);
  const [documentoIdentidadeFrente, setDocumentoIdentidadeFrente] =
    useState(null);
  const [documentoIdentidadeVerso, setDocumentoIdentidadeVerso] =
    useState(null);
  const [tokenSignatario, setTokenSignatario] = useState('');
  const [livenessConfig, setLivenessConfig] = useState(false);
  const [qtdTentativasFacematchConfig, setQtdTentativasFacematchConfig] =
    useState(0);
  const [facematchConfig, setFacematchConfig] = useState(false);
  const [tokenSMSConfig, setTokenSMSConfig] = useState(false);
  const [validarNascimentoConfig, setValidarNascimentoConfig] = useState(false);
  const [confirmacaoDadosConfig, setConfirmacaoDadosConfig] = useState(false);
  const [dadosInvalidosConfig, setDadosInvalidosConfig] = useState(false);
  const [pathToken, setPathToken] = useState('');
  const [signatarioAnexos, setSignatarioAnexos] = useState([]);
  const [geolocalizacaoPorIp, setGeolocalizacaoPorIp] = useState(false);
  const [tokenSMS, setTokenSMS] = useState(null);
  const [finalCelular, setFinalCelular] = useState('');
  const [usuarioValido, setUsuarioValido] = useState(false);
  const [senhaRedefinida, setSenhaRedefinida] = useState(false);
  const [facetecIndisponivel, setFacetecIndisponivel] = useState(false);
  const [exibirDocumentosConfig, setExibirDocumentosConfig] = useState(false);
  const [exibirPassosConfig, setExibirPassosConfig] = useState(false);
  const [listaPassos, setListaPassos] = useState([]);
  const [listaPages, setListaPages] = useState([]);
  const [statusFacetec, setStatusFacetec] = useState(0);
  const [idTokenPortal, setIdTokenPortal] = useState(0);
  const [assinaturaRealizada, setAssinaturaRealizada] = useState(false);
  const [assinaturaComErro, setAssinaturaComErro] = useState(false);
  const [orientacaoVerificada, setOrientacaoVerificada] = useState(false);
  const [sessaoExpiradaPortal, setSessaoExpiradaPortal] = useState(false);
  const [idUsuario, setIdUsuario] = useState(0);
  const [parteEmail, setParteEmail] = useState('');
  const [semNumeroCelular, setSemNumeroCelular] = useState('');
  const [dadosTemporarios, setDadosTemporarios] = useState(null);
  const [isLiveness, setIsLiveness] = useState(false);
  const [informacoes, setInformacoes] = useState(null);
  const [
    autorizoDebitoValorParcelasTotalOuParcialmente,
    setAutorizoDebitoValorParcelasTotalOuParcialmente,
  ] = useState(false);
  const [
    autorizoDebitosLimiteCreditoConta,
    setAutorizoDebitosLimiteCreditoConta,
  ] = useState(false);
  const [
    autorizoDebitosObrigacaoVencidaInclusive,
    setAutorizoDebitosObrigacaoVencidaInclusive,
  ] = useState(false);
  const [qtdTentativasLivenessConfig, setQtdTentativasLivenessConfig] =
    useState(0);
  const [ajudaPorVoz, setAjudaPorVoz] = useState(true);
  const [execucaoInicial, setExecucaoInicial] = useState(false);
  const [configuracaoAssinatura, setConfiguracaoAssinatura] = useState(null);
  const [audio, state, controls, ref] = useAudio({
    src: ajudaPorVoz ? audioVozLigada : audioVozDesligada,
    autoPlay: false,
  });
  const [idEmpresa, setIdEmpresa] = useState(null);
  const [habilitaAudio, setHabilitaAudio] = useState(false);
  const [audioDescricao, stateDescricao, controlsDescricao] = useAudio({
    src: habilitaAudio ? audioDescricaoLigada : audioDescricaoDesligada,
    autoPlay: false,
  });
  const [habilitaLibras, setHabilitaLibras] = useState(false);
  const [coletaDocumentoConfig, setColetaDocumentoConfig] = useState(false);
  const [componenteDocumentoConfig, setComponenteDocumentoConfig] = useState(false);
  const [quantidadeProgresso, setQuantidadeProgresso] = useState(0);
  const [listaSpanProgresso, setListaSpanProgresso] = useState([]);
  const [execucaoInicialDescricao, setExecucaoInicialDescricao] =
    useState(false);
  const [tema, setTema] = useState(null);
  const [configGlobal, setConfigGlobal] = useState(null);
const [ultimaAssinatura, setUltimaAssinatura] = useState(false);

  useEffect(() => {
    if (ZoomGlobalState.currentCustomization) {
      if (ajudaPorVoz) {
        ZoomGlobalState.currentCustomization.vocalGuidanceCustomization.mode = 1;
        FaceTecSDK.setCustomization(ZoomGlobalState.currentCustomization);
      } else {
        ZoomGlobalState.currentCustomization.vocalGuidanceCustomization.mode = 2;
        FaceTecSDK.setCustomization(ZoomGlobalState.currentCustomization);
      }
    }

    if (execucaoInicial) {
      controlsDescricao.pause();

      controls.volume(VOLUME);
      controls.play();
    } else {
      setExecucaoInicial(true);
    }
  }, [ajudaPorVoz]);

  useEffect(() => {
    if (execucaoInicialDescricao) {
      controls.pause();

      controlsDescricao.volume(VOLUME);
      controlsDescricao.play();
    } else {
      setExecucaoInicialDescricao(true);
    }
  }, [habilitaAudio]);

  useEffect(() => {
    if (quantidadeProgresso > 0) {
      for (let i = 0; i < quantidadeProgresso; i++) {
        setListaSpanProgresso((listaSpan) => [
          ...listaSpan,
          { id: i, checked: i === 0 },
        ]);
      }
    }
  }, [quantidadeProgresso]);

  const proximaPagina = () => {
    try {
      const pathnameAtual = pathAtual();
      const rotaAtual =
        pathnameAtual.last === '/identificacao'
          ? '/biometria'
          : pathnameAtual.last;
      let rotaAtualizada = '';

      if (listaPages.length) {
        let rota = listaPages.filter((rota) => rota.vigente)[0];

        if (rota && rotaAtual !== rota.rota) {
          rota = { rota: rotaAtual, vigente: true };
        }

        if (rota) {
          const rotaFilter = listaPages.filter(
            (objRota) => objRota.rota === rota.rota,
          )[0];
          const index = listaPages.indexOf(rotaFilter);
          rotaAtualizada = listaPages[index + 1].rota;
        } else {
          rotaAtualizada = listaPages[0].rota;
        }

        tornaVigente(rotaAtualizada);
        window.localStorage.setItem('last-route', rotaAtualizada);
      }

      return rotaAtualizada;
    } catch (error) {
      console.log(error);
    }
  };

  const tornaVigente = (rotaVigente) => {
    const listaAtualizada = listaPages.map((obj) => {
      return {
        rota: obj.rota,
        vigente: obj.rota === rotaVigente,
      };
    });

    setListaPages(listaAtualizada);
  };

  const mudaAjudaPorVoz = () => {
    setAjudaPorVoz(!ajudaPorVoz);
  };

  const alteraProgresso = () => {
    const spanDesmarcado = listaSpanProgresso.find((item) => !item.checked);

    if (spanDesmarcado) {
      const novaLista = listaSpanProgresso.map((span, index) => {
        if (index === spanDesmarcado.id) {
          span.checked = true;
        }

        return span;
      });

      setListaSpanProgresso(novaLista);
    }
  };

  const pathAtual = () => {
    const pathname = window.location.pathname;

    return { last: pathname.substring(pathname.lastIndexOf('/')), complete: pathname }
  }

  return (
    <AppContext.Provider
      value={{
        proposta,
        setProposta,
        cpf,
        setCpf,
        senha,
        setSenha,
        confirmacaoLgpd,
        setConfirmacaoLgpd,
        confirmacaoDados,
        setConfirmacaoDados,
        cliente,
        setCliente,
        email,
        setEmail,
        celular,
        setCelular,
        ip,
        setIp,
        latitude,
        setLatitude,
        longitude,
        setLongitude,
        listaDocumentos,
        setListaDocumentos,
        documentosAssinados,
        setDocumentosAssinados,
        selfie,
        setSelfie,
        cidade,
        setCidade,
        lote,
        setLote,
        token,
        setToken,
        matchLevel,
        setMatchLevel,
        documentoIdentidadeFrente,
        setDocumentoIdentidadeFrente,
        documentoIdentidadeVerso,
        setDocumentoIdentidadeVerso,
        tokenSignatario,
        setTokenSignatario,
        livenessConfig,
        setLivenessConfig,
        facematchConfig,
        setFacematchConfig,
        qtdTentativasFacematchConfig,
        setQtdTentativasFacematchConfig,
        tokenSMSConfig,
        setTokenSMSConfig,
        validarNascimentoConfig,
        setValidarNascimentoConfig,
        confirmacaoDadosConfig,
        setConfirmacaoDadosConfig,
        dadosInvalidosConfig,
        setDadosInvalidosConfig,
        pathToken,
        setPathToken,
        signatarioAnexos,
        setSignatarioAnexos,
        geolocalizacaoPorIp,
        setGeolocalizacaoPorIp,
        tokenSMS,
        setTokenSMS,
        finalCelular,
        setFinalCelular,
        usuarioValido,
        setUsuarioValido,
        senhaRedefinida,
        setSenhaRedefinida,
        facetecIndisponivel,
        setFacetecIndisponivel,
        exibirDocumentosConfig,
        setExibirDocumentosConfig,
        exibirPassosConfig,
        setExibirPassosConfig,
        listaPassos,
        setListaPassos,
        listaPages,
        setListaPages,
        proximaPagina,
        statusFacetec,
        setStatusFacetec,
        idTokenPortal,
        setIdTokenPortal,
        assinaturaRealizada,
        setAssinaturaRealizada,
        assinaturaComErro,
        setAssinaturaComErro,
        orientacaoVerificada,
        setOrientacaoVerificada,
        sessaoExpiradaPortal,
        setSessaoExpiradaPortal,
        idUsuario,
        setIdUsuario,
        parteEmail,
        setParteEmail,
        semNumeroCelular,
        setSemNumeroCelular,
        dadosTemporarios,
        setDadosTemporarios,
        isLiveness,
        setIsLiveness,
        informacoes,
        setInformacoes,
        autorizoDebitoValorParcelasTotalOuParcialmente,
        setAutorizoDebitoValorParcelasTotalOuParcialmente,
        autorizoDebitosLimiteCreditoConta,
        setAutorizoDebitosLimiteCreditoConta,
        autorizoDebitosObrigacaoVencidaInclusive,
        setAutorizoDebitosObrigacaoVencidaInclusive,
        ajudaPorVoz,
        setAjudaPorVoz,
        mudaAjudaPorVoz,
        qtdTentativasLivenessConfig,
        setQtdTentativasLivenessConfig,
        configuracaoAssinatura,
        setConfiguracaoAssinatura,
        idEmpresa,
        setIdEmpresa,
        habilitaAudio,
        setHabilitaAudio,
        habilitaLibras,
        setHabilitaLibras,
        coletaDocumentoConfig,
        setColetaDocumentoConfig,
        componenteDocumentoConfig,
        setComponenteDocumentoConfig,
        quantidadeProgresso,
        setQuantidadeProgresso,
        listaSpanProgresso,
        setListaSpanProgresso,
        alteraProgresso,
        tema,
        setTema,
        pathAtual,
        configGlobal,
        setConfigGlobal,
        ultimaAssinatura,
        setUltimaAssinatura,
      }}
    >
      {audio}
      {audioDescricao}
      {children}
    </AppContext.Provider>
  );
}

export function useApp() {
  const context = useContext(AppContext);

  const {
    proposta,
    setProposta,
    cpf,
    setCpf,
    senha,
    setSenha,
    confirmacaoLgpd,
    setConfirmacaoLgpd,
    confirmacaoDados,
    setConfirmacaoDados,
    cliente,
    setCliente,
    email,
    setEmail,
    celular,
    setCelular,
    ip,
    setIp,
    latitude,
    setLatitude,
    longitude,
    setLongitude,
    listaDocumentos,
    setListaDocumentos,
    documentosAssinados,
    setDocumentosAssinados,
    selfie,
    setSelfie,
    cidade,
    setCidade,
    lote,
    setLote,
    token,
    setToken,
    matchLevel,
    setMatchLevel,
    documentoIdentidadeFrente,
    setDocumentoIdentidadeFrente,
    documentoIdentidadeVerso,
    setDocumentoIdentidadeVerso,
    tokenSignatario,
    setTokenSignatario,
    livenessConfig,
    setLivenessConfig,
    facematchConfig,
    setFacematchConfig,
    qtdTentativasFacematchConfig,
    setQtdTentativasFacematchConfig,
    tokenSMSConfig,
    setTokenSMSConfig,
    validarNascimentoConfig,
    setValidarNascimentoConfig,
    confirmacaoDadosConfig,
    setConfirmacaoDadosConfig,
    dadosInvalidosConfig,
    setDadosInvalidosConfig,
    pathToken,
    setPathToken,
    signatarioAnexos,
    setSignatarioAnexos,
    geolocalizacaoPorIp,
    setGeolocalizacaoPorIp,
    tokenSMS,
    setTokenSMS,
    finalCelular,
    setFinalCelular,
    usuarioValido,
    setUsuarioValido,
    senhaRedefinida,
    setSenhaRedefinida,
    facetecIndisponivel,
    setFacetecIndisponivel,
    exibirDocumentosConfig,
    setExibirDocumentosConfig,
    exibirPassosConfig,
    setExibirPassosConfig,
    listaPassos,
    setListaPassos,
    listaPages,
    setListaPages,
    proximaPagina,
    statusFacetec,
    setStatusFacetec,
    idTokenPortal,
    setIdTokenPortal,
    assinaturaRealizada,
    setAssinaturaRealizada,
    assinaturaComErro,
    setAssinaturaComErro,
    orientacaoVerificada,
    setOrientacaoVerificada,
    sessaoExpiradaPortal,
    setSessaoExpiradaPortal,
    idUsuario,
    setIdUsuario,
    parteEmail,
    setParteEmail,
    semNumeroCelular,
    setSemNumeroCelular,
    dadosTemporarios,
    setDadosTemporarios,
    isLiveness,
    setIsLiveness,
    informacoes,
    setInformacoes,
    autorizoDebitoValorParcelasTotalOuParcialmente,
    setAutorizoDebitoValorParcelasTotalOuParcialmente,
    autorizoDebitosLimiteCreditoConta,
    setAutorizoDebitosLimiteCreditoConta,
    autorizoDebitosObrigacaoVencidaInclusive,
    setAutorizoDebitosObrigacaoVencidaInclusive,
    ajudaPorVoz,
    setAjudaPorVoz,
    mudaAjudaPorVoz,
    qtdTentativasLivenessConfig,
    setQtdTentativasLivenessConfig,
    configuracaoAssinatura,
    setConfiguracaoAssinatura,
    idEmpresa,
    setIdEmpresa,
    habilitaAudio,
    setHabilitaAudio,
    habilitaLibras,
    setHabilitaLibras,
    coletaDocumentoConfig,
    setColetaDocumentoConfig,
    componenteDocumentoConfig,
    setComponenteDocumentoConfig,
    quantidadeProgresso,
    setQuantidadeProgresso,
    listaSpanProgresso,
    setListaSpanProgresso,
    alteraProgresso,
    tema,
    setTema,
    pathAtual,
    configGlobal,
    setConfigGlobal,
    ultimaAssinatura,
    setUltimaAssinatura,
  } = context;

  return {
    proposta,
    setProposta,
    cpf,
    setCpf,
    senha,
    setSenha,
    confirmacaoLgpd,
    setConfirmacaoLgpd,
    confirmacaoDados,
    setConfirmacaoDados,
    cliente,
    setCliente,
    email,
    setEmail,
    celular,
    setCelular,
    ip,
    setIp,
    latitude,
    setLatitude,
    longitude,
    setLongitude,
    listaDocumentos,
    setListaDocumentos,
    documentosAssinados,
    setDocumentosAssinados,
    selfie,
    setSelfie,
    cidade,
    setCidade,
    lote,
    setLote,
    token,
    setToken,
    matchLevel,
    setMatchLevel,
    documentoIdentidadeFrente,
    setDocumentoIdentidadeFrente,
    documentoIdentidadeVerso,
    setDocumentoIdentidadeVerso,
    tokenSignatario,
    setTokenSignatario,
    livenessConfig,
    setLivenessConfig,
    facematchConfig,
    setFacematchConfig,
    qtdTentativasFacematchConfig,
    setQtdTentativasFacematchConfig,
    tokenSMSConfig,
    setTokenSMSConfig,
    validarNascimentoConfig,
    setValidarNascimentoConfig,
    confirmacaoDadosConfig,
    setConfirmacaoDadosConfig,
    dadosInvalidosConfig,
    setDadosInvalidosConfig,
    pathToken,
    setPathToken,
    signatarioAnexos,
    setSignatarioAnexos,
    geolocalizacaoPorIp,
    setGeolocalizacaoPorIp,
    tokenSMS,
    setTokenSMS,
    finalCelular,
    setFinalCelular,
    usuarioValido,
    setUsuarioValido,
    senhaRedefinida,
    setSenhaRedefinida,
    facetecIndisponivel,
    setFacetecIndisponivel,
    exibirDocumentosConfig,
    setExibirDocumentosConfig,
    exibirPassosConfig,
    setExibirPassosConfig,
    listaPassos,
    setListaPassos,
    listaPages,
    setListaPages,
    proximaPagina,
    statusFacetec,
    setStatusFacetec,
    idTokenPortal,
    setIdTokenPortal,
    assinaturaRealizada,
    setAssinaturaRealizada,
    assinaturaComErro,
    setAssinaturaComErro,
    orientacaoVerificada,
    setOrientacaoVerificada,
    sessaoExpiradaPortal,
    setSessaoExpiradaPortal,
    idUsuario,
    setIdUsuario,
    parteEmail,
    setParteEmail,
    semNumeroCelular,
    setSemNumeroCelular,
    dadosTemporarios,
    setDadosTemporarios,
    isLiveness,
    setIsLiveness,
    informacoes,
    setInformacoes,
    autorizoDebitoValorParcelasTotalOuParcialmente,
    setAutorizoDebitoValorParcelasTotalOuParcialmente,
    autorizoDebitosLimiteCreditoConta,
    setAutorizoDebitosLimiteCreditoConta,
    autorizoDebitosObrigacaoVencidaInclusive,
    setAutorizoDebitosObrigacaoVencidaInclusive,
    ajudaPorVoz,
    setAjudaPorVoz,
    mudaAjudaPorVoz,
    qtdTentativasLivenessConfig,
    setQtdTentativasLivenessConfig,
    configuracaoAssinatura,
    setConfiguracaoAssinatura,
    idEmpresa,
    setIdEmpresa,
    habilitaAudio,
    setHabilitaAudio,
    habilitaLibras,
    setHabilitaLibras,
    coletaDocumentoConfig,
    setColetaDocumentoConfig,
    componenteDocumentoConfig,
    setComponenteDocumentoConfig,
    quantidadeProgresso,
    setQuantidadeProgresso,
    listaSpanProgresso,
    setListaSpanProgresso,
    alteraProgresso,
    tema,
    setTema,
    pathAtual,
    configGlobal,
    setConfigGlobal,
    ultimaAssinatura,
    setUltimaAssinatura,
  };
}
