import React, { useState } from 'react';
import ImageUploading from 'react-images-uploading';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BotaoFixado from 'components/BotaoFixado';
import './styles.css';

export default function UploadImage(props) {
  const [images, setImages] = useState([]);
  const maxMbFileSize = 5 * 1024 * 1024;

  function onChange(imageList, addUpdateIndex) {
    setImages(imageList);
    props.anexo.documento = imageList.length
      ? imageList[0].data_url.split(',')[1]
      : null;
  }

  function onError(erro) {
    if (erro.maxFileSize) {
      toast.error('O tamanho do arquivo deve ter no maximo 5MB');
    } else if (erro.acceptType) {
      toast.error('Os formatos aceitos são: jpg, png, jpeg');
    }
  }

  return (
    <ImageUploading
      value={images}
      onChange={onChange}
      dataURLKey="data_url"
      maxFileSize={maxMbFileSize}
      acceptType={['jpeg', 'png', 'jpg']}
      onError={onError}
    >
      {({
        imageList,
        onImageUpload,
        onImageRemoveAll,
        onImageUpdate,
        onImageRemove,
        isDragging,
        dragProps,
      }) =>
        !images.length ? (
          <div className="anexo-container">
            <ToastContainer />
            <h1>Atenção</h1>
            <ul>
              <li>
                O <b>{props.anexo.nome}</b> deve possuir no máximo 5MB.
              </li>
              <li>
                Os tipos de arquivos permitidos são os seguintes: jpeg, jpg e
                png.
              </li>
            </ul>
            <BotaoFixado onClick={onImageUpload}>Entendi</BotaoFixado>
          </div>
        ) : (
          imageList.map((image, index) => (
            <div key={index} className="anexo-container">
              <h1>Confirma o envio da imagem?</h1>
              <img src={image.data_url} alt="" className="imagem-anexada" />
              <BotaoFixado
                onClick={() => props.proximoAnexo()}
                BotaoComplemento={
                  <button
                    onClick={() => onImageUpdate(index)}
                    className="button-bem button-branco"
                  >
                    Não, selecionar outra imagem
                  </button>
                }
              >
                Sim, confirmo
              </BotaoFixado>
            </div>
          ))
        )
      }
    </ImageUploading>
  );
}
