import axios from 'axios';
import { API_BEMSIGN_URL, API_BEMSIGN_AUTENTICACAO_URL } from './consts';

const api = axios.create({
    baseURL: API_BEMSIGN_URL
})

export const apiAuth = axios.create({
    baseURL: API_BEMSIGN_AUTENTICACAO_URL
})

export default api;