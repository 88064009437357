import React, { useEffect } from 'react';
import {
  formatarMoeda,
  formatarStringDataMesAno,
  formatarStringData,
} from 'helpers/Formatters';
import audioResumoProposta from 'assets/audios/resumo-proposta.mp3';

import { useAudio } from 'react-use';
import { useApp } from 'context/App';
import { VOLUME } from 'services/consts';
import './styles.css';
import { AudioAcessibilidade } from 'components/AudioAcessibilidade';
import { cancelarAudio } from 'components/AudioAcessibilidade/utils';

import { dateAddMoths } from 'helpers/dateUtils';

export default function Resumo(prop) {
  const { dadosCCB } = prop;
  const { ajudaPorVoz, tokenSignatario, habilitaAudio } = useApp();
  const [audio, state, controls, ref] = useAudio({
    src: audioResumoProposta,
    autoPlay: false,
  });

  useEffect(() => {
    if (ajudaPorVoz && !habilitaAudio) {
      controls.volume(VOLUME);
      controls.play();
    }

    if (habilitaAudio) {
      executaAudioResumo();
    }

    return () => {
      if (habilitaAudio) cancelarAudio(tokenSignatario);
    };
  }, []);

  function executaAudioResumo() {
    AudioAcessibilidade(1, dadosCCB, tokenSignatario);
  }

  const vencimentoFolha = dateAddMoths(
    dadosCCB.caracteristicaCreditoCet.primeiroVencimento,
    -1,
  );

  return (
    <div className="resumo-ccb-container">
      {audio}
      <div className="resumo-valor-ccb">
        <label>Valor Financiado</label>
        <div>
          <span>
            {formatarMoeda(dadosCCB.caracteristicaCreditoCet.valorFinanciado)}
          </span>
        </div>
      </div>

      <div className="resumo-valor-ccb">
        <label>Valor Líquido</label>
        <div>
          <span>{formatarMoeda(dadosCCB.valorLiberado)}</span>
        </div>
      </div>

      <div className="resumo-informacoes-adicionais">
        <div className="tipo-operacao">
          <label>Operação</label>
          <span>Proposta {dadosCCB.descricaoTipoOperacao} (consignado)</span>
        </div>
      </div>

      <div className="resumo-informacoes-adicionais">
        <div className="tipo-operacao">
          <label>Vencimento</label>
          <span>
            {formatarStringData(
              dadosCCB.caracteristicaCreditoCet.primeiroVencimento,
            )}{' '}
            (Folha de {formatarStringDataMesAno(vencimentoFolha)})
          </span>
        </div>
      </div>

      <div className="resumo-informacoes-adicionais">
        <div>
          <label>Parcela</label>
          <span>
            {formatarMoeda(dadosCCB.caracteristicaCreditoCet.valorPrestacao)}
          </span>
        </div>

        <div className="text-align-right">
          <label>Prazo</label>
          <span>
            {dadosCCB.caracteristicaCreditoCet.quantidadePrestacao} vezes
          </span>
        </div>
      </div>
    </div>
  );
}
