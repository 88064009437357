import api from 'services/api';

const useDownload = () => {

    const download = (data, documentName, type = 'application/pdf') => {
        const fileName = documentName;

        const blob = new Blob([data], { type: type });

        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
    }

    return { download };
}

export default useDownload;