import api from 'services/api';

const executarAudio = (texto, tokenSignatario) => {
    try {
        var msg = new SpeechSynthesisUtterance();
        var voices = window.speechSynthesis.getVoices();
        msg.voice = voices.filter(x => x.lang === 'pt-BR')[0];
        msg.volume = 0.8; // 0 to 1
        // msg.rate = 0.8; // 0.1 to 10
        msg.text = texto;
        msg.lang = 'pt-BR';

        cancelarAudio(tokenSignatario);
        window.speechSynthesis.speak(msg);
    } catch (err) {
        gravarLogErro(err, 'Falha ao executar aúdio de acessibilidade na tela de resumo', tokenSignatario)
     }
}

const cancelarAudio = (tokenSignatario, gravaLog = true) => {
    try {
        window.speechSynthesis.cancel();
    } catch (err) {
        if(gravaLog)
          gravarLogErro(err, 'Falha ao cancelar aúdio de acessibilidade na tela de resumo', tokenSignatario)
     }
}

async function gravarLogErro(erro, mensagem, tokenSignatario) {
    const ERRO_NEGOCIO = 1;
    const data = {
        token: tokenSignatario,
        mensagem: `${mensagem}: ${erro}}`,
        tipo: ERRO_NEGOCIO,
    };

    await api.post(`BemSign/GravarLog`, data, {
        headers: {
            Authorization: `Bearer ${window.localStorage.getItem('autorizacao')}`,
            'Content-Type': 'application/json',
        },
    });
}


export { executarAudio, cancelarAudio }
