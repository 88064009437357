import React from 'react';
import { useHistory } from 'react-router-dom';

import LocalizacaoErro from 'assets/erro.png';

import './styles.css';
import { useApp } from 'context/App';

export default function LocalizacaoAviso() {
  const { configGlobal } = useApp();
  const history = useHistory();
  const corPrimaria = configGlobal?.aviso_cor_fundo;

  function retornar() {
    history.push('/localizacao');
  }

  function finalizar() {
    history.push('/');
  }

  return (
    <div className="container localizacao-aviso-container" style={{ background: corPrimaria }}>
      <img src={LocalizacaoErro} alt="localização não permitida" />
      <h1>Para prosseguir permita acesso a sua localização e clique em "Tentar novamente".</h1>
      <h2>O acesso a sua localização é para garantir sua segurança.</h2>

      <button className="button-bem" onClick={retornar} style={{ background: configGlobal.aviso_cor_botao_primario, color: configGlobal.aviso_cor_fonte_botao_primario }}>
        Tentar Novamente
      </button>
      <button className="button-bem finalizar" onClick={finalizar} style={{ background: corPrimaria }}>Finalizar</button>
    </div>
  );
}
