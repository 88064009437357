import React, { useState, useEffect } from 'react';

import api from 'services/api';
import { useApp } from 'context/App';

import { formatarCPF, formatarIniciarMaiusculo } from 'helpers/Formatters';
import { telaVisualizada } from 'helpers/telaVisualizada';

export default function Cartao(props) {
  const [dadosCartao, setDadosCartao] = useState(null);
  const { tokenSignatario, configuracaoAssinatura } = useApp();
  const { setIsLoading, setDadosValidos, setBloqueioScroll } = props;

  useEffect(() => {
    const fecthData = async () => {
      try {
        setIsLoading(true);
        const response = await api.get(
          `Template/ObterDadosTermoValidacaoCartao/${tokenSignatario}`,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                'autorizacao',
              )}`,
            },
          },
        );
        setIsLoading(false);
        setDadosValidos(true);
        setDadosCartao(response.data.retorno);
        if (configuracaoAssinatura.validaVisualizacaoDocumento) {
          setBloqueioScroll(!telaVisualizada());
        }
      } catch (err) {
        setDadosValidos(false);
        setIsLoading(false);
      }
    };
    fecthData();
  }, []);

  return (
    dadosCartao && (
      <div className="container-cartao">
        <h2>Resumo da proposta</h2>
        <table className="table-group" cellSpacing="0" cellPadding="0">
          <thead>
            <tr>
              <th colSpan="2"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Operação</td>
              <td>
                {formatarIniciarMaiusculo(dadosCartao.operacao)}
              </td>
            </tr>
            <tr>
              <td>CPF do cliente</td>
              <td>{formatarCPF(dadosCartao.cpf)}</td>
            </tr>
            <tr>
              <td>Nome do cliente</td>
              <td>{formatarIniciarMaiusculo(dadosCartao.nome)}</td>
            </tr>
            <tr>
              <td>Correspondente - Loja</td>
              <td>{`${dadosCartao.correspondenteNome} - ${dadosCartao.correspondenteCodigo}`}</td>
            </tr>
          </tbody>
        </table>

        <p>
          O Cliente concorda que, em razão do limite disponível de margem e o
          momento da averbação, os valores da operação poderão sofrer alterações
        </p>
      </div>
    )
  );
}
