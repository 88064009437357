import React from 'react';

import AppProvider from 'context/App';

import 'global.css';

import Routes from 'routes';

function App() {
  window.addEventListener('popstate',() => { window.location.reload() })

  return (
    <AppProvider>
      <Routes />
    </AppProvider>
  );
}

export default App;
