import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import 'react-toastify/dist/ReactToastify.css';

import dateTimeMiliseconds from 'helpers/dateTimeMiliseconds';

import { useApp } from 'context/App';
import BotaoFixado from 'components/BotaoFixado';
import './styles.css';

export default function Passos() {
  const history = useHistory();

  const { setConfirmacaoLgpd, listaPassos, proximaPagina, configGlobal } = useApp();

  function iniciar(e) {
    e.preventDefault();

    setConfirmacaoLgpd(dateTimeMiliseconds());

    history.push(proximaPagina());
  }

  return (
    <div className="container passos-container" style={{ background: configGlobal.passos_cor_fundo }}>

      <section className="form">
        <h1>{configGlobal.passos_titulo}</h1>
        <h2>{configGlobal.passos_subtitulo}</h2>

        <div className='instrucoes-container'>
          {listaPassos.map((card) => (
            <div className="instrucao" key={card.altText}>
              <card.image style={{ fill: configGlobal.passos_cor_icone }} />
              <span>{card.text}</span>
            </div>
          ))}
        </div>
        <p className="aviso-passos">
          {configGlobal.passos_info_adicional}
        </p>
      </section>
      <BotaoFixado onClick={iniciar} cor='transparent' corBotao={configGlobal.passos_cor_botao_primario} corFonte={configGlobal.passos_cor_fonte_botao_primario}>
        Iniciar
      </BotaoFixado>
    </div>
  );
}
