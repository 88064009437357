import { useApp } from 'context/App';
import React, { useEffect, useState } from 'react';
import api from 'services/api';
import {
  formatarMoeda,
  formatarPercentual,
  formatarCPF,
  formatarCep,
  formatarStringData,
  formatarTelefone,
} from 'helpers/Formatters';
import { telaVisualizada } from 'helpers/telaVisualizada';
import './styles.css';

export default function ContratoSeguro(props) {
  const [dadosContrato, setDadosContrato] = useState(null);
  const { tokenSignatario, configuracaoAssinatura } = useApp();

  useEffect(() => {
    const fecthData = async () => {
      try {
        props.setIsLoading(true);
        const urlIdExterno = props.idExterno ? `/${props.idExterno}` : '';

        const url = `Template/ObterDadosPropostaContratacaoSeguro/${tokenSignatario}${urlIdExterno}`;

        const response = await api.get(url, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              'autorizacao'
            )}`,
          },
        });
        props.setIsLoading(false);
        props.setDadosValidos(true);
        setDadosContrato(response.data.retorno);
        if (configuracaoAssinatura.validaVisualizacaoDocumento) {
          props.setBloqueioScroll(!telaVisualizada());
        }
      } catch (err) {
        props.setDadosValidos(false);
        props.setIsLoading(false);
      }
    };
    fecthData();
  }, [props.idDocumento, props.idExterno]);

  return (
    dadosContrato && (
      <div className="container-contrato-seguro">
        <h2>Proposta de contratação de seguro</h2>
        <table className="table-group" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th colSpan="2">Dados do seguro</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Estipulante</td>
              <td>
                BANCO DO ESTADO DO RIO GRANDE DO SUL S/A Número da Apólice:
                {dadosContrato.proposta}
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table-group" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th colSpan="2">Dados do proponente</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Nome completo</td>
              <td>{dadosContrato.nome}</td>
            </tr>
            <tr>
              <td>CPF</td>
              <td>{formatarCPF(dadosContrato.cpf)}</td>
            </tr>
            <tr>
              <td>Sexo</td>
              <td>{dadosContrato.sexo === 'M' ? 'Masculino' : 'Feminino'}</td>
            </tr>
            <tr>
              <td>Data de nascimento</td>
              <td>{formatarStringData(dadosContrato.nascimento)}</td>
            </tr>
            <tr>
              <td>Estado civil</td>
              <td>{dadosContrato.estadoCivil}</td>
            </tr>
            <tr>
              <td>Documento de identificação</td>
              <td>{dadosContrato.numeroDocumentacaoIdentificacao}</td>
            </tr>
            <tr>
              <td>Expedido em</td>
              <td>{formatarStringData(dadosContrato.dataExpedicao)}</td>
            </tr>
            <tr>
              <td>Por</td>
              <td>{dadosContrato.orgaoExpedidor}</td>
            </tr>
            <tr>
              <td>Endereço de residência</td>
              <td>{dadosContrato.endereco}</td>
            </tr>
            <tr>
              <td>Bairro</td>
              <td>{dadosContrato.bairro}</td>
            </tr>
            <tr>
              <td>Cidade</td>
              <td>{dadosContrato.cidade}</td>
            </tr>
            <tr>
              <td>UF</td>
              <td>{dadosContrato.uf}</td>
            </tr>
            <tr>
              <td>Cep</td>
              <td>{formatarCep(dadosContrato.cep)}</td>
            </tr>
            <tr>
              <td>Telefone e DDD</td>
              <td>
                {formatarTelefone(dadosContrato.ddd + dadosContrato.telefone)}
              </td>
            </tr>
            <tr>
              <td>Nacionalidade</td>
              <td>{dadosContrato.nacionalidade}</td>
            </tr>
            <tr>
              <td>Residente no Brasil</td>
              <td>{dadosContrato.residenteNoBrasil ? 'Sim' : 'Não'}</td>
            </tr>
            <tr>
              <td>Profissão</td>
              <td>{dadosContrato.profissao}</td>
            </tr>
            <tr>
              <td>Renda mensal</td>
              <td>{formatarMoeda(dadosContrato.valorRenda)}</td>
            </tr>
            <tr>
              <td>Pessoa politicamente exposta?</td>
              <td>
                {dadosContrato.pessoaPoliticamenteExposta ? 'Sim' : 'Não'}
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          1 - No caso do proponente estrangeiro o passaporte deve ser utilizado
          como documento de identificação.
          <br />2 - Consideram-se pessoas politicamente expostas os agentes
          públicos que desempenham ou tenham desempenhado, nos últimos cinco
          anos, no Brasil ou em países, territórios e dependências estrangeiros,
          cargos, empregos ou funções públicas relevantes, assim como seus
          representantes, familiares e outras pessoas de seu relacionamento
          próximo. Para mais esclarecimentos sobre Pessoas Politicamente Exposta
          consulte http://hotsite.icatuseguros.com.br/ppe/.
        </p>
        <table className="table-group" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th colSpan="2">Dados do plano</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Garantias</td>
              <td>Morte</td>
            </tr>
            <tr>
              <td>Capital segurado</td>
              <td>{formatarMoeda(dadosContrato.valorCapitalSegurado)}</td>
            </tr>
            <tr>
              <td>Sorteios</td>
              <td>1 (um) sorteio mensal no valor de R$ 1.000,00</td>
            </tr>
            <tr>
              <td>Início de vigência do risco individual</td>
              <td>
                às 24(vinte e quatro) horas da data do pagamento do seguro
              </td>
            </tr>
            <tr>
              <td>Final de vigência do risco individual</td>
              <td>
                às 24 hs da data em que completar{' '}
                {formatarStringData(dadosContrato.dataFimVigencia)} meses
                contados a partir do dia de início de vigência do risco
                individual
              </td>
            </tr>
            <tr>
              <td>Prêmio do seguro</td>
              <td>
                {formatarMoeda(dadosContrato.valorPremioSeguro)}, pago em uma
                única parcela
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          3 - Haverá um período de carência de 6 (seis) meses, contado a partir
          do início de vigência do seguro, período este que o(s) beneficiário(s)
          não terá(ão) direito a indenização por morte, salvo se em decorrência
          de acidente pessoal coberto, conforme condições gerais.
          <br />4 - Valor bruto de impostos, sujeito à tributação com base na
          legislação vigente à época da premiação. O sorteio ocorrerá no último
          sábado do mês. O segurado passará a concorrer a partir do mês
          subsequente do repasse das mensalidades do seguro para a Seguradora.
        </p>
        <table className="table-group" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th colSpan="2">Dados dos beneficiários</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Nome:
                <br />
                Grau de parentesco:
                <br />
                Percentual:
              </td>
              <td>
                {dadosContrato.nomeBeneficiario1}
                <br />
                {dadosContrato.grauParentescoBeneficiario1}
                <br />
                {dadosContrato.percentualBeneficiario1
                  ? formatarPercentual(dadosContrato.percentualBeneficiario1)
                  : ''}
              </td>
            </tr>
            <tr>
              <td>
                Nome:
                <br />
                Grau de parentesco:
                <br />
                Percentual:
              </td>
              <td>
                {dadosContrato.nomeBeneficiario2}
                <br />
                {dadosContrato.grauParentescoBeneficiario2}
                <br />
                {dadosContrato.percentualBeneficiario2
                  ? formatarPercentual(dadosContrato.percentualBeneficiario2)
                  : ''}
              </td>
            </tr>
            <tr>
              <td>
                Nome:
                <br />
                Grau de parentesco:
                <br />
                Percentual:
              </td>
              <td>
                {dadosContrato.nomeBeneficiario3}
                <br />
                {dadosContrato.grauParentescoBeneficiario3}
                <br />
                {dadosContrato.percentualBeneficiario3
                  ? formatarPercentual(dadosContrato.percentualBeneficiario3)
                  : ''}
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          É garantido o direito de pagamento da indenização ao(s)
          beneficiário(s) legal(is) ou àqueles indicados pelo proponente, na
          forma da legislação vigente.{' '}
        </p>
        <table className="table-group" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th colSpan="2">Declarações do proponente</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="2">
                Uma vez aprovada esta proposta pela Icatu Seguros S.A., declaro
                estar ciente e de acordo que: <br />
                1. A aceitação do seguro estará sujeita à análise do risco.{' '}
                <br />
                2. O início de vigência do seguro dar-se-á às 24 (vinte e
                quatro) horas da data do pagamento do prêmio e vigorará pelo
                prazo de {dadosContrato.prazo}, sendo que no caso de quitação ou
                refinanciamento, o seguro permanecerá ativo e a data de final de
                vigência inalterada.
                <br />
                3. É garantido o direito de pagamento da indenização ao(s)
                beneficiário(s) legal(is) ou àqueles indicados pelo proponente,
                na forma da legislação vigente.
                <br />
                4. As informações contidas nesta proposta são certas, completas
                e verdadeiras. Estou ciente de que quaisquer omissões ou
                inexatidões contidas nas informações prestadas nesta proposta
                ocasionarão a perda do direito ou a resolução do contrato pela
                Seguradora, de acordo com os termos do art. 766 do Código Civil,
                podendo ainda, responder civil e criminalmente pelas informações
                inverídicas.
                <br />
                5. Declaro que tive prévio e expresso conhecimento dos termos
                das Condições Gerais e de todos os documentos relacionados ao
                presente seguro disponíveis no site www.icatuseguros.com.br.
                Atesto, ainda, que recebi do estipulante todas as informações e
                esclarecimentos necessários para esse fim, na forma da Resolução
                CNSP nº 107, de 16 de janeiro de 2004.
                <br />
                6. Reconheço que a minha assinatura na presente proposta implica
                no conhecimento e aprovação dos itens presentes nas Condições
                Gerais e nas Declarações do proponente.
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table-group" cellPadding="0" cellSpacing="0">
          <thead>
            <tr>
              <th colSpan="2">Autorização de débito</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="2">
                Autorizo o Banco do Estado do Rio Grande do Sul S/A - Banrisul a
                efetuar o desconto da parcela única desse seguro do valor do
                empréstimo adquirido por mim perante o Banco.
              </td>
            </tr>
          </tbody>
        </table>
        <p className="text-align-right">
          <b>{dadosContrato.dataAtualPorExtenso}</b>
        </p>
        <div className="div-condicoes">
          <p>
            Assinatura do proponente e/ou responsável (caso seja menor de 18
            anos de idade) Seguro de Vida em Grupo administrado pela Icatu
            Seguros S/A, inscrita no CNPJ/MF sob o n° 42.283.770/0001-39,
            aprovado pela SUSEP sob o n° 15414.001273/2006-81. Título de
            capitalização da modalidade incentivo emitido pela Icatu
            Capitalização S/A, inscrita no CNPJ/MF sob o n°74.267.170/0001-73,
            aprovado pela SUSEP sob o n°15414.002643/200435. O registro deste
            plano na SUSEP não implica, por parte da Autarquia, incentivo ou
            recomendação a sua comercialização. O segurado poderá consultar a
            situação cadastral de seu corretor de seguros, no site
            www.susep.gov.br por meio do número de seu registro na SUSEP, nome
            completo, CNPJ ou CPF. A aceitação do seguro estará sujeita à
            análise do risco. Este seguro é por prazo determinado tendo a
            seguradora a faculdade de não renovar a apólice na data de
            vencimento, sem devolução dos prêmios pagos nos termos da apólice.
            SAC 0800 286 0110 (exclusivo para informações públicas, reclamações
            ou cancelamentos de produtos adquiridos pelo telefone). Serviço de
            Informação ao Cidadão SUSEP 0800 021 84 84 (dias úteis, das 9h30 às
            17h) ou www.susep.gov.br. Ouvidoria Icatu Seguros 0800 286 0047
          </p>
        </div>
      </div>
    )
  );
}
