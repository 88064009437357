import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isValidTokenScore } from 'helpers/authScore';
import { dataAno4DigitosMask } from 'helpers/masks';
import useRole from 'helpers/hooks/useRole';
import MaskedInput from 'react-text-mask';
import dateFormat from 'helpers/dateFormat';
import PageLoader from 'components/PageLoader';
import './styles.css';
import useDownloadRelatorio from 'helpers/hooks/useDownloadRelatorio';

export default function Relatorio() {
    const [usuarioFiltro, setUsuarioFiltro] = useState('');
    const [inicioFiltro, setInicioFiltro] = useState('');
    const [fimFiltro, setFimFiltro] = useState('');
    const { getIsAdmRole } = useRole();
    const history = useHistory();
    const { baixarLista, loading } = useDownloadRelatorio();

    useEffect(() => {
        const valido = isValidTokenScore();
        const isAdmRole = getIsAdmRole();

        if (!valido || !isAdmRole) {
            history.push('/login');
        }
    }, []);

    const gerarRelatorio = (e) => {
        e.preventDefault();

        const data = {
            usuario: usuarioFiltro ? usuarioFiltro : null,
            dataInicio: inicioFiltro ? dateFormat(inicioFiltro) : null,
            dataFim: fimFiltro ? dateFormat(fimFiltro) : null
        };;

        baixarLista(data);
    }


    return <div className='container relatorio-container'>
        <section
            className={`loader-animation ${loading ? 'enabled' : 'hidden'}`}
        >
            <PageLoader isOverlay="true" width={125} height={250} />
        </section>
        <h1>Relatório</h1>
        <div className='busca'>
            <form onSubmit={gerarRelatorio}>
                <div className='campos'>
                    <fieldset>
                        <legend>Usuario</legend>
                        <input onChange={(e) => setUsuarioFiltro(e.target.value)} />
                    </fieldset>
                    <fieldset>
                        <legend>Data início</legend>
                        <MaskedInput
                            guide={false}
                            mask={dataAno4DigitosMask}
                            onChange={(e) => setInicioFiltro(e.target.value)}
                            type="tel"
                        />
                    </fieldset>
                    <fieldset>
                        <legend>Data fim</legend>
                        <MaskedInput
                            guide={false}
                            mask={dataAno4DigitosMask}
                            onChange={(e) => setFimFiltro(e.target.value)}
                            type="tel"
                        />
                    </fieldset>
                </div>

                <div className='botao'>
                    <button className="button-bem">
                        Gerar relatório
                    </button>
                </div>
            </form>
        </div>

    </div>;
}