import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useApp } from 'context/App';

import dateTimeMiliseconds from 'helpers/dateTimeMiliseconds';
import {
  formatarCPF,
  formatarTelefone,
  formatarIniciarMaiusculo,
} from 'helpers/Formatters';
import api from 'services/api';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import audioConfirmeSeusDados from 'assets/audios/confirme-seus-dados-3.mp3';

import { useAudio } from 'react-use';
import { VOLUME } from 'services/consts';
import BotaoFixado from 'components/BotaoFixado';
import './styles.css';

export default function ConfirmacaoDados() {
  const [open, setOpen] = useState(false);
  const [naoPerturbeStatus, setNaoPerturbeStatus] = useState(false);
  const [naoPerturbeAceito, setNaoPerturbeAceito] = useState(false);
  const [esperaGravacao, setEsperaGravacao] = useState(false);
  const {
    cliente,
    cpf,
    email,
    celular,
    setConfirmacaoDados,
    tokenSignatario,
    dadosInvalidosConfig,
    proximaPagina,
    ajudaPorVoz,
    configuracaoAssinatura,
    alteraProgresso,
    configGlobal
  } = useApp();
  const history = useHistory();
  const [audio, state, controls, ref] = useAudio({
    src: audioConfirmeSeusDados,
    autoPlay: false,
  });

  const corPrimaria = configGlobal.confirmacao_cor_fundo;
  const corSecundaria = configGlobal.confirmacao_cor_fundo_secundaria;
  const bordaPrimaria = configGlobal.confirmacao_cor_fundo_secundaria ? `1px solid ${configGlobal.confirmacao_cor_fundo_secundaria}` : null;

  useEffect(() => {
    if (ajudaPorVoz) {
      controls.volume(VOLUME);
      controls.play();
    }

    setNaoPerturbeStatus(
      configuracaoAssinatura && configuracaoAssinatura.naoPerturbe,
    );
  }, []);

  async function gravarAuditoriaConfirmacaoConsentimento() {
    if (!esperaGravacao) {
      setEsperaGravacao(true);

      await api.post(
        `BemSign/GravarAuditoriaConfirmacaoConsentimento/${tokenSignatario}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              'autorizacao',
            )}`,
            'Content-Type': 'application/json',
          },
        },
      );

      setEsperaGravacao(false);
    }
  }

  async function confirmar(e) {
    e.preventDefault();

    setConfirmacaoDados(dateTimeMiliseconds());

    if (naoPerturbeStatus) {
      await gravarAuditoriaConfirmacaoConsentimento();
    }

    alteraProgresso();
    history.push(proximaPagina());
  }

  function onOpenModal(e) {
    e.preventDefault();

    setOpen(true);
  }

  function onCloseModal() {
    setOpen(false);
  }

  async function dadosErrados(e) {
    e.preventDefault();

    try {
      await api.post(
        `Assinatura/GravarInconsistencia/${tokenSignatario}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              'autorizacao',
            )}`,
            'Content-Type': 'application/json',
          },
        },
      );
    } catch (err) {
    } finally {
      history.push('/aviso', {
        textoH1:
          'Um atendente irá entrar em contato para confirmação dos dados.',
      });
    }
  }

  function onChangeCheckbox(e) {
    const target = e.target;
    const value = target.checked;

    setNaoPerturbeAceito(value);
  }

  return (
    <div className="container confirmacao-dados-container" style={{ background: corPrimaria }}>
      {audio}
      <section className="form">
        <h1>Confirme seus dados</h1>

        <form>
          <table className="table-group" cellSpacing="0" cellPadding="0" style={{ borderLeft: bordaPrimaria, borderRight: bordaPrimaria, borderBottom: bordaPrimaria }}>
            <thead>
              <tr>
                <th colSpan="2" style={{ backgroundColor: corSecundaria }}>Meus dados</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ borderTop: bordaPrimaria }}>Nome</td>
                <td style={{ borderTop: bordaPrimaria }}>{formatarIniciarMaiusculo(cliente)}</td>
              </tr>
              <tr>
                <td style={{ borderTop: bordaPrimaria }}>CPF</td>
                <td style={{ borderTop: bordaPrimaria }}>{formatarCPF(cpf)}</td>
              </tr>
              {celular && (
                <tr>
                  <td style={{ borderTop: bordaPrimaria }}>Celular</td>
                  <td style={{ borderTop: bordaPrimaria }}>{formatarTelefone(celular)}</td>
                </tr>
              )}
              {email && (
                <tr>
                  <td style={{ borderTop: bordaPrimaria }}>E-mail</td>
                  <td style={{ borderTop: bordaPrimaria }}>{email.toLowerCase()}</td>
                </tr>
              )}
            </tbody>
          </table>
          {naoPerturbeStatus && (
            <div className="checkbox-group">
              <input
                type="checkbox"
                id="concordo"
                className="checkbox-concordo"
                checked={naoPerturbeAceito}
                onChange={onChangeCheckbox}
              />
              <label htmlFor="concordo" className="checkbox-label">
                Telefone cadastrado na lista <b>Não Perturbe</b>. Confirmo que
                estou ciente e autorizo prosseguir com a operação de assinatura
                eletrônica.
              </label>
            </div>
          )}
          {dadosInvalidosConfig && (
            <a onClick={onOpenModal}>Meus dados estão errados</a>
          )}
        </form>
      </section>
      <Modal
        open={open}
        onClose={onCloseModal}
        center
        styles={{ modal: { borderRadius: '8px' } }}
        showCloseIcon={false}
      >
        <div className="modal-container">
          <h2>
            Indicando que seus dados estão errados você não poderá realizar a
            assinatura neste momento.
          </h2>

          <button className="button-bem" onClick={onCloseModal}>
            Continuar assinatura
          </button>
          <button className="button-bem finalizar" onClick={dadosErrados}>
            Estão errados
          </button>
        </div>
      </Modal>
      <BotaoFixado
        onClick={confirmar}
        disabled={(!naoPerturbeAceito && naoPerturbeStatus) || esperaGravacao}
        borderTop
        corBotao={configGlobal.confirmacao_cor_botao_primario}
        corFonte={configGlobal.confirmacao_cor_fonte_botao_primario}
        cor="transparent"
      >
        Confirmar
      </BotaoFixado>
    </div>
  );
}
