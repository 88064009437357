import React, { useState, useEffect } from 'react';

import api from 'services/api';
import 'react-responsive-modal/styles.css';
import { useApp } from 'context/App';
import Resumo from './Resumo';
import { telaVisualizada } from 'helpers/telaVisualizada';

export default function TermoValidacaoFigital(props) {
  const [dadosTermo, setDadosTermo] = useState(null);
  const { tokenSignatario, configuracaoAssinatura } = useApp();

  useEffect(() => {
    const fecthData = async () => {
      try {
        props.setIsLoading(true);
        const response = await api.get(
          `Template/ObterDadosTermoValidacaoFigital/${tokenSignatario}`,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                'autorizacao',
              )}`,
            },
          },
        );

        props.setIsLoading(false);
        props.setDadosValidos(true);
        setDadosTermo(response.data.retorno);
        if (configuracaoAssinatura.validaVisualizacaoDocumento) {
          props.setBloqueioScroll(!telaVisualizada());
        }
      } catch (err) {
        props.setDadosValidos(false);
        props.setIsLoading(false);
      }
    };
    fecthData();
  }, []);

  return dadosTermo && <Resumo dadosFigital={dadosTermo} />;
}
