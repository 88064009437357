import {
  PAGE_BIOMETRIA,
  PAGE_DOCUMENTO,
  PAGE_ASSINATURA,
  PAGE_TOKEN,
  PAGE_PASSOS,
  PAGE_CONFIRMACAO_DADOS,
  PAGE_CONCLUSAO,
  PAGE_VERIFICACAO,
  PAGE_LOCALIZACAO,
  PAGE_ANEXOS,
  PAGE_CAMERA,
  PAGE_SOLICITACAO_ACESSO,
  PAGE_INFORMACOES,
} from './consts';

const listaPaginas = (config) => {
  let lista = [];

  if (config.exibirPassos) {
    lista.push({ rota: PAGE_PASSOS, vigente: true });
  }

  lista.push({ rota: PAGE_SOLICITACAO_ACESSO, vigente: !config.exibirPassos });

  lista.push({ rota: PAGE_LOCALIZACAO });

  if (
    config.solicitarAcessoCamera &&
    (config.realizarLiveness || config.realizarFacematch)
  ) {
    lista.push({ rota: PAGE_CAMERA });
  }

  if (config.validarDataNascimento) {
    lista.push({ rota: PAGE_VERIFICACAO });
  }

  if (config.realizarConfirmacaoDados) {
    lista.push({ rota: PAGE_CONFIRMACAO_DADOS });
  }

  if (config.exibirDocumentos) {
    lista.push({ rota: PAGE_DOCUMENTO });
  }

  if (config.informacoes) {
    lista.push({ rota: PAGE_INFORMACOES });
  }

  if (config.validarTokenSms) {
    lista.push({ rota: PAGE_TOKEN });
  }

  if (config.realizarLiveness || config.realizarFacematch) {
    lista.push({ rota: PAGE_BIOMETRIA });
  }

  lista.push({ rota: PAGE_ANEXOS });
  lista.push({ rota: PAGE_ASSINATURA });
  lista.push({ rota: `${PAGE_CONCLUSAO}/${config.autenticacaoIdbase64}` });

  return lista;
};

export default listaPaginas;
