const isDevEnv = process.env.NODE_ENV === 'development';

const KEYS = [
  'REACT_APP_BEMSIGN_URL',
  'REACT_APP_BEMSIGN_AUTENTICACAO_URL',
  'REACT_APP_LICENSE_KEY_FACETEC',
  'REACT_APP_VOLUME',
  'REACT_APP_SALT_PBKDF2',
  'REACT_APP_VIDEO_BITS',
];

const API = {};

KEYS.map(
  (key) =>
    (API[key] = isDevEnv ? process.env[key] : window[key] || process.env[key]),
);

const API_BEMSIGN_URL = API.REACT_APP_BEMSIGN_URL;
const API_BEMSIGN_AUTENTICACAO_URL = API.REACT_APP_BEMSIGN_AUTENTICACAO_URL
const LICENSE_KEY_FACETEC = API.REACT_APP_LICENSE_KEY_FACETEC;
const VOLUME = API.REACT_APP_VOLUME;
const SALT_PBKDF2 = API.REACT_APP_SALT_PBKDF2;
const VIDEO_BITS = API.REACT_APP_VIDEO_BITS;

export {
  API_BEMSIGN_URL,
  API_BEMSIGN_AUTENTICACAO_URL,
  LICENSE_KEY_FACETEC,
  VOLUME,
  SALT_PBKDF2,
  VIDEO_BITS,
};
