import MobileDetect from 'mobile-detect';

const getListBrowser = () => {
  const md = new MobileDetect(navigator.userAgent);
  const rules = MobileDetect._impl.mobileDetectRules;
  const listBrowser = [];

  Object.keys(rules.props).map((propKey) => {
    let version;

    version = md.version(propKey);

    if (version) {
      listBrowser.push({ name: propKey, version: parseInt(version, 10) });
    }
  });

  return listBrowser;
};

const getBrowser = () => {
  let browser = 'Chrome';
  let listBrowser = getListBrowser();

  if (listBrowser.some((elem) => elem.name === 'Chrome')) {
    browser = 'Chrome';
  } else if (listBrowser.some((elem) => elem.name === 'Safari')) {
    browser = 'Safari';
  }

  return browser;
};

export default getBrowser;
