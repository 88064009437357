import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { formatarCPFOculto, formatarStringData } from 'helpers/Formatters';
import MaskedInput from 'react-text-mask';
import { cpfMask } from 'helpers/masks';
import api from 'services/api';
import './styles.css';
import BotaoFixado from 'components/BotaoFixado';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BREAKPOINT, STATUS_PROCESSANDO } from './consts';
import PageLoader from 'components/PageLoader';
import { isValidTokenScore } from 'helpers/authScore';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import useRole from 'helpers/hooks/useRole';
import NomeScore from './NomeScore';
import { AiFillFilter, AiOutlineFilter } from 'react-icons/ai';
import useDownloadBiometria from 'helpers/hooks/useDownloadBiometria';
import useViewport from 'helpers/hooks/useViewport';
import { useMemo } from 'react';

export default function Score() {
    const [nomeFiltro, setNomeFiltro] = useState('');
    const [cpfFiltro, setCpfFiltro] = useState('');
    const [nomeBiometria, setNomeBiometria] = useState('');
    const [cpfBiometria, setCpfBiometria] = useState('');
    const [listaScores, setListaScores] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [imgBiometria, setImgBiometria] = useState(null);
    const [open, setOpen] = useState(false);
    const [filtroVisivel, setFiltroVisivel] = useState(false);
    const [idBiometria, setIdBiometria] = useState(null);
    const [biometriaSelecionada, setBiometriaSelecionada] = useState(null);
    const { isAdmRole } = useRole();
    const { baixar, loading: loadingDownload } = useDownloadBiometria();
    const history = useHistory();
    const { width } = useViewport();

    const listarScoreBiometricoTela = async (filtro) => {
        const queryParams = {};

        if (filtro?.nomeFiltro) {
            queryParams.nome = filtro.nomeFiltro;
        }

        if (filtro?.cpfFiltro) {
            queryParams.cpf = filtro.cpfFiltro;
        }

        setIsLoading(true);

        const response = await api.get(
            'ScoreBiometrico/ListarScoreBiometricoTela',
            {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem(
                        'autorizacao-score',
                    )}`,
                },
                params: queryParams
            },
        );

        setIsLoading(false);

        return response;
    }

    const busca = async (event) => {
        event.preventDefault();

        const data = {
            nomeFiltro,
            cpfFiltro: cpfFiltro.replaceAll('.', '').replace('-', '')
        }

        try {
            const response = await listarScoreBiometricoTela(data);
            setListaScores(response.data.retorno);
        } catch (error) {
            toast.error('Ocorreu um erro ao obter a lista');
        }
    }

    const changeNome = (value) => {
        setNomeFiltro(value);
    }

    const changeCpf = (value) => {
        setCpfFiltro(value);
    }

    const changeNomeBiometria = (value) => {
        setNomeBiometria(value);
    }

    const changeCpfBiometria = (value) => {
        setCpfBiometria(value);
    }

    const novaBiometria = () => {
        history.push('/biometria-score', {
            nome: nomeBiometria,
            cpf: cpfBiometria
        });
    }

    const toggleFiltro = () => {
        setFiltroVisivel(!filtroVisivel);
    }

    const obterFotoScoreBiometrico = async (data) => {
        setIsLoading(true);

        try {
            const response = await api.get(`ScoreBiometrico/ObterFotoScoreBiometricoTela/${data.id}`, {
                headers: {
                    Authorization: `Bearer ${window.localStorage.getItem('autorizacao-score')}`,
                    'Content-Type': 'application/json'
                }
            });

            setIdBiometria(data.id);
            setImgBiometria(response.data.retorno);
            setBiometriaSelecionada(data);
            setOpen(true);

            setIsLoading(false);
        } catch (error) {
            toast.error('Não foi possível recuperar a biometria');
            setIsLoading(false);
        }
    }

    const onCloseModal = () => {
        setOpen(false);
        setImgBiometria(null);
        setIdBiometria(null);
        setBiometriaSelecionada(null);
    }

    useEffect(() => {

        const fetchData = async () => {
            const valido = isValidTokenScore();

            if (!valido) return history.push('/login');

            const response = await listarScoreBiometricoTela();

            setListaScores(response.data.retorno);
        }

        fetchData();
    }, []);

    const matchBreakpoint = useMemo(() => width <= BREAKPOINT, [width])

    return <div className='container score-container'>
        <fieldset className='inclusao-biometria'>
            <legend>Biometria</legend>
            <form onSubmit={novaBiometria}>
                <fieldset>
                    <legend>Nome</legend>
                    <input onChange={(e) => changeNomeBiometria(e.target.value)} />
                </fieldset>
                <fieldset>
                    <legend>CPF</legend>
                    <MaskedInput
                        guide={false}
                        mask={cpfMask}
                        onChange={(e) => changeCpfBiometria(e.target.value)}
                        autoComplete="off"
                        type="tel"
                    />
                </fieldset>
                <button className="button-bem" disabled={!nomeBiometria || !cpfBiometria}>
                    Incluir
                </button>
            </form>
        </fieldset>

        <div className='logo-titulo'>
            <h1>Biometrias recentes</h1>
            {!filtroVisivel && <AiOutlineFilter size={25} onClick={toggleFiltro} />}
            {filtroVisivel && <AiFillFilter size={25} onClick={toggleFiltro} />}
        </div>

        <fieldset className='busca' hidden={!filtroVisivel}>
            <legend>Filtro</legend>
            <form onSubmit={busca}>
                <div className='campos'>
                    <fieldset>
                        <legend>Nome</legend>
                        <input onChange={(e) => changeNome(e.target.value)} />
                    </fieldset>
                    <fieldset>
                        <legend>CPF</legend>
                        <MaskedInput
                            guide={false}
                            mask={cpfMask}
                            onChange={(e) => changeCpf(e.target.value)}
                            autoComplete="off"
                            type="tel"
                        />
                    </fieldset>
                </div>

                <div className='botao'>
                    <button className="button-bem">
                        Pesquisar
                    </button>
                </div>
            </form>
        </fieldset>

        <table>
            <thead>
                <tr>
                    <th>Cliente</th>
                    <th>Score</th>
                    {!matchBreakpoint && isAdmRole && <th>Usuário</th>}
                    {!matchBreakpoint && <th>Data</th>}
                </tr>
            </thead>
            <tbody>
                {listaScores.map((item, index) => (<tr key={item.cpf + index}>
                    <td>
                        <span><b>{formatarCPFOculto(item.cpf)}</b></span>
                        <span><NomeScore isAdmRole={isAdmRole} data={item} onClick={obterFotoScoreBiometrico} /></span>
                    </td>
                    <td>
                        {item.scoreUnico !== null && <span>Score: {item.scoreUnico}</span>}
                        {item.scoreSerpro !== null && <span>Serpro: {item.scoreSerpro}</span>}
                        {item.scoreUnico === null && <span>Status: {item.statusProcesso === STATUS_PROCESSANDO ? 'Processando' : item.statusProcessoDescricao}</span>}
                    </td>
                    {!matchBreakpoint && isAdmRole && <td>{item.usuario}</td>}
                    {!matchBreakpoint && <td>{formatarStringData(item.data)}</td>}
                </tr>))}

                {listaScores.length === 0 && <tr><td colSpan={3} align="center">Nenhum dado encontrado</td></tr>}
            </tbody>
        </table>
        <ToastContainer />
        <section
            className={`loader-animation ${isLoading || loadingDownload ? 'enabled' : 'hidden'}`}
        >
            <PageLoader isOverlay="true" width={125} height={250} />
        </section>
        {imgBiometria && <Modal
            open={open}
            onClose={onCloseModal}
            center
            styles={{ modal: { borderRadius: '8px' }, closeButton: { right: 0, top: 0 } }}
            showCloseIcon={true}
        >
            <div className='modal-biometria-score'>
                {imgBiometria && <img src={`data:image/png;base64,${imgBiometria}`} width={'100%'} alt='imagem biométrica' />}
                {biometriaSelecionada?.usuario && <div className='full-width-label'><b>Usuário:</b> {biometriaSelecionada?.usuario}</div>}
                {biometriaSelecionada?.data && <div className='full-width-label'><b>Data:</b> {formatarStringData(biometriaSelecionada?.data)}</div>}
                {idBiometria && <button className='button-bem' style={{ backgroundColor: '#010050F', maxWidth: '450px' }} onClick={() => baixar(idBiometria)}>Baixar</button>}
            </div>            
        </Modal>}
    </div>
}