import React, { useEffect, useState } from 'react';
import api from 'services/api';
import { useApp } from 'context/App';
import { telaVisualizada } from 'helpers/telaVisualizada';

import './styles.css';

export default function AutorizacaoLiquidarDivida(props) {
  const { tokenSignatario, configuracaoAssinatura } = useApp();
  const [dadosLiquidacao, setDadosLiquidacao] = useState(null);

  useEffect(() => {
    const fecthData = async () => {
      try {
        props.setIsLoading(true);
        const urlIdExterno = props.idExterno ? `/${props.idExterno}` : '';

        const url = `Template/ObterDadosAutorizacaoLiquidacaoDividas/${tokenSignatario}${urlIdExterno}`;

        const response = await api.get(url, {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem(
              'autorizacao'
            )}`,
          },
        });

        props.setIsLoading(false);
        props.setDadosValidos(true);

        setDadosLiquidacao(response.data.retorno);
        if (configuracaoAssinatura.validaVisualizacaoDocumento) {
          props.setBloqueioScroll(!telaVisualizada());
        }
      } catch (err) {
        props.setDadosValidos(false);
        props.setIsLoading(false);
      }
    };

    fecthData();
  }, [props.idDocumento, props.idExterno]);

  return (
    dadosLiquidacao && (
      <div className="autorizacao-liquidar-divida-container">
        <h2>Formulário autorização para liquidar dívidas</h2>
        <p>
          Eu, {dadosLiquidacao.nomeCliente}, portador do Documento de
          Identificação
          {dadosLiquidacao.documentoTipoIdentificacao} (RG/CNH...) número
          {dadosLiquidacao.numeroDocumentoIdentificacao} e do CPF{' '}
          {dadosLiquidacao.cpf}, AUTORIZO o Banco do Estado do Rio Grande do Sul
          S/A – BANRISUL, a efetuar a LIQUIDAÇÃO INTEGRAL do contrato número
          {dadosLiquidacao.contratos}, firmado com esta instituição financeira
          em {dadosLiquidacao.firmadoEm} pelo valor R$
          {dadosLiquidacao.valorTotal} ({dadosLiquidacao.valorTotalPorExtenso})
          nesta data. Reconheço que a presente liquidação está sendo realizada
          através de uma nova operação contratada na modalidade EMPRÉSTIMO
          CONSIGNAÇÃO EM FOLHA, em {dadosLiquidacao.quantidadeParcelas} parcelas
          mensais e sucessivas no valor de R$
          {dadosLiquidacao.valorParcela}(
          {dadosLiquidacao.valorParcelaPorExtenso}
          ), sendo esta contratação também realizada pelo BANRISUL conforme CCB
          – Cédula de Crédito Bancário de número {dadosLiquidacao.proposta}{' '}
          firmada nesta data, cujo valor liberado sofrerá dedução no valor do
          saldo devedor do contrato ora liquidado. Por ser expressão da verdade,
          firmo a presente AUTORIZAÇÃO que passa a fazer parte integrante da
          formalização da nova operação de Empréstimo Consignação Folha.
        </p>
      </div>
    )
  );
}
