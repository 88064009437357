import React from 'react';

import Direcional from 'assets/direc.png';
import Chrome from 'assets/chrome.png';
import ConfigChrome from 'assets/config-chrome.png';

export default function ChromeInstrucao() {
  return (
    <div className="instrucao-camera">
      <h1>Chrome no Android</h1>
      <ol dir="ltr">
        <li>
          No dispositivo Android, abra o app do Chrome{' '}
          <img
            src={Chrome}
            width="18"
            height="18"
            alt="Chrome"
            title="Chrome"
          />
          .
        </li>
        <li>
          À direita da barra de endereço, toque em Mais<strong>&nbsp;</strong>
          <img
            src={ConfigChrome}
            width="18"
            height="18"
            alt="Organizar"
            title="Organizar"
          />{' '}
          <img src={Direcional} width="18" height="18" alt="e" title="e" />{' '}
          <strong>Configurações</strong>.
        </li>
        <li>
          Toque em <strong>Configurações do site</strong>.
        </li>
        <li>
          Toque em <strong>Câmera</strong>.
        </li>
        <li>
          Toque para ativar a câmera.
          <ul>
            <li data-outlined="false">
              Se o site que você quer usar estiver em{' '}
              <strong>Bloqueados</strong>, toque no site{' '}
              <img src={Direcional} width="18" height="18" alt="e" title="e" />{' '}
              <strong>Permitir</strong>.
            </li>
          </ul>
        </li>
      </ol>
    </div>
  );
}
