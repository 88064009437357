import React, { useEffect } from 'react';
import {
  formatarMoeda,
  formatarPercentual,
  formatarIniciarMaiusculo,
  formatarStringData,
} from 'helpers/Formatters';
import audioResumoProposta from 'assets/audios/resumo-proposta.mp3';
import { useAudio } from 'react-use';
import { VOLUME } from 'services/consts';
import { useApp } from 'context/App';
import { AudioAcessibilidade } from 'components/AudioAcessibilidade';
import { cancelarAudio } from 'components/AudioAcessibilidade/utils';
import { TIPO_TERMO_FIGITAL } from 'components/AudioAcessibilidade/consts';
import './styles.css';

export default function Resumo(props) {
  const { dadosFigital } = props;
  const { ajudaPorVoz, tokenSignatario, habilitaAudio } = useApp();
  const [audio, state, controls, ref] = useAudio({
    src: audioResumoProposta,
    autoPlay: false,
  });

  useEffect(() => {
    if (ajudaPorVoz && !habilitaAudio) {
      controls.volume(VOLUME);
      controls.play();
    }

    if (habilitaAudio) {
      executaAudioResumo();
    }

    return () => {
      if (habilitaAudio) cancelarAudio(tokenSignatario);
    };
  }, []);

  const executaAudioResumo = () => {
    const dadosAudio = {
      operacao: dadosFigital.operacao,
      valorFinanciado: dadosFigital.valorFinanciado,
      valorLiquido: dadosFigital.valorLiquido,
      parcela: dadosFigital.valorParcela,
      prazo: dadosFigital.prazo,
      cetMes: dadosFigital.cetMes,
      primeiroVencimento: dadosFigital.primeiroVencimento,
    };

    AudioAcessibilidade(TIPO_TERMO_FIGITAL, dadosAudio, tokenSignatario);
  };

  return (
    <div className="resumo-figital-container">
      {audio}
      <h2>Resumo da proposta</h2>

      <div className="resumo-informacoes-adicionais">
        <div>
          <label>Operação</label>
          <span>{formatarIniciarMaiusculo(dadosFigital.operacao)}</span>
        </div>
      </div>

      <div className="resumo-valor-figital">
        <label>Valor financiado</label>
        <div>
          <span>{formatarMoeda(dadosFigital.valorFinanciado)}</span>
        </div>
      </div>

      <div className="resumo-valor-figital">
        <label>Valor líquido</label>
        <div>
          <span>{formatarMoeda(dadosFigital.valorLiquido)}</span>
        </div>
      </div>

      <div className="resumo-informacoes-adicionais">
        <div>
          <label>Parcela</label>
          <span>{formatarMoeda(dadosFigital.valorParcela)}</span>
        </div>

        <div className="text-align-right">
          <label>Prazo</label>
          <span>{dadosFigital.prazo} vezes</span>
        </div>
      </div>

      <div className="resumo-informacoes-adicionais">
        <div>
          <label>Cet mês</label>
          <span>{formatarPercentual(dadosFigital.cetMes)}</span>
        </div>

        <div className="text-align-right">
          <label>Primeiro Vencimento</label>
          <span>{formatarStringData(dadosFigital.primeiroVencimento)}</span>
        </div>
      </div>

      <p>
        O Cliente concorda que, em razão do limite disponível de margem e o
        momento da averbação, os valores da operação poderão sofrer alterações
      </p>
    </div>
  );
}
