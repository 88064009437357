import firstName from "./firstName";
import { Parser } from 'html-to-react';
import React from "react";

const listaTelas = [
    { nome: 'passos' },
    { nome: 'acesso' },
    { nome: 'camera' },
    { nome: 'localizacao' },
    { nome: 'verificacao' },
    { nome: 'confirmacao' },
    { nome: 'conclusao' },
    { nome: 'aviso' },
    { nome: 'biometria' },
    { nome: 'navegador' },
    { nome: 'tentativa' },
    { nome: 'header' },
];

const converteHtml = (string) => {
    const htmlInput = string;
    const htmlToReactParser = new Parser(React);
    const reactComponent = htmlToReactParser.parse(htmlInput);

    return reactComponent;
}

const extraiObjetos = (configEmpresa) => {
    const resultado = {};
    const layouts = configEmpresa?.layout?.telas || [];

    for (const tela of listaTelas) {
        const layoutObj = layouts.find(layout => layout.nome === tela.nome);

        if (layoutObj) {
            if (layoutObj.textos) {
                const novoObjeto = Object.assign({}, layoutObj, layoutObj.textos.reduce((acc, item) => {
                    acc[item.nome] = item.texto;
                    return acc;
                }, {}));

                delete novoObjeto.textos;

                resultado[tela.nome] = novoObjeto;
            } else {
                resultado[tela.nome] = layoutObj;
            }
        }
    }

    return resultado;
}

const geraConfigGlobal = (configEmpresa, nome) => {
    const {
        passos,
        acesso,
        camera,
        localizacao,
        verificacao,
        confirmacao,
        conclusao,
        aviso,
        biometria,
        navegador,
        tentativa,
        header
    } = extraiObjetos(configEmpresa);

    const config = {
        "passos_titulo": passos && passos?.titulo?.length >= 0 ? passos.titulo : `Olá, ${firstName(nome)}!`,
        "passos_subtitulo": passos && passos?.subtitulo?.length >= 0 ? passos.subtitulo : "Os seguintes passos serão necessários para validar sua identidade e serão usados com segurança.",
        "passos_info_adicional": passos && passos?.info_adicional?.length >= 0 ? converteHtml(passos.info_adicional) : converteHtml(`Consulte nosso <a href="https://www.bempromotora.com.br/bemsign" target="_blank" rel="noopener noreferrer">Termo de uso e Política de Privacidade</a>`),
        "passos_cor_fundo": passos && passos?.corFundo?.length >= 0 ? passos.corFundo : 'linear-gradient(162.43deg, #e15100 49.02%, #ff9e25 107.26%)',
        "passos_cor_fonte": '',
        "passos_cor_icone": passos && passos?.corIcone?.length >= 0 ? passos.corIcone : '#FFFFFF',
        "passos_cor_botao_primario": passos && passos?.corBotaoPrimario?.length >= 0 ? passos.corBotaoPrimario : null,
        "passos_cor_fonte_botao_primario": passos && passos?.corFonteBotaoPrimario?.length >= 0 ? passos.corFonteBotaoPrimario : null,
        "acesso_cor_fundo": acesso && acesso?.corFundo?.length >= 0 ? acesso.corFundo : null,
        "acesso_cor_icone": acesso && acesso?.corIcone?.length >= 0 ? acesso.corIcone : '#E15100',
        "acesso_cor_fonte": acesso && acesso?.corFonte?.length >= 0 ? acesso.corFonte : null,
        "acesso_cor_botao_primario": acesso && acesso?.corBotaoPrimario?.length >= 0 ? acesso.corBotaoPrimario : null,
        "acesso_cor_fonte_botao_primario": acesso && acesso?.corFonteBotaoPrimario?.length >= 0 ? acesso.corFonteBotaoPrimario : null,
        "camera_cor_fundo": camera && camera?.corFundo?.length >= 0 ? camera.corFundo : null,
        "camera_cor_icone": camera && camera?.corIcone?.length >= 0 ? camera.corIcone : '#8A8A8A',
        "localizacao_cor_fundo": localizacao && localizacao?.corFundo?.length >= 0 ? localizacao.corFundo : null,
        "localizacao_cor_icone": localizacao && localizacao?.corIcone?.length >= 0 ? localizacao.corIcone : '#8A8A8A',
        "verificacao_cor_fundo": verificacao && verificacao?.corFundo?.length >= 0 ? verificacao.corFundo : null,
        "verificacao_cor_botao_primario": verificacao && verificacao?.corBotaoPrimario?.length >= 0 ? verificacao.corBotaoPrimario : null,
        "verificacao_cor_fonte_botao_primario": verificacao && verificacao?.corFonteBotaoPrimario?.length >= 0 ? verificacao.corFonteBotaoPrimario : null,
        "confirmacao_cor_fundo": confirmacao && confirmacao?.corFundo?.length >= 0 ? confirmacao.corFundo : null,
        "confirmacao_cor_fundo_secundaria": confirmacao && confirmacao?.corFundoSecundaria?.length >= 0 ? confirmacao.corFundoSecundaria : null,
        "confirmacao_cor_botao_primario": confirmacao && confirmacao?.corBotaoPrimario?.length >= 0 ? confirmacao.corBotaoPrimario : null,
        "confirmacao_cor_fonte_botao_primario": confirmacao && confirmacao?.corFonteBotaoPrimario?.length >= 0 ? confirmacao.corFonteBotaoPrimario : null,
        "conclusao_cor_fundo": conclusao && conclusao?.corFundo?.length >= 0 ? conclusao.corFundo : null,
        "conclusao_cor_fonte": conclusao && conclusao?.corFonte?.length >= 0 ? conclusao.corFonte : null,
        "conclusao_cor_botao_primario": conclusao && conclusao?.corBotaoPrimario?.length >= 0 ? conclusao.corBotaoPrimario : "#E15100",
        "conclusao_cor_fonte_botao_primario": conclusao && conclusao?.corFonteBotaoPrimario?.length >= 0 ? conclusao.corFonteBotaoPrimario : "#FFFFFF",
        "aviso_cor_fundo": aviso && aviso?.corFundo?.length >= 0 ? aviso.corFundo : null,
        "aviso_cor_botao_primario": aviso && aviso?.corBotaoPrimario?.length >= 0 ? aviso.corBotaoPrimario : null,
        "aviso_cor_fonte_botao_primario": aviso && aviso?.corFonteBotaoPrimario?.length >= 0 ? aviso.corFonteBotaoPrimario : null,
        "biometria_cor_fundo": biometria && biometria?.corFundo?.length >= 0 ? biometria.corFundo : '#e15100',
        "navegador_cor_fundo": navegador && navegador?.corFundo?.length >= 0 ? navegador.corFundo : null,
        "navegador_cor_botao_primario": navegador && navegador?.corBotaoPrimario?.length >= 0 ? navegador.corBotaoPrimario : null,
        "tentativa_cor_fundo": tentativa && tentativa?.corFundo?.length >= 0 ? tentativa.corFundo : null,
        "header_cor_fundo": header && header?.corFundo?.length >= 0 ? header.corFundo : null,
    }
    return config;
}


export default geraConfigGlobal;