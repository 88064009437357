const TAMANHO_HASH_DOCUMENTO = 64;
const TAMANHO_IDENTIFICADOR_DOCUMENTO = 36;
const DOCUMENTO_ORIGINAL = 1;
const DOCUMENTO_EVIDENCIA = 2;
const LARGURA_MEDIA = 950;
const LARGURA_PEQUENA = 750;

export {
  TAMANHO_HASH_DOCUMENTO,
  TAMANHO_IDENTIFICADOR_DOCUMENTO,
  DOCUMENTO_ORIGINAL,
  DOCUMENTO_EVIDENCIA,
  LARGURA_MEDIA,
  LARGURA_PEQUENA,
};
