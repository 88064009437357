import {
  BANRISUL,
  BANRISUL_2,
  BANRISUL_PORTAL_EMPRESARIAL,
} from "helpers/consts";
import React from "react";
import Lottie from "react-lottie";
import * as animacaoPadrao from "./animacao-loader.json";
import * as animacaoEspecifica from "./animacao-especifica.json";

export default function LoaderPersonalizado(props) {
  const { idEmpresa } = props;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    renderer: "svg",
    animationData:
      idEmpresa === BANRISUL ||
      idEmpresa === BANRISUL_2 ||
      idEmpresa === BANRISUL_PORTAL_EMPRESARIAL
        ? animacaoEspecifica.default
        : animacaoPadrao.default,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return <Lottie options={defaultOptions} width={100} height={250} />;
}
