import React from 'react';
import { useHistory } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import './styles.css';
import useAuthScore from 'helpers/hooks/useAuthScore';
import useRole from 'helpers/hooks/useRole';

export default props => {
    const history = useHistory();
    const { deslogar } = useAuthScore();
    const { isAdmRole } = useRole();

    return (
        <Menu right>
            <a className="menu-item" onClick={() => history.push('/score')}>
                Biometrias
            </a>
            {isAdmRole && <a className="menu-item" onClick={() => history.push('/relatorio-biometria')}>
                Relatório
            </a>}
            <a className="menu-item" onClick={deslogar}>
                Sair
            </a>
        </Menu>
    );
};