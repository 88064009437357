import { useState, useEffect } from "react";

const useBestCamera = () => {
  const [deviceId, setDeviceId] = useState(null);
  const [facingMode, setFacingMode] = useState("user");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getBestCamera = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );

        if (videoDevices.length === 0) {
          throw new Error("Nenhuma câmera encontrada");
        }

        const frontCameras = videoDevices.filter((device) =>
          device.label.toLowerCase().includes("front")
        );
        const selectedCamera =
          frontCameras.length > 0
            ? frontCameras[frontCameras.length - 1]
            : null;

        if (selectedCamera) {
          setDeviceId(selectedCamera.deviceId);
          setFacingMode("user");
        } else {
          setDeviceId(videoDevices[0].deviceId);
          setFacingMode({ exact: "environment" });
        }
      } catch (err) {
        console.error("Erro ao encontrar a melhor câmera:", err);
        setError(err);

        setDeviceId(null);
        setFacingMode("user");
      } finally {
        setLoading(false);
      }
    };

    getBestCamera();
  }, []);

  return { deviceId, facingMode, error, loading };
};

export default useBestCamera;
