import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import { cpfMask } from 'helpers/masks';
import './styles.css';
import { useApp } from 'context/App';
import api from 'services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PageLoader from 'components/PageLoader';
import getOnlyNumbers from 'helpers/getOnlyNumbers';

export default function Usuario() {
  const [cpfDigitado, setCpfDigitado] = useState('');
  const [nome, setNome] = useState('');
  const [desabilitaBotao, setDesabilitaBotao] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { idUsuario, exibirPassosConfig, setCliente, setCpf } = useApp();

  async function atualizarUsuario(e) {
    e.preventDefault();

    const data = {
      idUsuario,
      nome,
      cpf: getOnlyNumbers(cpfDigitado),
    };

    try {
      setIsLoading(true);
      await api.put(`Portal/AtualizarUsuario`, data, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem('autorizacao')}`,
          'Content-Type': 'application/json',
        },
      });

      setCpf(getOnlyNumbers(data.cpf));
      setCliente(data.nome);

      setIsLoading(false);

      if (exibirPassosConfig) {
        history.push('/passos');
      } else {
        history.push('/solicitacao-acesso');
      }
    } catch (error) {
      toast.error('Erro ao atualizar usuário');
      setIsLoading(false);
    }
  }

  function changeCpf(valor) {
    setCpfDigitado(valor);
    setDesabilitaBotao(valor.length !== 14 || nome.length <= 10);
  }

  function changeNome(valor) {
    setNome(valor);
    setDesabilitaBotao(valor.length <= 10 || cpfDigitado.length !== 14);
  }

  return (
    <div className="container usuario-container">
      <section
        className={`loader-animation ${isLoading ? 'enabled' : 'hidden'}`}
      >
        <PageLoader isOverlay="true" width={125} height={250} />
      </section>
      <section className="form">
        <h2>Por favor atualize seus dados</h2>
        <form>
          <fieldset>
            <legend>CPF</legend>
            <MaskedInput
              guide={false}
              mask={cpfMask}
              onChange={(e) => changeCpf(e.target.value)}
            />
          </fieldset>
          <fieldset>
            <legend>Nome Completo</legend>
            <input type="text" onChange={(e) => changeNome(e.target.value)} />
          </fieldset>
          <button
            className="button-bem"
            onClick={atualizarUsuario}
            disabled={desabilitaBotao}
          >
            Atualizar dados
          </button>
        </form>
      </section>
      <ToastContainer />
    </div>
  );
}
