import { FaceTecSDK } from '../../../../../public/core-sdk/FaceTecSDK.js/FaceTecSDK';
import { ZoomGlobalState as Config } from '../../../../../public/core-sdk/ZoomGlobalState';

export default function LivenessCheckProcessor(
  sessionToken,
  bemFacetec,
  tokenSignatario,
) {
  var _this = this;
  this.latestNetworkRequest = new XMLHttpRequest();
  this.bemFacetec = bemFacetec;
  this.tokenSignatario = tokenSignatario;

  this.onFaceTecSDKCompletelyDone = function () {
    _this.success = _this.latestSessionResult.isCompletelyDone;

    _this.bemFacetec.onComplete(
      _this.latestSessionResult,
      null,
      _this.latestNetworkRequest.status,
      _this.sucessoLiveness,
    );
  };

  this.isSuccess = function () {
    return _this.success;
  };

  this.success = false;
  this.bemFacetec = bemFacetec;
  this.latestSessionResult = null;
  this.quantidadeTentativasLiveness = 0;
  this.sucessoLiveness = true;

  new FaceTecSDK.FaceTecSession(this, sessionToken);
}

LivenessCheckProcessor.prototype.processSessionResultWhileFaceTecSDKWaits =
  function (sessionResult, faceScanResultCallback) {
    var _this = this;

    this.latestSessionResult = sessionResult;

    if (
      sessionResult.status !==
      FaceTecSDK.FaceTecSessionStatus.SessionCompletedSuccessfully
    ) {
      console.log('sessão abortada');
      this.latestNetworkRequest.abort();
      faceScanResultCallback.cancel();
      return;
    }

    var parameters = {
      faceScan: sessionResult.faceScan,
      auditTrailImage: sessionResult.auditTrail[0],
      lowQualityAuditTrailImage: sessionResult.lowQualityAuditTrail[0],
      sessionId: sessionResult.sessionId,
    };

    this.latestNetworkRequest = new XMLHttpRequest();
    this.latestNetworkRequest.open(
      'POST',
      `${Config.ZoomServerBaseURL}/ObterLiveness/${_this.tokenSignatario}`,
    );
    this.latestNetworkRequest.setRequestHeader(
      'Authorization',
      `Bearer ${window.localStorage.getItem('autorizacao')}`,
    );
    this.latestNetworkRequest.setRequestHeader(
      'Content-Type',
      'application/json',
    );
    this.latestNetworkRequest.setRequestHeader(
      'X-Device-Key',
      Config.DeviceLicenseKeyIdentifier,
    );
    this.latestNetworkRequest.setRequestHeader(
      'X-User-Agent',
      FaceTecSDK.createFaceTecAPIUserAgentString(sessionResult.sessionId),
    );
    this.latestNetworkRequest.onreadystatechange = function () {
      if (_this.latestNetworkRequest.readyState === XMLHttpRequest.DONE) {
        try {
          var responseJSON = JSON.parse(
            _this.latestNetworkRequest.responseText,
          ).retorno;

          _this.bemFacetec.setLatestServerResult(responseJSON);

          var scanResultBlob = responseJSON.scanResultBlob;

          _this.quantidadeTentativasLiveness =
            _this.quantidadeTentativasLiveness + 1;

          if (parseInt(window.localStorage.getItem('tentativasLiveness')) > 0 &&
            !responseJSON.success &&
            _this.quantidadeTentativasLiveness >=
            parseInt(window.localStorage.getItem('tentativasLiveness'))
          ) {
            _this.success = true;
            _this.latestSessionResult.isCompletelyDone = true;
            _this.sucessoLiveness = false;
            faceScanResultCallback.cancel();
          } else if (responseJSON.wasProcessed) {
            faceScanResultCallback.proceedToNextStep(scanResultBlob);
          } else {
            faceScanResultCallback.cancel();
          }
        } catch (_a) {
          faceScanResultCallback.cancel();
        }
      }
    };
    this.latestNetworkRequest.onerror = function () {
      faceScanResultCallback.cancel();
    };

    this.latestNetworkRequest.upload.onprogress = function (event) {
      var progress = event.loaded / event.total;
      faceScanResultCallback.uploadProgress(progress);
    };

    var jsonStringToUpload = JSON.stringify(parameters);
    this.latestNetworkRequest.send(jsonStringToUpload);

    window.setTimeout(function () {
      if (_this.latestNetworkRequest.readyState === XMLHttpRequest.DONE) {
        return;
      }
      faceScanResultCallback.uploadMessageOverride('Ainda enviando...');
    }, 6000);
  };
