import React, { useState, useEffect } from 'react';
import DocumentoBase64 from 'components/DocumentoBase64';
import DocumentoTemplate from 'components/DocumentoTemplate';

export default function DocumentoAssinatura(props) {
  const [retornoComponente, setRetornoComponente] = useState(null);
  const [listaTemplateFalha, setListaTemplateFalha] = useState([]);

  function setDados(valido) {
    if (!valido) {
      if (props.idTemplate)
        setListaTemplateFalha((lista) => [...lista, props.idTemplate]);

      setRetornoComponente(
        <DocumentoBase64
          idDocumento={props.idDocumento}
          setIsLoading={props.setIsLoading}
          setBloqueioScroll={props.setBloqueioScroll}
          setPdfRenderizado={props.setPdfRenderizado}
        />,
      );
    }
  }

  useEffect(() => {
    if (listaTemplateFalha.indexOf(props.idTemplate) !== -1) return;

    if (props.idTemplate) {
      setRetornoComponente(
        <DocumentoTemplate
          idTemplate={props.idTemplate}
          setDadosValidos={setDados}
          setIsLoading={props.setIsLoading}
          idExterno={props.idExterno}
          setBloqueioScroll={props.setBloqueioScroll}
          dimensaoBottom={props.dimensaoBottom}
        />,
      );
    } else {
      setRetornoComponente(
        <DocumentoBase64
          idDocumento={props.idDocumento}
          setIsLoading={props.setIsLoading}
          setBloqueioScroll={props.setBloqueioScroll}
          setPdfRenderizado={props.setPdfRenderizado}
        />,
      );
    }
  }, [props.idDocumento, props.idExterno, props.dimensaoBottom]);

  return retornoComponente;
}
