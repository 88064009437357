import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { apiAuth } from 'services/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ROLE, ROLE_ADM } from './consts'
import LogoBanrisul from 'assets/logo-banrisul.svg'
import './styles.css';
import PageLoader from 'components/PageLoader';
import { isValidTokenScore } from 'helpers/authScore';

export default function Login() {
    const [usuario, setUsuario] = useState('');
    const [senha, setSenha] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();

    const verificaRole = (jwtToken) => {
        const jwtDescoded = new Buffer.from(jwtToken.split('.')[1], 'base64').toString('ascii')
        const jwt = JSON.parse(jwtDescoded);

        localStorage.setItem('autorizacao-score', jwtToken);

        if (jwt.role === ROLE || jwt.role === ROLE_ADM) {
            history.push('/score');
        } else {
            toast.error('Seu usuário não possui permissão');
        }
    }

    useEffect(() => {
        const valido = isValidTokenScore();

        const jwtToken = localStorage.getItem('autorizacao-score');

        if (valido) verificaRole(jwtToken);
    }, [])

    const logar = async (event) => {
        event.preventDefault();

        try {
            const data = {
                usuario,
                senha
            };

            setIsLoading(true);

            const response = await apiAuth.post(`Autenticacao/Autenticar`, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const jwtToken = response.data.retorno;

            if (jwtToken) {
                verificaRole(jwtToken);
            }

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            toast.error('Usuário ou senha inválidos');
        }
    }

    const changeUsuario = (value) => {
        setUsuario(value);
    }

    const changeSenha = (value) => {
        setSenha(value);
    }

    return <div className='container login-container'>
        <img src={LogoBanrisul} alt="logo Banrisul" width={250} />
        <h1>Faça seu login</h1>
        <form onSubmit={logar}>
            <fieldset>
                <legend>Usuario</legend>
                <input onChange={(e) => changeUsuario(e.target.value)} />
            </fieldset>
            <fieldset>
                <legend>Senha</legend>
                <input type='password' onChange={(e) => changeSenha(e.target.value)} />
            </fieldset>
            <button className="button-bem" disabled={!usuario || !senha}>
                Confirmar
            </button>
        </form>
        <ToastContainer />
        <section
            className={`loader-animation ${isLoading ? 'enabled' : 'hidden'}`}
        >
            <PageLoader isOverlay="true" width={125} height={250} />
        </section>
    </div>
}