import moment from 'moment';

const formatarCep = (cep) => {
  if (cep) {
    return cep.replace(/^(\d{5})(\d{3})/, '$1-$2');
  }

  return cep;
};

const formatarCPF = (cpf) => {
  if (cpf) {
    return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }
};

const formatarCNPJ = (cnpj) => {
  if (cnpj) {
    return cnpj.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5',
    );
  }
};

const formatarMoeda = (valor) => {
  let retorno = 0.0;
  if (valor) {
    retorno = valor;
  }

  return retorno.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
};

const formatarPercentual = (valor) => {
  let retorno = 0.0;
  if (valor) {
    retorno = valor;
  }
  return `${parseFloat(retorno).toFixed(2)}%`.replace('.', ',');
};

const formatarTelefone = (telefone) => {
  if (telefone) {
    if (telefone.search('X') !== -1) {
      if (telefone.length > 10)
        return telefone.replace(
          /^(\d{2})([A-Za-z_0-9]{5})([A-Za-z_0-9]{4})/,
          '($1) $2-$3',
        );
      else
        return telefone.replace(
          /^(\d{2})([A-Za-z_0-9]{4})([A-Za-z_0-9]{4})/,
          '($1) $2-$3',
        );
    }
    if (telefone.length > 10) {
      return telefone.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    }

    return telefone.replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  }
};

const formatarStringData = (dataString) => {
  if (dataString && moment(dataString).isValid()) {
    return moment(dataString).format('DD/MM/YYYY');
  }

  return dataString;
};

const formatarStringDataMesAno = (dataString) => {
  if (dataString && moment(dataString).isValid()) {
    return moment(dataString).format('MMM/YY');
  }

  return dataString;
};

const formatarStringDataHora = (dataString) => {
  if (dataString && moment(dataString).isValid()) {
    return moment(dataString).format('DD/MM/YYYY HH:mm');
  }

  return dataString;
};

const formatarStringDataHoraSegundos = (dataString) => {
  if (dataString && moment(dataString).isValid()) {
    return moment(dataString).format('DD/MM/YYYY HH:mm:ss');
  }

  return dataString;
};

const formatarStringDataTemplate = (dataString, template) => {
  if (dataString && moment(dataString).isValid()) {
    return moment(dataString).format(template || 'DD/MM/YYYY');
  }

  return dataString;
};

const formatarIniciarMaiusculo = (texto) => {
  if (texto) {
    return texto
      .toLowerCase()
      .replace(/(?:^|\s)(?!da |de |do |e )\S/gm, (item) => item.toUpperCase());
  }

  return texto;
};

const formatarCPFOculto = (cpf) => {
  if (cpf) {
    if (cpf.length === 11) {
      return `${cpf.substring(0, 3)}.${cpf.substring(3, 6)}.${cpf.substring(6, 9)}-${cpf.substring(9, 11)}`;
    } else {
      return '';
    }
  } else {
    return '';
  }
};

export {
  formatarCep,
  formatarCPF,
  formatarCNPJ,
  formatarMoeda,
  formatarPercentual,
  formatarTelefone,
  formatarStringData,
  formatarStringDataMesAno,
  formatarStringDataHora,
  formatarStringDataHoraSegundos,
  formatarStringDataTemplate,
  formatarIniciarMaiusculo,
  formatarCPFOculto
};
