import React, { useState, useEffect, useLayoutEffect, useMemo } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useApp } from "context/App";
import { isAuthenticated } from "auth";
import { PAGE_BIOMETRIA } from "helpers/pageList/consts";

import "./styles.css";

import Logout from "assets/menu-logout.svg";
import HeaderBemSignLogo from "assets/header-bemsign.svg";
import { LARGURA_HEADER } from "./consts";
import HeaderBLogo from "assets/logo-b.svg";
import HeaderBanrisulLogo from "assets/logo-p-banrisul.png";
import Progresso from "components/Progresso";
import {
  BANRISUL,
  CONSIGNADO,
  BANRISUL_2,
  FGTS,
  FUNDACAO,
  BANRISUL_PORTAL_EMPRESARIAL,
} from "helpers/consts";
import { AiOutlineLogout } from "react-icons/ai";
import { isValidTokenScore } from "helpers/authScore";
import LogoBanrisul from "assets/logo-p-banrisul.png";
import LogoFundacao from "assets/logo-fundacao.png";
import useRole from "helpers/hooks/useRole";
import MenuScoreADM from "components/MenuScoreADM";

export default function Header() {
  const [visibleLogout, setVisibleLogout] = useState(false);
  const [larguraHeader, setLarguraHeader] = useState(false);
  const [classeToggle, setClasseToggle] = useState(false);
  const [exibeProgresso, setExibeProgresso] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const {
    setUsuarioValido,
    ajudaPorVoz,
    mudaAjudaPorVoz,
    habilitaAudio,
    setHabilitaAudio,
    idEmpresa,
    pathAtual,
    configGlobal,
    token,
  } = useApp();
  const matchBiometria = useRouteMatch(PAGE_BIOMETRIA);

  history.listen(() => {
    window.scrollTo(0, 0);
    let autenticacaoPortal = window.localStorage.getItem("autenticacao-portal");
    setVisibleLogout(
      isAuthenticated(true) && autenticacaoPortal && autenticacaoPortal != ""
    );
  });

  function deslogar() {
    window.localStorage.removeItem("autenticacao-portal");
    setUsuarioValido(false);
    history.push("/");
  }

  const ocultarBotaoAudioUrls = [
    "/",
    "/token-portal",
    "/redefine-senha",
    "/detalhes",
  ];

  const exibirBotaoAudio =
    ocultarBotaoAudioUrls.filter((x) => x === pathAtual().last).length === 0;

  const exibeHeader =
    pathAtual().complete.indexOf("/verificador-assinatura") === -1;

  useEffect(() => {
    const handleHeaderResize = () => {
      if (window.innerWidth <= LARGURA_HEADER) {
        setLarguraHeader(true);
      } else {
        setLarguraHeader(false);
      }
    };

    handleHeaderResize();

    window.addEventListener("resize", handleHeaderResize);

    return () => window.removeEventListener("resize", handleHeaderResize);
  }, []);

  const toggleMenu = () => {
    document.body.style.overflow = !classeToggle ? "hidden" : "initial";

    setClasseToggle(!classeToggle);
  };

  const instrucaoAudio = () => {
    mudaAjudaPorVoz();
  };

  const audioDescricao = () => {
    setHabilitaAudio(!habilitaAudio);
  };

  const listaProgresso = [
    "/verificacao",
    "/token",
    "/confirmacao-dados",
    "/documento",
    "/biometria",
    "/anexos",
    "/identificacao",
    "/assinatura",
  ];

  useLayoutEffect(() => {
    const rotaPermitida =
      listaProgresso.filter((x) => x === location.pathname).length > 0;

    setExibeProgresso(rotaPermitida);
  }, [location]);

  const listaScoreRotas = [
    "/score",
    "/biometria-score",
    "/relatorio-biometria",
  ];

  const exibeLogoutScore = useMemo(() => {
    const valido = isValidTokenScore();

    const rotaScore =
      listaScoreRotas.filter((x) => x === location.pathname).length > 0;

    return valido && rotaScore;
  }, [location]);

  const Menu = () => {
    return (
      <div
        className={classeToggle ? "menu-section on" : "menu-section"}
        style={{
          backgroundColor: classeToggle && configGlobal?.header_cor_fundo,
        }}
      >
        <div className="menu-toggle" onClick={toggleMenu}>
          <div className="one"></div>
          <div className="two"></div>
          <div className="three"></div>
        </div>
        <nav className="menu-assinatura">
          <ul>
            <li>
              <a
                onClick={instrucaoAudio}
                className={ajudaPorVoz ? "menu-highlight" : ""}
              >
                Instruções por áudio
              </a>
            </li>
            <li>
              <a
                onClick={audioDescricao}
                className={habilitaAudio ? "menu-highlight" : ""}
              >
                Áudio descrição
              </a>
            </li>
          </ul>
        </nav>
      </div>
    );
  };

  const LogoEmpresa = () => {
    if (
      idEmpresa === BANRISUL ||
      idEmpresa === BANRISUL_2 ||
      idEmpresa === BANRISUL_PORTAL_EMPRESARIAL
    ) {
      return <img src={HeaderBanrisulLogo} alt="BemSign" id="logo-b" />;
    } else if (idEmpresa === CONSIGNADO || idEmpresa === FGTS) {
      return <img src={HeaderBLogo} alt="BemSign" id="logo-b" />;
    } else if (idEmpresa === FUNDACAO) {
      return (
        <img
          src={LogoFundacao}
          alt="BemSign"
          id="logo-b"
          className="fundacao"
        />
      );
    }

    return null;
  };

  const estiloLogoutScore = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 15px",
  };

  const HeaderApp = () => (
    <div className="header" style={exibeLogoutScore ? estiloLogoutScore : null}>
      <LogoEmpresa />
      {visibleLogout && <img src={Logout} alt="deslogar" onClick={deslogar} />}
      {exibirBotaoAudio && token && <Menu />}
      {exibeLogoutScore && (
        <img src={LogoBanrisul} alt="Logo Banrisul" width={"30px"} />
      )}
      {exibeLogoutScore && <MenuScoreADM />}
    </div>
  );

  const HeaderLogo = () =>
    !matchBiometria && (
      <div className="header-bemsign">
        <img src={HeaderBemSignLogo} alt="Novo logo bemsign" />
      </div>
    );

  const handleHeader = () => {
    if (exibeHeader) {
      return (
        !matchBiometria && (
          <div className="container-header-progresso">
            <HeaderApp />
            <Progresso hide={!exibeProgresso} />
          </div>
        )
      );
    }

    if (!exibeHeader && larguraHeader) {
      return <HeaderLogo />;
    }
  };

  return <>{handleHeader()}</>;
}
