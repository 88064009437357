const valorFinanceiroParaTexto = (valor) => {
    var valorQuebrado = valor.toString().split('.');

    var reais =  valorQuebrado[0];
    var centavos =  valorQuebrado[1];

    var valorReais = parseInt(reais);
    var texto = `${valorReais} reais`
    if (valorReais <= 1) {
        texto = `${valorReais} real`
    }

    if (centavos) {
        centavos = (centavos + '0').substr(0, 2);
        
        var valorCentavos = parseInt(centavos)
        if (valorCentavos > 1) {
            texto += ` e ${valorCentavos} centavos`
        } else {
            texto += ` e ${valorCentavos} centavo`
        }
    }

    return texto;
}

const valorPercentualParaTexto = (valor) => {
    return valor.toString().replace('.', ' ponto ');
}

export { valorFinanceiroParaTexto, valorPercentualParaTexto }