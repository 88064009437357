import React from 'react';

import Relogio from 'assets/relogio.png';

import './styles.css';
import { useApp } from 'context/App';
import { COR_PADRAO } from 'helpers/consts';

export default function SessaoAviso() {
  const { token, configGlobal } = useApp();

  const corPrimaria = configGlobal?.aviso_cor_fundo;

  return (
    <div className="container sessao-container" style={{ background: token ? corPrimaria : COR_PADRAO }}>
      <img src={Relogio} alt="sessão expirada" />
      <h2>Sua sessão expirou!</h2>
    </div>
  );
}
