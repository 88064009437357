import React from 'react';

import './styles.css';

function montarColunas(colunas) {
    var colunasTabela = Object.keys(colunas).map((coluna, index) => {
        return (
            <th key={index} style={colunas[coluna].styles}>{colunas[coluna].titulo}</th>
        );
    });

    return (
        <tr>{colunasTabela}</tr>
    );
}

function montarLinhas(colunas, valores) {

    return valores.map((valor, index) => {
        var colunasTabela = Object.keys(colunas).map((coluna, indexColuna) => {
            return (
                <td key={indexColuna} data-label={colunas[coluna].titulo}>{valor[coluna]}</td>
            );
        });

        return (
            <tr key={index}>{colunasTabela}</tr>
        );
    })
}

export default function TabelaResponsiva({ colunas, valores }) {
    return (
        <table className="responsive-table table-group">
            <thead>
                {montarColunas(colunas)}
            </thead>
            <tbody>
                {montarLinhas(colunas, valores)}
            </tbody>
        </table>
      );
}