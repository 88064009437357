import React from 'react';
import { Parser } from 'html-to-react';
import api from 'services/api';
import { useApp } from 'context/App';

import '../styles.css';
import './styles.css';

export default function InformacoesPadrao({ informacoes, marcarInformacaoValidada }) {
  const { tokenSignatario } = useApp();

  const htmlToReactParser = new Parser(React);

  async function buttonHandler() {
    marcarInformacaoValidada(informacoes.id);

    if (informacoes.idAuditoriaTipo) {

      await api.post(`/BemSign/GravarAuditoria/${tokenSignatario}/${informacoes.idAuditoriaTipo}`, null, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(
            'autorizacao',
          )}`,
          'Content-Type': 'application/json',
        },
      });
    }
  }


  return (
    <div className="container informacoes-container">
      <h1>Aviso</h1>

      <h2 className="informacoes-frase">{htmlToReactParser.parse(informacoes.texto)}</h2>

      <button
        className="button-bem"
        onClick={async () => { await buttonHandler() }}
      >
        Entendi
      </button>
    </div>
  )
}
