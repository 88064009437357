const ROTACAO_ESPELHO = 2;
const ORIENTACAO_TELA_1 = 4;
const ORIENTACAO_TELA_2 = 9;

const transformaContexto = (srcOrientation, ctx, width, height) => {
  switch (srcOrientation) {
    case 2:
      ctx.transform(-1, 0, 0, 1, width, 0);
      break;
    case 3:
      ctx.transform(-1, 0, 0, -1, width, height);
      break;
    case 4:
      ctx.transform(1, 0, 0, -1, 0, height);
      break;
    case 5:
      ctx.transform(0, 1, 1, 0, 0, 0);
      break;
    case 6:
      ctx.transform(0, 1, -1, 0, height, 0);
      break;
    case 7:
      ctx.transform(0, -1, -1, 0, height, width);
      break;
    case 8:
      ctx.transform(0, -1, 1, 0, 0, width);
      break;
    default:
      break;
  }
};

const rotacionaImagem = (srcBase64, srcOrientation, callback) => {
  var img = new Image();

  img.onload = function () {
    var width = img.width,
      height = img.height,
      canvas = document.createElement('canvas'),
      ctx = canvas.getContext('2d');

    var validaOrientacao = ORIENTACAO_TELA_1 < srcOrientation && srcOrientation < ORIENTACAO_TELA_2;

    canvas.width = validaOrientacao ? height : width;
    canvas.height = validaOrientacao ? width : height;

    transformaContexto(srcOrientation, ctx, width, height);

    ctx.drawImage(img, 0, 0);

    callback(canvas.toDataURL());
  };

  img.src = srcBase64;
};

export { rotacionaImagem, ROTACAO_ESPELHO };
