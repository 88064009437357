import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useApp } from 'context/App';

import ComprovanteResidencia from 'assets/comprovante-residencia.png';
import ComprovanteRenda from 'assets/comprovante-renda.png';
import PageLoader from 'components/PageLoader';
import UploadArquivo from './UploadArquivo';

import api from 'services/api';

import { PASSO_INFO_DOCUMENTO, PASSO_UPLOAD } from './consts';

import audioAnexo from 'assets/audios/se-desejar-anexe-2.mp3';

import { useAudio } from 'react-use';
import { VOLUME } from 'services/consts';
import BotaoFixado from 'components/BotaoFixado';

import './styles.css';

export default function Anexo() {
  const [listaAnexos, setListaAnexos] = useState([]);
  const [idSelecionado, setIdSelecionado] = useState(null);
  const [anexo, setAnexo] = useState({});
  const [passoDocumento, setPassoDocumento] = useState(PASSO_INFO_DOCUMENTO);
  const [passoAnterior, setPassoAnterior] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const {
    setSignatarioAnexos,
    tokenSignatario,
    proximaPagina,
    ajudaPorVoz,
    alteraProgresso,
  } = useApp();
  const [audio, state, controls, ref] = useAudio({
    src: audioAnexo,
    autoPlay: false,
  });

  function navegarProximaPagina() {
    history.push(proximaPagina());
  }

  function changePassoDocumento(passo) {
    setPassoAnterior(passoDocumento);
    setPassoDocumento(passo);
  }

  function obterIcone(descricaoIcone) {
    let imagem = null;

    switch (descricaoIcone) {
      case 'Casa':
        imagem = ComprovanteResidencia;
        break;
      case 'Cifrao':
        imagem = ComprovanteRenda;
        break;
    }

    return imagem;
  }

  function proximoAnexo() {
    alteraProgresso();

    const index = listaAnexos.findIndex((o) => o.id == anexo.id);
    const nextIndex = index + 1;

    if (listaAnexos[nextIndex]) {
      const proximoAnexo = listaAnexos[nextIndex];

      proximoAnexo.imagem = obterIcone(proximoAnexo.descricaoIcone);

      setAnexo(proximoAnexo);
      setIdSelecionado(proximoAnexo.id);
    } else {
      setSignatarioAnexos(
        listaAnexos
          .filter((anexo) => anexo.documento != null)
          .map((anexo) => {
            return { idAnexo: anexo.id, documento: anexo.documento };
          }),
      );
      navegarProximaPagina();
    }
  }

  function incluirDocumento() {
    changePassoDocumento(PASSO_UPLOAD);
  }

  useEffect(() => {
    const fecthData = async () => {
      try {
        setIsLoading(true);

        const response = await api.get(
          `Assinatura/ListarAnexosAssinatura/${tokenSignatario}`,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem(
                'autorizacao',
              )}`,
            },
          },
        );

        const listaRetorno = Array.from(new Set(response.data.retorno));

        setListaAnexos(listaRetorno);

        if (!idSelecionado && listaRetorno.length) {
          listaRetorno[0].imagem = obterIcone(listaRetorno[0].descricaoIcone);
          setIdSelecionado(listaRetorno[0].id);
          setAnexo(listaRetorno[0]);
        } else {
          return navegarProximaPagina();
        }

        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        return navegarProximaPagina();
      }
    };

    fecthData();
  }, []);

  useEffect(() => {
    if (ajudaPorVoz && idSelecionado) {
      controls.volume(VOLUME);
      controls.play();
    }
    changePassoDocumento(PASSO_INFO_DOCUMENTO);
  }, [idSelecionado]);

  const infoDocumento = (
    <div className="anexo-container">
      {anexo.imagem && <img src={anexo.imagem} alt={anexo.descricaoIcone} />}
      <h1>
        {!anexo.obrigatorio
          ? `Você gostaria de incluir um ${anexo.nome}?`
          : `Agora vamos incluir o ${anexo.nome}`}
      </h1>
      <h2>
        Esse passo ajuda a instituição financeira que está lhe concedendo esse
        produto.
      </h2>
      <BotaoFixado
        onClick={incluirDocumento}
        botaoSecundario
        BotaoComplemento={
          !anexo.obrigatorio ? (
            <button className="button-bem" onClick={proximoAnexo}>
              Não, quero apenas continuar
            </button>
          ) : null
        }
      >
        {!anexo.obrigatorio ? 'Sim, incluir comprovante' : 'Incluir'}
      </BotaoFixado>
    </div>
  );

  const uploadDocumento = (
    <UploadArquivo anexo={anexo} proximoAnexo={proximoAnexo} />
  );

  const renderPasso = () => {
    let ui = null;

    switch (passoDocumento) {
      case PASSO_INFO_DOCUMENTO:
        ui = infoDocumento;
        break;
      case PASSO_UPLOAD:
        ui = uploadDocumento;
        break;
      default:
        ui = infoDocumento;
        break;
    }

    return ui;
  };

  return (
    <div className="container ">
      {audio}
      {isLoading ? (
        <section
          className={`loader-animation ${isLoading ? 'enabled' : 'hidden'}`}
        >
          <PageLoader isOverlay="true" width={125} height={250} />
        </section>
      ) : (
        renderPasso()
      )}
    </div>
  );
}
