import MobileDetect from 'mobile-detect';

const getListBrowser = () => {
  const md = new MobileDetect(navigator.userAgent);
  const rules = MobileDetect._impl.mobileDetectRules;
  const listBrowser = [];

  Object.keys(rules.props).map((propKey) => {
    let version;

    version = md.version(propKey);

    if (version) {
      listBrowser.push({ name: propKey, version: parseInt(version, 10) });
    }
  });

  return listBrowser;
};

const rulesBrowser = (listBrowser) => {
  let confirmedRules = true;

  if (
    listBrowser.some((elem) => elem.name === 'Android') &&
    listBrowser.some((elem) => elem.name === 'SamsungBrowser')
  ) {
    confirmedRules = false;
  } else if (
    listBrowser.some((elem) => elem.name === 'Android' && elem.version < 4.4)
  ) {
    confirmedRules = false;
  } else if (
    listBrowser.some((elem) => elem.name === 'iOS' && elem.version < 8.3)
  ) {
    confirmedRules = false;
  } else if (
    listBrowser.some((elem) => elem.name === 'Android') &&
    (listBrowser.some((elem) => elem.name === 'Chrome' && elem.version < 56) ||
      listBrowser.some(
        (elem) => elem.name === 'Firefox' && elem.version < 50,
      ) ||
      listBrowser.some((elem) => elem.name === 'Opera' && elem.version < 47))
  ) {
    confirmedRules = false;
  } else if (
    listBrowser.some((elem) => elem.name === 'iOS' && elem.version < 8.3) &&
    listBrowser.some((elem) => elem.name === 'Safari' && elem.version < 11)
  ) {
    confirmedRules = false;
  } else if (
    listBrowser.some((elem) => elem.name === 'iOS') &&
    listBrowser.some((elem) => elem.name === 'Chrome')
  ) {
    confirmedRules = false;
  } else if (
    listBrowser.some((elem) => elem.name === 'Chrome' && elem.version < 56) ||
    listBrowser.some((elem) => elem.name === 'Firefox' && elem.version < 50) ||
    listBrowser.some((elem) => elem.name === 'Safari' && elem.version < 11)
  ) {
    confirmedRules = false;
  } else if (
    !listBrowser.some((elem) => elem.name === 'Android') &&
    !listBrowser.some((elem) => elem.name === 'iOS') &&
    listBrowser.some((elem) => elem.name === 'Opera' && elem.version < 47)
  ) {
    confirmedRules = false;
  }

  return confirmedRules;
};

const isBrowserValid = () => {
  const listBrowser = getListBrowser();

  return rulesBrowser(listBrowser);
};

export { isBrowserValid, getListBrowser };
