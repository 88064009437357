import React from 'react';
import { formatarMoeda } from 'helpers/Formatters';

import './styles.css';

export default function ResumoCCB(prop) {
  const { dadosCCB } = prop;

  return (
    <div className="resumo-ccb-container">
      <h1>Resumo da sua proposta de crédito consignado:</h1>

      <div className="resumo-valor-ccb">
        <label>Valor Financiado</label>
        <div>
          <span>
            {formatarMoeda(dadosCCB.caracteristicaCreditoCet.valorFinanciado)}
          </span>
        </div>
      </div>

      <div className="resumo-valor-ccb">
        <label>Valor Líquido</label>
        <div>
          <span>{formatarMoeda(dadosCCB.valorLiquidoASerCreditado)}</span>
        </div>
      </div>

      <div className="resumo-informacoes-adicionais">
        <div>
          <label>Parcela</label>
          <span>
            {formatarMoeda(dadosCCB.caracteristicaCreditoCet.valorPrestacao)}
          </span>
        </div>

        <div>
          <label>Prazo</label>
          <span>
            {dadosCCB.caracteristicaCreditoCet.quantidadePrestacao} vezes
          </span>
        </div>
      </div>

      <div className="resumo-informacoes-adicionais">
        <div>
          <label>Tipo de Operação</label>
          <span>Proposta {dadosCCB.descricaoTipoOperacao}</span>
        </div>
      </div>
    </div>
  );
}
