const rotasIgnoradas = ['/video-usuario', '/verificador-assinatura', '/b'];

export default function retornaBasename(){
    let basename = '/';

    const rota = "/" + window.location.pathname.split('/')[1];

    if(rota.length && !rotasIgnoradas.includes(rota) && window.location.pathname.split('/')[2]) {
        basename = rota;
    }

    return basename;
}