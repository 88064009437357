import { FaceTecSDK } from "../../../../../public/core-sdk/FaceTecSDK.js/FaceTecSDK";
import { ZoomGlobalState } from "../../../../../public/core-sdk/ZoomGlobalState";
import { VOLUME } from "services/consts";
import {
  CONSIGNADO,
  BANRISUL,
  BANRISUL_2,
  FGTS,
  FUNDACAO,
  BANRISUL_PORTAL_EMPRESARIAL,
} from "helpers/consts";
import $ from "jquery";

export var FacetecUtilities = (function () {
  var vocalGuidanceSoundFilesDirectory =
    "../../../../../public/core-sdk/Vocal_Guidance_Audio_Files/";
  var vocalGuidanceOnPlayer = new Audio(
    vocalGuidanceSoundFilesDirectory + "voz-ligada-2.mp3"
  );
  var vocalGuidanceOffPlayer = new Audio(
    vocalGuidanceSoundFilesDirectory + "voz-desligada-1.mp3"
  );
  vocalGuidanceOnPlayer.volume = VOLUME;
  vocalGuidanceOffPlayer.volume = VOLUME;
  function displayStatus(message) {
    document.getElementById("status").innerHTML = message;
  }
  function fadeInMainUIContainer() {
    $(".loading-session-token-container").fadeOut(1);
    $("#theme-transition-overlay").fadeOut(800);
    $(".wrapping-box-container").fadeIn(800);
  }
  function fadeInMainUIControls(callback) {
    // if (isLikelyMobileDevice()) {
    //     $("#custom-logo-container").fadeIn(800);
    // }
    $("#controls").fadeIn(800, function () {
      enableAllButtons();
      if (callback) {
        callback();
      }
    });
  }
  // Disable buttons to prevent hammering, fade out main interface elements, and reset the Session Review Screen data.
  function fadeOutMainUIAndPrepareForSession() {
    disableAllButtons();
    // if (isLikelyMobileDevice()) {
    //     $("#custom-logo-container").fadeOut(800);
    // }
    $("#controls").fadeOut(800);
    $(".wrapping-box-container").fadeOut(800);
    $("#theme-transition-overlay").fadeIn(800);
    //SessionReviewScreen.reset();
  }
  function showLoadingSessionToken() {
    $(".loading-session-token-container").fadeIn(300);
  }
  function hideLoadingSessionToken() {
    $(".loading-session-token-container").fadeOut(800);
  }
  function disableAllButtons() {
    $("#enroll-button").prop("disabled", true);
    $("#id-scan-button").prop("disabled", true);
    $("#liveness-button").prop("disabled", true);
    $("#authenticate-button").prop("disabled", true);
    $("#session-result-button").prop("disabled", true);
    $("#design-showcase-button").prop("disabled", true);
  }
  function enableAllButtons() {
    $("#enroll-button").prop("disabled", false);
    $("#id-scan-button").prop("disabled", false);
    $("#liveness-button").prop("disabled", false);
    $("#authenticate-button").prop("disabled", false);
    $("#session-result-button").prop("disabled", false);
    $("#design-showcase-button").prop("disabled", false);
  }
  function showMainUI() {
    fadeInMainUIContainer();
    fadeInMainUIControls();
  }
  function handleErrorGettingServerSessionToken() {
    showMainUI();
    displayStatus(
      "Session could not be started due to an unexpected issue during the network request."
    );
  }
  function generateUUId() {
    // @ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, function (c) {
      return (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16);
    });
  }
  function formatUIForDevice() {
    if (isLikelyMobileDevice()) {
      // Adjust button sizing
      document
        .querySelectorAll("#controls > button")
        .forEach(function (element) {
          if (element.className === "big-button") {
            element.style.height = "40px";
            element.style.fontSize = "18px";
          } else if (element.className === "medium-button") {
            element.style.height = "30px";
            element.style.fontSize = "14px";
          }
          element.style.width = "220px";
        });
      // Adjust main interface display
      document.getElementById("main-interface").style.display = "contents";
      // Hide border around control panel and adjust height
      document.getElementById("controls").style.height = "auto";
      document.getElementById("controls").style.borderColor = "transparent";
      // Hide status label text background and decrease label font size
      document.getElementById("status").style.backgroundColor = "transparent";
      document.getElementById("status").style.fontSize = "12px";
      document.getElementById("status").style.position = "inherit";
      document.getElementById("status").style.width = "90%";
      document.getElementById("status").style.margin = "20px auto 0";
      document.getElementById("status").style.left = "unset";
      document.getElementById("status").style.bottom = "unset";
      // Move logo above buttons
      // document.getElementById("custom-logo-container").parentNode.insertBefore(document.getElementById("custom-logo-container"), document.getElementById("custom-logo-container").parentNode.firstChild);
      // document.getElementById("custom-logo-container").style.margin = "0px 0px 20px 0px";
      // document.querySelector("#custom-logo-container img").style.height = "40px";
      // Center control interface on screen
      document.getElementsByClassName("wrapping-box-container")[0].style.top =
        "50%";
      document.getElementsByClassName("wrapping-box-container")[0].style.left =
        "50%";
      document.getElementsByClassName(
        "wrapping-box-container"
      )[0].style.transform = "translate(-50%, -50%)";
      // Adjust button margins
      document.getElementById("liveness-button").style.marginTop = "unset";
      document.getElementById("design-showcase-button").style.marginBottom =
        "unset";
      // Move Session Review Screen
      document
        .querySelector("body")
        .appendChild(document.getElementById("session-review-screen"));
    }
  }
  function configureGuidanceScreenTextForDevice() {
    if (isLikelyMobileDevice()) {
      // using strings with breaks for Ready Screen text on mobile devices
      ZoomGlobalState.currentCustomization.guidanceCustomization.readyScreenHeaderAttributedString =
        "Prepare-se para<br/>a selfie";
      ZoomGlobalState.currentCustomization.guidanceCustomization.readyScreenSubtextAttributedString = `Enquadre seu rosto <br/>para prosseguir`;
    }
    FaceTecSDK.setCustomization(ZoomGlobalState.currentCustomization);
  }
  function setVocalGuidanceSoundFiles() {
    ZoomGlobalState.currentCustomization.vocalGuidanceCustomization.pleaseFrameYourFaceInTheOvalSoundFile =
      vocalGuidanceSoundFilesDirectory + "centralize-rosto-circulo-2.mp3";
    ZoomGlobalState.currentCustomization.vocalGuidanceCustomization.pleaseMoveCloserSoundFile =
      vocalGuidanceSoundFilesDirectory + "aproxime-4.mp3";
    ZoomGlobalState.currentCustomization.vocalGuidanceCustomization.pleaseRetrySoundFile =
      vocalGuidanceSoundFilesDirectory + "tente-novamente-3.mp3";
    ZoomGlobalState.currentCustomization.vocalGuidanceCustomization.uploadingSoundFile =
      vocalGuidanceSoundFilesDirectory + "enviando-dados-1.mp3";
    ZoomGlobalState.currentCustomization.vocalGuidanceCustomization.facescanSuccessfulSoundFile =
      vocalGuidanceSoundFilesDirectory + "prova-de-vida-1.mp3";
    ZoomGlobalState.currentCustomization.vocalGuidanceCustomization.pleasePressTheButtonToStartSoundFile =
      vocalGuidanceSoundFilesDirectory + "comecar-2.mp3";
    FaceTecSDK.setCustomization(ZoomGlobalState.currentCustomization);
  }
  function isLikelyMobileDevice() {
    var isMobileDeviceUA =
      !!/Android|iPhone|iPad|iPod|IEMobile|Mobile|mobile/i.test(
        navigator.userAgent || ""
      );
    // ChromeOS/Chromebook detection.
    if (
      isMobileDeviceUA &&
      (navigator.userAgent.indexOf("CrOS") != -1 ||
        navigator.userAgent.indexOf("Chromebook") != -1)
    ) {
      isMobileDeviceUA = false;
    }
    // Mobile device determination based on portrait / landscape and user agent.
    if (window.screen.width < window.screen.height || isMobileDeviceUA) {
      // Assume mobile device when in portrait mode or when determined by the user agent.
      return true;
    } else {
      return false;
    }
  }

  function selectImageLogo(idEmpresa) {
    let logo = null;

    if (idEmpresa === CONSIGNADO) {
      logo = require("assets/logo-consignado-bloco.png");
    } else if (
      idEmpresa === BANRISUL ||
      idEmpresa === BANRISUL_2 ||
      idEmpresa === BANRISUL_PORTAL_EMPRESARIAL
    ) {
      logo = require("assets/logo-banrisul.svg");
    } else if (idEmpresa === FGTS) {
      logo = require("assets/logo-fgts.png");
    } else if (idEmpresa === FUNDACAO) {
      logo = require("assets/logo-fundacao.png");
    }

    return logo;
  }

  function changeImageLogo(idEmpresa) {
    if (idEmpresa) {
      let facetecLogo = selectImageLogo(idEmpresa);

      if (facetecLogo) {
        ZoomGlobalState.currentCustomization.overlayCustomization.brandingImage =
          facetecLogo.default;
        ZoomGlobalState.currentCustomization.overlayCustomization.showBrandingImage = true;
      } else {
        ZoomGlobalState.currentCustomization.overlayCustomization.showBrandingImage = false;
      }

      FaceTecSDK.setCustomization(ZoomGlobalState.currentCustomization);
    }
  }

  return {
    displayStatus: displayStatus,
    fadeInMainUIContainer: fadeInMainUIContainer,
    fadeInMainUIControls: fadeInMainUIControls,
    fadeOutMainUIAndPrepareForSession: fadeOutMainUIAndPrepareForSession,
    disableAllButtons: disableAllButtons,
    enableAllButtons: enableAllButtons,
    generateUUId: generateUUId,
    formatUIForDevice: formatUIForDevice,
    handleErrorGettingServerSessionToken: handleErrorGettingServerSessionToken,
    configureGuidanceScreenTextForDevice: configureGuidanceScreenTextForDevice,
    setVocalGuidanceSoundFiles: setVocalGuidanceSoundFiles,
    showMainUI: showMainUI,
    hideLoadingSessionToken: hideLoadingSessionToken,
    showLoadingSessionToken: showLoadingSessionToken,
    isLikelyMobileDevice: isLikelyMobileDevice,
    changeImageLogo: changeImageLogo,
  };
})();
